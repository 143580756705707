const numberRegex =  /^\d+$/;
const stringRegexWithSpace = /^[A-Za-z ]+$/;

const replaceAll = (str = "") => ({
    afterDot: str.replace(/\.(.*)/, "")
});

export {
    numberRegex,
    replaceAll,
    stringRegexWithSpace
};
