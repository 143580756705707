import isObject from "./is-object";

export const objectToString = (o) => {
    if (isObject(o)) {
        Object.keys(o).forEach((k) => {
            if (typeof o[k] === "object") {
                return objectToString(o[k]);
            }
            o[k] = `${o[k]}`;
            return null;
        });
    }
    return o;
};
