export const AMPLITUDE_EVENTS = {
    SELL_PAGE_OPENED: "sell page opened",
    SELL_INSPECTION_SCHEDULED: "sell inspection scheduled",
    TOF_GET_VALUATION_CLICKED: "TOF get valuation clicked",
    TOF_GET_AN_ESTIMATE_CLICKED: "TOF get an estimate clicked",
    SELL_INSPECTION_CANCELLED: "sell inspection cancelled",
    TOF_FLOW_STARTED: "TOF flow started",
    TOF_VARIANT_SELECTED: "TOF variant selected",
    TOF_VALUATION_SHOWN: "TOF valuation shown",
    TOF_VALUATION_ACCEPTED: "TOF valuation accepted",
    SELL_OR_TRADE_MENU_CLICKED: "sell or trade menu clicked",
    SELL_ORDERS_CLICKED: "sell orders clicked",
    NOT_LOOKING_TO_BUY_HOMEPAGE_BANNER_CLICKED: "not looking to buy homepage banner clicked",
    MY_ACCOUNT_SELL_OR_TRADE_IN_CLICKED: "my account sell or trade in clicked",
    CHECKOUT_TRADE_IN_LANDED: "checkout trade in landed",
    CHECKOUT_GET_AN_ESTIMATE_CLICKED: "checkout get an estimate clicked",
    CHECKOUT_GET_TRADE_IN_OFFER_CLICKED: "checkout get trade in offer clicked",
    CHECKOUT_VARIANT_SELECTED: "checkout variant selected",
    CHECKOUT_VALUATION_SHOWN: "checkout valuation shown",
    TRADE_IN_CHECKOUT_VALUATION_ACCEPTED: "trade in checkout valuation accepted",
    TRADE_IN_HOMEPAGE_BANNER_CLICKED: "trade in homepage banner clicked",
    CLP_TRADE_IN_CARD_CLICKED: "clp trade in card clicked",
    CDP_TRADE_IN_LINK_CLICKED: "cdp trade in banner cta clicked",
    CDP_TRADE_IN_LINK_VIEWED: "cdp trade in banner cta viewed",
    CLP_TRADE_IN_TOP_BANNER_CLICKED: "clp trade in top banner clicked",

    // phase 2 events
    NUMBER_OF_KEYS_ENTERED: "keys selected",
    WRITTEN_OFF_ENTERED: "written off selected",
    SERVICE_HISTORY_ENTERED: "service history selected",
    STATE_ENTERED: "state entered",
    BODY_CORROSION_ANSWERED: "underbody corrosion answered",
    HARD_GAURDRAIL_ANSWERED: "hard guardrail answered",
    ESTIMATED_VALUATION_RANGE_ACCEPTED: "estimated valuation range accepted",
    ODOMETER_READING: "odometer entered",

    // MMVY
    MMVY_PILL_CLICKED: "pill clicked",
    MAKE_SELECTED: "c2b make selected",
    YEAR_SELECTED: "year selected",
    MODEL_SELECTED: "c2b model selected",
    BODY_TYPE_SELECTED: "body selected",
    FUEL_SELECTED: "fuel selected",
    TRANSMISSION_SELECTED: "transmission selected",
    DRIVE_TRAIN_SELECTED: "drivetrain slected",

    // INSPECTION BOOKING
    INSPECTION_PAGE_OPENED: "inspection page opened",
    INSPECTION_FIRST_NAME_ENTERED: "inspection first name entered",
    INSPECTION_SUR_NAME_ENTERED: "inspection surname entered",
    INSPECTION_PINCODE_ENTERED: "inspection pincode entered",
    INSPECTION_NUMBER_ENTERED: "inspection number entered",
    INSPECTION_EMAIL_ENTERED: "inspection email entered",
    INSPECTION_REGO_ENTERED: "inspection rego entered",
    INSPECTION_STATE_ENTERED: "inspection state entered",
    INSPECTION_DATE_ENTERED: "inspection date selected",
    INSPECTION_TIME_ENTERED: "inspection time selected",
    INSPECTION_FINANCE_OWING_SELECTED: "inspection finance owing selected",
    INSPECTION_CONFIRMATION_RESCHEDULE_CLICKED: "inspection confirmation reschedule clicked",
    INPECTION_CONFIRMATION_VIEW_BOOKING_CLICKED: "inpection confirmation view booking clicked",

    // VALUATION PAGE
    CHOOSE_CAR_NOW_CLICKED: "choose car now clicked",
    VALUATION_INFO_CLICKED: "info clicked",
    DO_NOT_AGREE_WITH_ASSESMENT_CLICKED: "do not agree with assessment clicked",
    VALUE_COMPARISON_DONE: "value comparison done",
    PRE_BI_TRADE_IN_CAR_CAROUSEL_VIEWED: "pre_bi_trade_in_car_carousel_viewed",
    TRADE_IN_PRICE_ACCEPTED_NON_C2B_ELIGIBLE: "trade_in_price_accepted_non_c2b_eligible",

    // LOGIN
    LOGIN_ATTEMPTED: "login attempted",
    SUCCESSFUL_LOGIN: "successful login",

    // Exterior Condition
    NEXT_EXTERIOR_CONDITION: "exterior parts condition clicked",
    EXTERIOR_DAMAGE_SEVERITY_SELECTED: "parts selected",
    PROCEED_AFTER_PARTS_SELECTED: "proceeded after selecting parts",

    RANGE_PRICING_SHOWN: "range pricing shown",

    // email popup
    PROCEEDED_FROM_GUEST_VALUATION: "proceeded from guest valuation",
    EMAIL_POPUP_OPENED: "email popup opened",
    MOBILE_NUMBER_ENTERED: "mobile number entered",

    // Mechanical Condition
    MECHANICAL_CONDITION_RATING_CLICKED: "mechanical condition clicked",
    MECHANICAL_CONDITION_RATING_VIEWED: "mechanical condition viewed"

};
