/* eslint-disable max-len */
import { appUrl, currentEnv, ppApiUrl } from "./url-constants";

export const NUMBER = {
    ZERO: 0,
    DECIMAL_FIVE: 0.5,
    DECIMAL_ZERO_FIVE: 0.05,
    DECIMAL_EIGHT: 0.8,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    TWELVE: 12,
    THIRTEEN: 13,
    FOURTEEN: 14,
    FIFTEEN: 15,
    SIXTEEN: 16,
    EIGHTEEN: 18,
    NINETEEN: 19,
    TWENTY: 20,
    TWENTY_TWO: 22,
    TWENTY_FOUR: 24,
    TWENTY_FIVE: 25,
    TWENTY_EIGHT: 28,
    TWENTY_NINE: 29,
    THIRTY: 30,
    THIRTY_TWO: 32,
    THIRTY_FOUR: 34,
    THIRTY_FIVE: 35,
    THIRTY_EIGHT: 38,
    FOURTY: 40,
    FOURTY_FIVE: 45,
    FOURTY_EIGHT: 48,
    FIFTY: 50,
    FIFTY_SIX: 56,
    SIXTY: 60,
    EIGHTY: 80,
    SIXTY_FOUR: 64,
    SEVENTY_TWO: 72,
    NINETY: 90,
    HUNDRED: 100,
    ONE_FIFTY: 150,
    TWO_HUNDRED: 200,
    TWO_HUNDRED_THIRTY: 230,
    TWO_HUNDRED_SEVENTY: 270,
    TWO_HUNDRED_EIGHTY: 280,
    ONE_HUNDRED_THIRTY_FOUR: 134,
    ONE_HUNDRED_SEVENTY_FOUR: 174,
    ONE_HUNDRED_EIGHTY: 180,
    THREE_HUNDRED: 300,
    FIVE_HUNDRED: 500,
    SIX_HUNDRED: 600,
    SIX_HUNDRED_FIFTY: 650,
    SEVEN_HUNDRED: 700,
    NINE_NINETY_NINE: 999,
    THOUSAND: 1000,
    TWO_THOUSAND: 2000,
    THREE_THOUSAND: 3000,
    FOUR_THOUSAND: 4000,
    FOUR_THOUSAND_FIVE_HUNDRED: 4500,
    FIVE_THOUSAND: 5000,
    FIVE_THOUSAND_FIVE_HUNDRED: 5500,
    SIX_THOUSAND: 6000,
    EIGHT_THOUSAND: 6000,
    TEN_THOUSAND: 10000,
    TWELVE_THOUSAND: 12000,
    FIFTEEN_THOUSAND: 15000,
    TWENTY_FIVE_THOUSAND: 25000,
    FIFTY_THOUSAND: 50000,
    ONE_LAKH: 100000,
    TEN_LAKH: 1000000,
    THIRTY_LAKH: 3000000,
    NINETY_NINE_LAKH: 9900000,
    TWENTY_ONE: 21,
    TWENTY_SIX: 26,
    THIRTY_ONE: 31,
    THIRTY_SIX: 36,
    FOURTY_ONE: 41,
    FOURTY_SIX: 46,
    NINETEEN_SEVENTY: 1970,
    MINUS_TWENTY: -20,
    TWENTY_THOUSAND: 20000,
    FIFTY_FIVE_HUNDRED: 5500,
    FIFTEEN_HUNDRED: 1500,
    ONE_THOUSAND_TWELVE: 1012,
    ONE_THOUSAND_FOURTEEN: 1014,
    ONE_THOUSAND_THIRTEEN: 1013,
    MINUS_TWO: -2,
    MINUS_THREE: -3,
    THOUSAND_SEVEN: 1007,
    THOUSAND_TWENTY_ONE: 1021,
    THREE_LAKH_FIFTY_THOUSAND: 350000,
    FIFTY_LAKH: 5000000,
    THIRTY_THOUSAND: 30000,
    FOUR_HUNDRED_FOUR: 404,
    FOUR_HUNDRED_ONE: 401,
    FOUR_HUNDRED_NINE: 409,
    THOUSAND_THREE: 1003,
    NINTY_NINE: 99,
    FOUR_HUNDRED: 400,
    FIFTY_TWO: 52,
    SEVENTY_THOUSAND: 70000,
    SEVEN_THOUSAND: 7000,
    ELEVEN: 11,
    SEVENTEEN: 17,
    TWENTY_THREE: 23,
    ONE_THOUSAND_ONE: 1001,
    ONE_THOUSAND_TWENTY_FOUR: 1024,
    ONE_THOUSAND_THIRTY_FIVE: 1035,
    FOUR_HUNDERED_THREE: 403,
    SIXTY_THOUSAND: 60000,
    ONE_TWENTY_EIGHT: 128,
    DECIMAL_ONE: 0.1,
    DECIMAL_ZERO_EIGHTEEN: 0.18,
    MINUS_FIFTY: -50,
    MINUS_HUNDRED: -100,
    MINUS_NINETY: -90,
    ONE_HUNDRED_THREE: 103,
    ONE_HUNDRED_TWO: 102,
    TWENTY_SEVEN: 27,
    ONE_HUNDRED_NINTY: 190,
    ONE_HUNDRED_TWENTY: 120,
    ONE_HUNDRED_SIXTEEN: 116,
    THREE_HUNDRED_SIXTY: 360,
    NINE_LAKH_NINTY_NINE_THOUSAND_NINE_HUNDERED_NINTY_NINE: 999999,
    THIRTY_SIX_LAKH: 3600000,
    DECIMAL_ONE_TWO: 1.2,
    MILLION: 1000000,
    BILLION: 1000000000
};

export const seoTemplateType = {
    MAKE: "make",
    CITY: "city",
    MAKE_CITY: "makeCity",
    MAKE_CITY_LOCATION: "makeCityLocation",
    MAKE_MODEL: "makeModel",
    MAKE_MODEL_CITY: "makeModelCity",
    MAKE_MODEL_CITY_LOCATION: "makeModelCityLocation",
    CITY_LOCATION: "cityLocation",
    GENERIC: "generic"
};

export const SAVE_EMAIL_CODE = {
    SUCCESS: 1,
    FAILED: 3,
    NEUTRAL: 2
};

export const urlExpandParam = {
    RECENTLY_INSPECTED: "recently_inspected",
    BLOG_LIST: "blog_list",
    SEO_CONTENT: "seo_content",
    TESTIMONIAL_LIST: "testimonial_list",
    EXPERTS_LIST: "experts_list",
    CITY_LIST: "city_list",
    MAKE_LIST: "make_list"
};

export const resetReducerKeys = {
    RESET_CAR_FINDER: "RESET_CAR_FINDER",
    RESET_BRANDS: "RESET_BRANDS",
    RESET_MODELS: "RESET_MODELS",
    RESET_YEARS: "RESET_YEARS",
    RESET_VARIANTS: "RESET_VARIANTS",
    RESET_REGISTRATION_STATES: "RESET_REGISTRATION_STATES",
    RESET_KILOMETERS_DRIVEN: "RESET_KILOMETERS_DRIVEN",
    RESET_BLOGS: "RESET_BLOGS",
    RESET_TESTIMONIALS: "RESET_TESTIMONIALS",
    RESET_EXPERTS: "RESET_EXPERTS",
    RESET_FAQS: "RESET_FAQS",
    RESET_RECENTLY_INSPECTED: "RESET_RECENTLY_INSPECTED",
    RESET_FOOTER: "RESET_FOOTER",
    META_TAGS: "RESET_META_TAGS",
    RESET_NEWS: "RESET_NEWS",
    RESET_LEAD_DATA: "RESET_LEAD_DATA",
    RESET_SEO_MODELS: "RESET_SEO_MODELS",
    RESET_BRANCH_LOCATION: "RESET_BRANCH_LOCATION",
    RESET_CITIES: "RESET_CITIES",
    RESET_APPOINTMENT_LOCATION: "RESET_APPOINTMENT_LOCATION",
    RESET_APPOINTMENT_DETAILS: "RESET_APPOINTMENT_DETAILS",
    RESET_USER: "RESET_USER",
    RESET_LOCATION: "RESET_LOCATION",
    RESET_RESCHEDULE: "RESET_RESCHEDULE",
    RESET_CONFIRMATION_DETAILS: "RESET_CONFIRMATION_DETAILS",
    RESET_SETTINGS: "RESET_SETTINGS",
    RESET_WIDGET: "RESET_WIDGET",
    RESET_REVIEW: "RESET_REVIEW",
    RESET_BRANCH_LOCATOR: "RESET_BRANCH_LOCATOR",
    RESET_ONE_STEP_APPOINTMENT: "RESET_ONE_STEP_APPOINTMENT",
    RESET_REVIEW_DETAILS: "RESET_REVIEW_DETAILS",
    RESET_REVIEW_SUBMISSION: "RESET_REVIEW_SUBMISSION",
    RESET_RC_TRANSFER: "RESET_RC_TRANSFER",
    RESET_TOP_SELLING_USED_CARS: "RESET_TOP_SELLING_USED_CARS",
    RESET_NEED_HELP: "RESET_NEED_HELP",
    RESET_SEO_TESTIMONIALS: "RESET_SEO_TESTIMONIALS",
    RESET_RATED_TESTIMONIALS: "RESET_RATED_TESTIMONIALS",
    RESET_BRANCH_FILTER: "RESET_BRANCH_FILTER",
    RESET_TRUECALLER: "RESET_TRUECALLER",
    RESET_RTO_INFO: "RESET_RTO_INFO",
    RESET_LEAD_BRANCHES: "RESET_LEAD_BRANCHES",
    RESET_LEAD_BRANCH_DATE: "RESET_LEAD_BRANCH_DATE",
    RESET_LEAD_PROGRESS_BAR: "RESET_LEAD_PROGRESS_BAR",
    RESET_SEO_DETAILS: "RESET_SEO_DETAILS",
    RESET_AB_EXPERIMENTS: "RESET_AB_EXPERIMENTS",
    RESET_CAR_LIST: "RESET_CAR_LIST",
    RESET_FILTERS: "RESET_FILTERS",
    RESET_CONFIG: "RESET_CONFIG",
    RESET_FUEL_PRICE: "RESET_FUEL_PRICE",
    RESET_LEAD_RTO: "RESET_LEAD_RTO",
    RESET_CAR_DETAILS: "RESET_CAR_DETAILS",
    RESET_EXPLORE_USED_CARS: "RESET_EXPLORE_USED_CARS",
    RESET_SIMILAR_CARS: "RESET_SIMILAR_CARS",
    RESET_RECENTLY_VIEWED_CARS: "RESET_RECENTLY_VIEWED_CARS",
    RESET_SELECT_OFFER_PRICE: "RESET_SELECT_OFFER_PRICE",
    RESET_PARKING_YARD: "RESET_PARKING_YARD",
    FEATURED_CARS: "FEATURED_CARS",
    RESET_CONTENT_HOOK: "RESET_CONTENT_HOOK",
    RESET_LEGACY_LEAD_FORM: "RESET_LEGACY_LEAD_FORM",
    RESET_SELL_BIKE: "RESET_SELL_BIKE",
    RESET_SCRIPT_LOADER: "RESET_SCRIPT_LOADER",
    RESET_REFER: "RESET_REFER",
    RESET_FRANCHISE_FORM: "RESET_FRANCHISE_FORM",
    RESET_PARTNER_FORM: "RESET_PARTNER_FORM",
    RESET_LOAN_FORM: "RESET_LOAN_FORM",
    RESET_BIKE_FORM: "RESET_BIKE_FORM",
    RESET_BIKE_BRANDS: "RESET_BIKE_BRANDS",
    RESET_BIKE_BRANCHES: "RESET_BIKE_BRANCHES",
    RESET_BIKE_BRANCH_DATE: "RESET_BIKE_BRANCH_DATE",
    RESET_CLASSIFIED: "RESET_CLASSIFIED",
    MAKE_AN_OFFER: "MAKE_AN_OFFER",
    USED_CAR_LEAD_INFO: "USED_CAR_LEAD_INFO",
    RESET_MARKETING_LEAD_FORM: "RESET_MARKETING_LEAD_FORM",
    RESET_LEAD_BRANCH_SELECTIONS: "RESET_LEAD_BRANCH_SELECTIONS",
    RESET_TOP_SELLING_CARS: "RESET_TOP_SELLING_CARS",
    RESET_FEATURED_CARS: "RESET_FEATURED_CARS",
    RESET_EXCHANGE_CAR_DETAILS: "RESET_EXCHANGE_CAR_DETAILS",
    RESET_TOP_DEALS_REDUCER: "RESET_TOP_DEALS_REDUCER",
    RESET_LISTING_INFO: "RESET_LISTING_INFO",
    RESET_LOAN_CALCULATION_DATA: "RESET_LOAN_CALCULATION_DATA",
    RESET_BUYER_SELLER_DETAIL: "RESET_BUYER_SELLER_DETAIL",
    RESET_BIKE_RESCHEDULE: "RESET_BIKE_RESCHEDULE",
    RESET_BIKE_CONFIRMATION_DETAILS: "RESET_BIKE_CONFIRMATION_DETAILS",
    RESET_WISHLISTED_CARS: "RESET_WISHLISTED_CARS",
    RESET_WISHLISTED_LOGIN: "RESET_WISHLISTED_LOGIN",
    RESET_TOAST_MESSAGE: "RESET_TOAST_MESSAGE",
    RESET_CAR_FINDER_LISTING: "RESET_CAR_FINDER_LISTING",
    RESET_CAR_FINDER_CAR_LIST: "RESET_CAR_FINDER_CAR_LIST",
    RESET_CAR_SERVICES_DATA: "RESET_CAR_SERVICES_DATA",
    RESET_CAR_SERVICE_LEAD_FORM: "RESET_CAR_SERVICE_LEAD_FORM",
    RESET_CAR_SERVICE_INFO: "RESET_CAR_SERVICE_INFO",
    RESET_CAR_SERVICE_BOOK: "RESET_CAR_SERVICE_BOOK",
    RESET_LEAD_FUEL_TYPE: "RESET_LEAD_FUEL_TYPE",
    RESET_DEAL_PAYMENT_INFO: "RESET_DEAL_PAYMENT_INFO",
    RESET_CARS24_SELECT: "RESET_CARS24_SELECT",
    RESET_GS_BUY_CAR_DETAILS: "RESET_GS_BUY_CAR_DETAILS",
    RESET_CONSUMER_FINANCE: "RESET_CONSUMER_FINANCE",
    RESET_MY_BOOKINGS: "RESET_MY_BOOKINGS",
    RESET_LOCATION_PICKER_MODAL: "RESET_LOCATION_PICKER_MODAL",
    RESET_CHECKOUT: "RESET_CHECKOUT",
    RESET_CHAT_ICON: "RESET_CHAT_ICON",
    RESET_CALLBACK_DETAILS: "RESET_CALLBACK_DETAILS",
    RESET_C24Q_PRICE: "RESET_C24Q_PRICE",
    RESET_CUSTOMER_CAR_INSPECTION_PARAMETER: "RESET_CUSTOMER_CAR_INSPECTION_PARAMETER",
    SAVED_FILTERS: "SAVED_FILTERS",
    RESET_RECOMMENDED_CARS: "RESET_RECOMMENDED_CARS",
    RESET_DELIVERY_INFO: "RESET_DELIVERY_INFO",
    RESET_HOME_LIST: "RESET_HOME_LIST",
    RESET_SITEMAP_LIST: "RESET_SITEMAP_LIST",
    RESET_NUDGE_LIST: "RESET_NUDGE_LIST",
    RESET_AUC2B_TRADE_IN: "RESET_AUC2B_TRADE_IN",
    PRE_APPROVAL_LOAN: "PRE_APPROVAL_LOAN",
    RESET_HEADER: "RESET_HEADER",
    RESET_TRADE_IN: "RESET_TRADE_IN",
    RESET_USER_REGO_VIN: "RESET_USER_REGO_VIN",
    RESET_NO_KBB_TRADE_IN: "RESET_NO_KBB_TRADE_IN",
    RESET_FINANCE_POST_PAYMENT_REDUCER: "RESET_FINANCE_POST_PAYMENT_REDUCER",
    RESET_BOOKED_TASKS_REDUCER: "RESET_BOOKED_TASKS_REDUCER",
    RESET_BOOK_INSPECTION: "RESET_BOOK_INSPECTION",
    RESET_SUPPORT_WIDGET: "RESET_SUPPORT_WIDGET",
    RESET_GUEST_LOGIN: "RESET_GUEST_LOGIN",
    RESET_MAKE_MODEL_FLOW: "RESET_MAKE_MODEL_FLOW",
    RESET_PAYMENT_CONFIG: "RESET_PAYMENT_CONFIG",
    RESET_ADDONS: "RESET_ADDONS",
    RESET_CLP_DATA: "RESET_CLP_DATA",
    RESET_FINANCE_LANDING_PAGE: "RESET_FINANCE_LANDING_PAGE",
    RESET_TEST_DRIVE: "RESET_TEST_DRIVE"
};

export const stringConstants = {
    YES: "yes",
    NO: "no"
};

//added undefiend for local env - has to tbe removed
const baseUrl = {
    privateProduct: {
        QA: "https://consumer-private-web-qa.ninja24.in/account",
        STAGING: "https://stage-c2b-website.qac24svc.dev/account",
        LNOD: "https://stage-c2b-website.qac24svc.dev/account",
        PRODUCTION: "https://www.cars24.com/account"
    },
    c2c: {
        QA: "http://c2cweb-qa.ninja24.in/buy-used-cars",
        STAGING: "https://stage-c2b-website.qac24svc.dev/buy-used-cars",
        // STAGING: "http://localhost:3000//buy-used-cars",
        LNOD: "https://stage-c2b-website.qac24svc.dev/buy-used-cars",
        PRODUCTION: "https://www.cars24.com/buy-used-cars"
    },
    c2bPHP: {
        STAGING: "https://staging.cars24.com",
        PRODUCTION: "https://www.cars24.com"
    }
};

export const redirect = {
    AUTH_LOGIN: `${baseUrl.privateProduct[currentEnv]}/login`,
    AUTH_APPOINTMENTS: `${baseUrl.privateProduct[currentEnv]}/appointments`,
    AUTH_BIDS: `${baseUrl.privateProduct[currentEnv]}/bids`,
    AUTH_PROFILE: `${baseUrl.privateProduct[currentEnv]}/profile`,
    c2C: (path = "") => `${baseUrl.c2c[currentEnv]}${path}`,
    CARS24_HOME: appUrl,
    BYE_BYE_DRIVE: `${appUrl()}/byebyedrive/`,
    BLOG: `${appUrl()}/blog`,
    RC_TRANSFER: `${appUrl()}/rc-transfer`,
    HOW_IT_WORKS: `${appUrl()}/how-it-works`,
    WE_ARE_HIRING: "https://cars24.recruitee.com/",
    FAQ: `${appUrl()}/faq`,
    FAQ_AE: `${appUrl()}/ae/faq`,
    HELP: `${appUrl()}/need-help`,
    BECOME_OUR_PARTNER: `${appUrl()}/become-our-partner`,
    BRANCHES: `${appUrl()}/centres`,
    CAR_LOAN: `${appUrl()}/car-loan`,
    TESTIMONIALS: `${appUrl()}/testimonials/`,
    GOOGLE_PLAY_STORE_APP: "https://play.google.com/store/apps/details?id=com.cars24.seller&hl=en_IN",
    APPLE_STORE_APP: "https://itunes.apple.com/in/app/cars24-sell-car-at-best-price/id1364492085?mt=8",
    FINANCIAL_SERVICES_TERMS_CONDITIONS: "https://img.24c.in/cars24/docs/finance/terms_&_conditions.pdf",
    CAR_SERVICE: `${appUrl()}/car-service?itm_source=car_service&itm_medium=nav_bar`,
    MY_BOOKINGS: `${appUrl()}/my-bookings`
};

export const appRedirect = {
    HOME: "/",
    BLOG: "/blog",
    RC_TRANSFER: "/rc-transfer",
    HOW_IT_WORKS: "/how-it-works",
    WE_ARE_HIRING: "https://cars24.recruitee.com/",
    FAQ: "/faq",
    HELP: "/need-help",
    BECOME_OUR_PARTNER: "/become-our-partner",
    BRANCHES: "/centres"
};

export const YOUTUBE_THUMBNAIL = "https://img.youtube.com/vi/4vArfT4vSCg/maxresdefault.jpg";

export const CITY_KEY = "selectedCity";

export const MISSED_CALL_CHECK_INTERVAL = 30000;

export const STEP_NAMES = {
    Brand: "brand",
    Model: "model",
    FuelType: "fuelType",
    Transmission: "transmission",
    Year: "year",
    Variant: "variant",
    State: "registrationState",
    Confirmation: "confirmation",
    KilometerDriven: "kilometersDriven",
    Branch: "branch",
    Date: "date",
    Time: "time",
    Override: "override",
    User: "user",
    Location: "location",
    Price: "price",
    Body: "body",
    Priority: "priority",
    CarService: "carService",
    Personal: "personal",
    Residential: "residential",
    Income: "income"
};

export const OTP_MAX_LENGTH = 4;
export const OTP_MAX_LENGTH_EMAIL = 6;

export const HTTP_CODES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    SUCCESS: 200,
    FOUND: 302,
    MOVED_PERMANENTLY: 301,
    PERMANENTLY_REMOVED: 410,
    INTERNAL_SERVER_ERROR: 500,
    GATEWAY_TIMEOUT: 504
};

export const KEY_DOWN_CODES = {
    DELETE: 8
};

export const LEAD_STATUSES = {
    CREATED: "Created",
    CAPTURED: "AppointmentCaptured"
};

export const DEFAULT_SLIDER_SETTINGS = {
    slidesToShow: 3.5,
    slidesToScroll: 1,
    infinite: false,
    arrow: true
};

export const BRANCH_STORE_TYPES = ["franchise", "store"];

export const BRANCHES_TOP_OFFSET = 170;

export const BRANCH_LIST_RIGHT_OFFSET = 275;

export const LOCAL_STORAGE_KEYS = {
    userProfile: "userProfile",
    financeUniqueId: "financeUniqueId",
    financeScreen: "finance_screen",
    tradeInToggleGuide: "tradeInToggleGuide",
    seenAppointmentIds: "seenAppointmentIds",
    closedClpSaleNudge: "closedClpSaleNudge",
    seenTradeInBannerCount: "seenTradeInBannerCount",
    show_web_to_app_nudge: "showWeb2AppNudge",
    show_price_confidence_nudge: "showPriceConfidenceNudge",
    price_confidence_nudge_seen_count: "PriceConfidenceNudgeSeenCount",
    tradeInDesktopAU: "tradeInDesktopAU",
    tradeInMobileAU: "tradeInMobileAU",
    similarCarsSeenCount: "similarCarsSeenCount"
};

export const SESSION_KEYS = {
    S20_ORIENTATION_CHANGE_MESSAGE: "S20_ORIENTATION_CHANGE_MESSAGE",
    PRICE_CONFIDENCE_NUDGE_STATE: "price_confidence_nudge_state", // {} stores modal seen state and nudge closed state
    TEST_DRIVE_FINANCE_QUOTES_COACHMARK: "test_drive_finance_quotes_coachmark",
    MOBILE_BOTTOM_ANIMATION_CLICKED: "mobile_bottom_animation_clicked",
    CLOSED_TEST_DRIVE_STRIP_NUDGE: "CLOSED_TEST_DRIVE_STRIP_NUDGE"

};

export const FEATURE_FLAG_KEYS = {
    KBB_FEATURE_FLAG: "kbbFeatureFlag",
    CHECKOUT_REVAMP: "checkoutRevampFlag",
    BUY_WARRANTY: "buyWarrantyFlag",
    DELIVERY_TIMELINE: "deliveryTimeLineFlag",
    TEST_DRIVE_FLAG: "testDriveFlag"
};

export const DEFAULT_MOBILE_SLIDER_SETTINGS = {
    slidesToShow: 1.14,
    slidesToScroll: 1,
    infinite: false,
    arrow: false
};

export const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg"];

export const USED_CAR_ALLOWED_CITIES = [];

export const COOKIE_SEARCH_KEYWORDS = "c2b-searched-keywords";

export const RECENTLY_APPLIED_FILTERS = "c2b-recently-applied-filters";

export const CITY_NOT_LISTED = {
    background_image: null,
    background_image_mobile: null,
    city_name: "My city is not listed here"
};

export const IGNORE_PRIVATE_PRODUCT_APIS = [`${ppApiUrl()}/pp/ums/users`];

export const ALTERNATE_LEAD_CITIES = ["769", "648", "650"];
export const RELEVANT_ORDER_EXPERIMENT_CITIES = ["2130", "2423", "4709", "5", "2", "6", "132", "134", "8597"];
export const PRICE_REVISION_CITIES = ["2378", "777", "5732"];
// export const DEAL_RATING_IGNORED_CITIES = [...RELEVANT_ORDER_EXPERIMENT_CITIES];
export const DEAL_RATING_IGNORED_CITIES = [];

export const LEAD_FORM = {
    DEFAULT: "default",
    ONE_STEP: "oneStep",
    BIKE: "bike",
    MARKETING: "marketing",
    CARFINDER: "carfinder",
    CAR_SERVICE: "carService",
    FINANCE_FORM: "financeForm"
};

export const COLUMN_MAPPING = {
    1: "col-12",
    2: "col-6",
    3: "col-4",
    4: "col-3",
    6: "col-2",
    12: "col-1"
};

export const EXPERIMENTS = {};

export const LOCATION_REQUEST_TYPE = {
    COORDINATES: "coordinates",
    PLACE: "place"
};

export const MAP_TYPE = {
    GOOGLE: "googlemap",
    MMI: "mapmyindia"
};

export const DISPOSITION_STATUS = {
    SOLD: "SOLD",
    PUBLISHED: "PUBLISHED",
    BOOKED: "BOOKED",
    LOST: "LOST",
    RE_PUBLISH: "RE-PUBLISH",
    RE_PUBLISH_1: "RE_PUBLISH",
    AUCTIONED: "AUCTIONED",
    REJECTED: "REJECTED",
    DRAFT: "DRAFT"
};

export const CAR_LIST_DISPLAY_TYPES = {
    CARD: "card",
    LIST: "list"
};

export const PRICE_RANGE_THRESHOLD = 1500000;
export const PRICE_RANGE_STEP = 10000;
export const PRICE_RANGE_BAR_GRAPH_STEP = 40000;
export const MAX_RANGE_THRESHOLD = 9999999;

export const DEFAULT_C2C_CITY = {
    city_id: "8597",
    city_name: "Delhi-NCR",
    city_slug: "delhi-ncr",
    pincode: "110001"
};

export const DEFAULT_BIKE_CITY = {
    city_id: "2",
    city_name: "New Delhi",
    city_slug: "new-delhi"
};

export const VIRUTAL_CITY = {
    city_id: DEFAULT_C2C_CITY.city_id,
    city_name: DEFAULT_C2C_CITY.city_name,
    city_slug: DEFAULT_C2C_CITY.city_slug,
    isReplicaOfCity: "2"
};

export const DEFAULT_INTEREST_RATE = 18;
// export const INTEREST_INCREMENTAL_RATE = 0.1;

export const AD_TYPE = {
    INSTANTLY_AVAILABLE: "instantlyAvailable",
    MONEYBACK_GUARANTEE: "moneybackGuarantee",
    GET_CAR_PRICE: "getCarPrice",
    FINANCE_AVAILABLE: "financeAvailable",
    WARRANTY_AVAILABLE: "warrantyAvailable",
    PRICE_DROP_AVAILABLE: "priceDropAvailable",
    VIEW_360_AVAILABLE: "view360Available",
    CAR_FINDER: "carFinder",
    DEAL_RATING: "dealRating"
};

export const MIN_LOAN_AMOUNT = 50000;

export const CAR_LIST_EVENT_NAMES = {
    LISTING: "Buy_used_cars_C2C_Car_Listing_Page",
    B2C_LISTING: "Buy_used_cars_B2C_Car_Listing_Page",
    MONEYBACK: "Buy_used_cars_C2C_Car_Listing_Page_Moneyback",
    INSTANTLY_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Instantly_Available",
    FINANCE_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Finance_Available",
    PRICE_DROP: "Buy_used_cars_C2C_Car_Listing_Page_Price_Drop",
    HOME_PAGE: "Buy_used_cars_C2C_Car_Home_Page",
    SELL_VALUATION_PAGE: "Sell_valuation_page",
    B2C_HOME_PAGE: "Buy_used_cars_B2C_Homepage",
    FILTER_PAGE: "Buy_used_cars_C2C_filter_page",
    DETAILS: "Buy_used_cars_C2C_Car_Details_Page",
    VIEW_360_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_View_360_Available",
    WHISLIST: "Buy_used_cars_C2C_Whislist_page",
    DETAIL_SIMILAR_CARS: "Buy_used_cars_C2C_Car_detail_page_similiar_cars",
    MY_ACCOUNT: "Buy_used_cars_B2C_my_account",
    CAR_COMPARISSION_CDP: "Buy_used_cars_car_comparission_cdp",
    FINANCE_PAGE: "Buy_used_cars_finance_page",
    LISTING_PAGE: "listing",
    PAGE_HOME: "HomePage",
    DETAIL_PAGE: "Detail page",
    SEARCH_RESULT: "Search Result",
    WISH_LIST: "Wishlist",
    TEST_DRIVE_FINANCE: "TD_finance"
};
export const WISHLIST_SOURCE_TYPES = {
    CLP: "clp",
    HOMEPAGE: "homepage",
    CDP: "cdp",
    WISHLIST: "wishlist",
    RECCENTLY_ADDED_CARS: "reccently added cars",
    RECENTLY_VIEWED_CARS: "recently viewed cars",
    SIMILAR_CAR_LIST: "similar car list"
};

export const CREDR_VALID_CITIES = ["4709"];

export const BANNER_LOCATIONS = {
    experts_say: "experts_say",
    featured_tweets: "featured_tweets",
    faq: "faq"
};

export const SCRIPT_TYPES = {
    mmi: "mmi"
};

export const AB_TEST_VARIATIONS = {
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B",
    TEST: "Test",
    CONTROL: "Control",
    CONTROL_TEST: "Control-Test",
    NON_TG: "Non TG"

};

export const HIDE_POPUP_FOR_URLS = [
    /([a-z]*)(confirmation)([a-z]*)/gi,
    /([a-z]*)(customer-review)([a-z]*)/gi,
    /(\/mobile-apps)/gi,
    /(offer|car-prices|specifications|images|variants|reviews|colors|mileage|service-cost|faqs|rto-vehicle-registration-details|-prices-in-|car-finder)/gi,
    /(mycar)([a-z]*)/gi,
    /(buyer-details)([a-z]*)/gi,
    /(seller-details)([a-z]*)/gi,
    /tokenpayment([a-z]*)/gi,
    /cars24-select/gi
];

export const PATHS = [
    /(\/centres)/gi,
    /(\/mobile-apps)/gi,
    /(\/customer-review)/gi,
    /(\/faq)/gi,
    ...HIDE_POPUP_FOR_URLS
];

export const CTA_LOCATIONS = {
    LIST_SELL_CAR: "classified",
    INSTANT_SELL_CAR: "instant_sell_car",
    REFINANCE: "REFINANCE"
};

export const PRICES = {
    GREAT: "great",
    GOOD: "good",
    FAIR: "fair",
    OVER_PRICED: "overpriced"
};

export const BIKE_BOOKING_STATUS_TYPE = {
    BOOKED: "BOOKED",
    CONFIRMED: "CONFIRMED",
    INSPECTED: "INSPECTED",
    PURCHASED: "PURCHASE"
};

export const APPOINTMENT_TYPE = {
    BRANCH: "branch",
    HOME: "inspection",
    CRM: "CRM"
};

export const APPOINTMENT_SCREEN = {
    DEFAULT: "default",
    ADDRESS: "address",
    DOCUMENT: "document"
};

export const FORM_TYPE = {
    CAR: "car",
    BIKE: "bike",
    CLASSIFIED_RESCHEDULE: "classified_reschedule",
    ONE_STEP: "one_step",
    BIKE_RESCHEDULE: "bike_reschedule",
    PAYWALL_BOOK_NOW: "paywall_book_now",
    PAYWALL_MAKE_OFFER: "paywall_make_offer",
    GS_BOOK_NOW: "gs_book_now",
    TOKEN_PAYMENT: "token_payment",
    VONE_STEP: "vone_step",
    CHECKOUT: "checkout"
};
// eslint-disable-next-line no-magic-numbers
export const MAX_ALLOWED_UPLOAD_SIZE = 5 * 1024 * 1000;

export const APPOINTMENT_LOCATION_TYPE = {
    LOCALITY: "locality",
    BIKE_LOCALITY: "bike_locality"
};

export const MAKE_AN_OFFER_SOURCE = {
    EMI: "emi",
    DETAILS: "details",
    GALLERY: "gallery"
};
export const LOC_ID = {
    HOME: 3,
    BRANCH: 1,
    CLASSIFIED: 4
};

export const VERIFIED_SOURCE = {
    PAYMENT: "payment"
};

export const USED_CAR_LEAD_FLOWS = {
    BOOK_CAR: "BOOK_CAR",
    MAKE_AN_OFFER: "MAKE_AN_OFFER"
};

export const CUSTOM_ACTION_TYPES = {
    SET_LOCATION_NAME: "SET_LOCATION_NAME",
    SET_WHATSAPP_CONSENT: "SET_WHATSAPP_CONSENT",
    SET_ACTIVE_SCREEN: "SET_ACTIVE_SCREEN",
    LEAD_ACTION: "LEAD_ACTION",
    SET_DATA: "SET_DATA",
    SET_VISITED_STATE: "SET_VISITED_STATE",
    OVERRIDE_CURRENT_POSITION_KEYS: "OVERRIDE_CURRENT_POSITION_KEYS",
    SHOW_PROCEED_BUTTON: "SHOW_PROCEED_BUTTON",
    SET_STYLES: "SET_STYLES"
};

export const IMAGE_UPLOADER_ENUMS = {
    UPLOAD: "upload",
    DELETE: "delete",
    VALIDATION_FAILURE: "validationFailure"
};

export const SELLER_PORTAL_SCREEN = {
    HOME: "HOME",
    DELIST: "DELIST",
    REVISE: "REVISE"
};

export const FALLBACK_C2B_CITY_ID = "2";

export const LEAD_TYPES = {
    REFINANCE: "refinance",
    EXCHANGE: "EXCHANGE"
};

export const CAR_SOURCE = {
    GS: "GS_CAR"
};

export const INACTIVE_CAR_STATES = [
    DISPOSITION_STATUS.LOST,
    DISPOSITION_STATUS.SOLD,
    DISPOSITION_STATUS.DRAFT,
    DISPOSITION_STATUS.AUCTIONED,
    DISPOSITION_STATUS.REJECTED
];

export const CAR_FINDER_SCREEN_TYPES = {
    LANDING: "LANDING",
    LEAD_FORM: "LEADFORM",
    LISTING: "LISTING",
    PERSONAL_DETAIL: "PERSONAL_DETAIL"
};

export const CAR_SERVICE_DISCOUNT = 1.2;

export const COOKIE_KEYS = {
    CAR_SERVICE_FIRST_CLICK_URL: "car_service_first_click_url",
    KBB_BANNER_SEEN_COUNT: "kbb_banner_seen_count",
    CLOSED_CLP_SALE_NUDGE: "close_clp_sale_nudge",
    CLP_SALE_ANIMATION_SEEN: "clp_sale_animation_seen"
};

export const INSPECTION_VIEW_MORE_AFTER = 6;

const CITY_ID = {
    MUMBAI: "2378",
    BENGALURU: "4709"
};

export const BOOK_NOW_CITIES = [];
export const MAKE_OFFER_CITIES = [CITY_ID.BENGALURU];

export const PAYWALL_FLOW_TYPE = {
    PAYWALL_OFFER: "paywall_offer",
    PAYWALL_BOOKNOW: "paywall_booknow"
};

export const WHATSAPP_STAGE = {
    STAGE_1: "stage_1",
    STAGE_2: "stage_2"
};

export const PAYMENT_OPTIONS = {
    FINANCE: "FINANCE",
    CASH: "CASH"
};

export const ANGLE = {
    ZERO: 0,
    NINETY: 90,
    HUNDRED_EIGHTY: 180,
    TWO_HUNDRED_SEVENTY: 270,
    THREE_HUNDRED_SIXTY: 360
};

export const DEFAULT_CITY = {
    AE: {
        city_id: "73662522",
        city_name: "Dubai",
        city_slug: "dubai",
        code: "CC_AE_1"
    },
    IN: {
        city_id: "8597",
        city_name: "Delhi-NCR",
        city_slug: "delhi-ncr"
    },
    AU: {
        city_id: "",
        city_name: "Brisbane",
        code: "BNE100",
        pincode: 4000,
        city_slug: "brisbane"
    }
};

export const COUNTRY = {
    name: "Australia",
    slug: "australia",
    id: "",
    code: "au"
};

export const COUNTRY_CODES = {
    IN: "IN",
    AE: "AE",
    AU: "AU"
};

export const CURRENCY = {
    IN: "₹",
    AE: "AED",
    AU: "$"
};

export const CURRENCY_ABBREVIATION = {
    IN: "INR",
    AE: "AED",
    AU: "AUD"
};

export const COUNTRY_CODE = {
    IN: "IN",
    AE: "AE",
    AU: "AU"
};

// export const AB_TEST_CONST = AB_TEST_VARIATIONS.VARIANT_A;
export const ACTIVE_EXPERIMENT_TEST = AB_TEST_VARIATIONS.VARIANT;
export const ACTIVE_VARIANT_SCREEN = {
    FUELTYPE: "fueltype",
    TRANSMISSIONTYPE: "transmissionType",
    VARIANT: "variant"
};

export const AU_HOMEPAGE_PAGINATION_SIZE = 5;
export const MY_BOOKINGS_PAGINATION_SIZE = 20;
export const AU_CITY = "au_city";
//todo: remove duplicated constant
export const DEFAULT_AU_CITY_CODE = DEFAULT_CITY.AU.code;
export const DEFAULT_AU_PINCODE = DEFAULT_CITY.AU.pincode;
export const DEFAULT_AU_CITY_NAME = DEFAULT_CITY.AU.city_name;

export const WHATSAPP_NUMBER_AU = { label: "+12013503301", value: "12013503301" };
export const HELPLINE_NUMBER_AU = { label: "1800 227 724", value: "1800 227 724" };
export const C2B_HELPLINE_NUMBER_AU = { label: "+61 482 091 942", value: "+61 482 091 942" };
export const FINANCE_EMAIL_AU = "financing.au@cars24.com";
export const SMS_NUMBER_AU = { label: "0488 825 496", value: "0488825496" };
export const SUPPORT_EMAIL = "hello.au@cars24.com";

export const CALL_SUPPORT_START_TIME = NUMBER.EIGHT;
export const CALL_SUPPORT_END_TIME = NUMBER.TWENTY;

export const SELL_SUPPORT_START_TIME = NUMBER.EIGHT;
export const SELL_SUPPORT_END_TIME = NUMBER.TWENTY;

export const WHATSAPP_SUPPORT_START_TIME = NUMBER.NINE;
export const WHATSAPP_SUPPORT_END_TIME = NUMBER.SEVENTEEN;

export const ADDRESS_LEVEL_TYPE = {
    SUBPREMISE: "subpremise",
    STREET_NUMBER: "street_number",
    ROUTE: "route",
    LOCALITY: "locality",
    ADMINISTRATIVE_AREA_LEVEL_2: "administrative_area_level_2",
    ADMINISTRATIVE_AREA_LEVEL_1: "administrative_area_level_1",
    COUNTRY: "country",
    POSTAL_CODE: "postal_code"
};

export const PHONE_COUNTRY_CODE = 61;
export const DEFAULT_PHONE_LENGTH = 10;

export const EXTERNAL_LINKS = {
    DRIVA_POLICY: "https://www.driva.com.au/legal",
    CAREERS_LINK: "https://www.linkedin.com/jobs/cars24-australia-jobs-worldwide?f_C=73063943&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0",
    WORKABLE_CAREER_LINK: "https://apply.workable.com/cars24/?lng=en",
    VICTORIA_HUB_MAP_LINK: "https://www.google.com/maps/place/CARS24+Melbourne+-+Buy,+Sell+%26+Finance+Used+Cars/@-37.837982,144.7913795,17z/data=!4m15!1m8!3m7!1s0x6ad661e523126b7f:0x9aa78a73d98bc41f!2s118+Cherry+Ln,+Laverton+North+VIC+3026,+Australia!3b1!8m2!3d-37.8379863!4d144.7939544!16s%2Fg%2F11c4jjhjlt!3m5!1s0x6ad6617696168445:0xf55bfeb22f39c563!8m2!3d-37.838041!4d144.7936896!16s%2Fg%2F11t_t81d6v?entry=ttu"
};

export const COMPARISON_RATE_AMOUNT = 30000;

export const API_SOURCE = {
    M_SITE: "mSite",
    WEB_APP: "WebApp",
    DESKTOP: "Desktop",
    ANDROID: "App-Android",
    IOS: "App-iOS"
};

export const PAYMENT_SURCHARGE = { value: 0.6, label: "0.6%" };
export const PAYMENT_SURCHARGE_NEW = {
    DEBIT: { value: 0.82, label: "0.82%" },
    CREDIT: { value: 1.48, label: "1.48%" },
    value: 0.82, label: "1.48%"
};

export const LOAN_PROVIDER = {
    DRIVA: "DRIVA",
    BYOF: "BYOF",
    CARS24: "CARS24",
    NONE: "NONE"
};
export const REFURBISHMENTS_URL = "https://drive.google.com/file/d/1pd-0cihvyuNcRbu5gGzqruHFfymObOMA/view";

export const PAYMENT_HISTORY_STATUS = {
    INITIATED: "INITIATED",
    SUCCESS: "SUCCESS"
};

// export const WARRANTY_TERMS_CONDITION = "https://drive.google.com/file/d/1mA5j2pdxXfEcnD5ysYXmL34GOcq43Tlb/view";
export const WARRANTY_TERMS_CONDITION = "https://drive.google.com/file/d/1ywLYzVTXImB8QJfl-J9WtXmB7DtBTIE8/view?usp=sharing";

export const CUSTOMER_REVIEW = "https://blog-au.c24.tech/pages/reviews/";

export const DEFAULT_CITY_PINCODE_MAP = {
    NSW: 2000,
    VIC: 3000,
    QLD: 4000,
    SA: 5000
};

export const ONSALE = "On sale";
export const CONFIG_ONSALE = "onSale";
export const MEDIA_SOURCE = {
    helloAR: "helloAR",
    s20: "s20"
};

export const SLIDE_TYPES = {
    EMI: "EMI",
    DOWN_PAYMENT: "DOWN_PAYMENT"
};

export const DEFAULT_TENURE = [
    {
        label: "36",
        value: 36
    },
    {
        label: "48",
        value: 48
    },
    {
        label: "60",
        value: 60
    },
    {
        label: "84",
        value: 84
    }
];

export const DEFAULT_TENURE_VARIANT_B = [
    {
        label: "3",
        value: 3
    },
    {
        label: "4",
        value: 4
    },
    {
        label: "5",
        value: 5
    },
    {
        label: "7",
        value: 7
    }
];

export const DEFAULT_TENURE_MSITE = [
    {
        label: "3",
        value: 36
    },
    {
        label: "4",
        value: 48
    },
    {
        label: "5",
        value: 60
    },
    {
        label: "7",
        value: 84
    }
];

// export const STATES_AU = [
//     {label: "Queensland", value: "Queensland"},
//     {label: "Victoria", value: "Victoria"},
//     {label: "New South Wales", value: "New South Wales"},
//     {label: "South Australia", value: "South Australia"},
//     {label: "Nothern Territory", value: "Nothern Territory"},
//     {label: "Western Australia", value: "Western Australia"},
//     {label: "Tasmania", value: "Tasmania"}
// ];

export const SALE_META_TYPE = {
    LISTING: "listingMeta",
    HOMESTRIP: "stripMeta"
};

export const DOWNLOAD_APP_URL = "";
export const DOWNLOAD_APP_URL_HEADER = "https://cars24aus.app.link/DownloadApp";
export const DOWNLOAD_APP_URL_FOOTER = "https://cars24aus.app.link/CARS24AppDownload";

export const DOWNLOAD_APP_URL_CTA_HEADER = {
    "VARIANT_A": "https://cars24aus.app.link/DJrKFVxUbDb",
    "VARIANT_B": "https://cars24aus.app.link/i14ItMCUbDb",
    "VARIANT_C": "https://cars24aus.app.link/sd41LtNUbDb",
    "WEB_2_APP_BANNER": "https://cars24aus.app.link/RPyRp5O8iKb"
};

export const WEB_2_APP_BANNER = "WEB_2_APP_BANNER";

export const DOWNLOAD_APP_URL_BOTTOM_NUDGE = {
    "VARIANT_A": "",
    "VARIANT_B": "https://cars24aus.app.link/tKpTTDWUbDb",
    "VARIANT_C": "https://cars24aus.app.link/OKngy0YUbDb"
};

export const DOWNLOAD_APP_URL_BANNER_NUDGE = {
    "VARIANT_A": "https://cars24aus.app.link/4rbR8WaVbDb",
    "VARIANT_B": "",
    "VARIANT_C": "https://cars24aus.app.link/7DeR6BeVbDb"
};

export const DOWNLOAD_APP_HOMEPAGE_BANNER = {
    "VARIANT_A": "https://cars24aus.app.link/Axf2e7nVbDb",
    "VARIANT_B": "https://cars24aus.app.link/Axf2e7nVbDb",
    "VARIANT_C": "https://cars24aus.app.link/Axf2e7nVbDb"
};

export const MOBILE_OS = {
    IOS: "iOS",
    ANDROID: "Android"
};

export const ISLOGGEDIN_COOKIE = "isLoggedIn";

export const APP_SENT_STATUS = {
    SUCCESS: "SUCCESS",
    PENDING: "PENDING",
    ERROR: "ERROR"
};

export const APP_LINK_TIMER_COUNTDOWN = 1000;

export const HTTP_STATUS_CODE = {
    NOT_FOUND: 404
};

// export const UTM_SOURCE = "utm_source";
// export const UTM_MEDIUM = "utm_medium";
// export const UTM_CAMPAIGN = "utm_campaign";

export const WEB_SOURCE = {
    msite: "mSite",
    desktop: "Desktop"
};

// export const UTM_MEDIUM = {
//     footer: "footer",
//     banner: "banner"
// };

// export const UTM_CAMPAIGN = {
//     downloadApp: "download_app"
// };

// export const UTM_SOURCE = {
//     msite: "mSite",
//     desktop: "Desktop"
// };

export const COUPON_STATUS = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED"
};

export const DISCOUNT_TYPE = {
    COUPON: "COUPON"
};

export const POST_COUPON_STATUS = {
    APPLIED: "APPLIED"
};

export const EXTENDEDWARRANTY = "extendedWarranty";

export const EGCLOOKUP = {
    egc: "Excluding government charges(EGC)",
    motorVehicleDuty: "Motor vehicle duty",
    registrationCost: "months registration",
    ctp: "CTP",
    transferFee: "Transfer fee"
};

export const SPECIALPAGETYPE = {
    COMMON: "COMMON",
    LOCATION_BASED: "LOCATION_BASED"
};

export const LISTING_CONFIG = {
    [SPECIALPAGETYPE.COMMON]: {
        IS_META_ENABLED: true,
        IS_SEARCH_ENABLED: true,
        IS_FILTER_ENABLED: true,
        IS_COACHMARK_ENABLED: true,
        COMPONENT: "CarList"
    },
    [SPECIALPAGETYPE.LOCATION_BASED]: {
        IS_META_ENABLED: false,
        IS_SEARCH_ENABLED: true,
        IS_FILTER_ENABLED: false,
        IS_COACHMARK_ENABLED: false,
        COMPONENT: "CarList"
    }
};

export const UP_ARROW_ROTATE_DEGREE = 90;
export const DOWN_ARROW_ROTATE_DEGREE = -90;

export const AU_STATES_CODE = {
    NSW: "NSW",
    VIC: "VIC",
    SA: "SA",
    QLD: "QLD"
};

export const GEO_LOCATION_STATE_CODES = {
    [AU_STATES_CODE.VIC]: "Victoria",
    [AU_STATES_CODE.QLD]: "Queensland",
    [AU_STATES_CODE.NSW]: "New South Wales"
};

export const EGC_BREAK_UP = {
    EGC: "egc",
    MOTORVEHICLEDUTY: "motorVehicleDuty",
    CTP: "ctp",
    TRANSFERFEE: "transferFee",
    REGISTRATIONCOST: "registrationCost"
};

export const ORDER_TYPE = {
    TEST_DRIVE: "TEST_DRIVE",
    BUY_ONLINE: "BUY_ONLINE",
    PRODUCT_TEST_DRIVE: "PRODUCT_TEST_DRIVE"
};

export const CAR_COMPARE_CONST = {
    STORAGE_KEY: "CAR_COMPARE_BUCKET",    //  storage key for car compare CLP
    STORAGE_KEY_CDP: "CAR_COMPARE_BUCKET_CDP", // storage key for car compare CDP
    ACTIVATION_KEY: "CAR_COMPARE_ACTIVE",   //  feature is inactive/active CLP
    SECONDARY_STORAGE_KEY: "CAR_COMPARE_SECONDARY_BUCKET",  // this bucket is used to activate feature if user views CDP >= 2 on CLP
    MAX_CARS_COUNT: NUMBER.TWO,    // max no of cars that can be compared on CLP
    CARS_COUNT_CDP_DESKTOP: NUMBER.THREE,  // no of cars required to compare on CDP dweb
    CARS_COUNT_CDP_MSITE: NUMBER.TWO,  // no of cars required to compare on CDP msite
    INITIAL_FIELDS_SHOWN: NUMBER.SEVEN // no of  fields shown to user initially
};

export const CTA_POSITION = {
    TOP: "top",
    BOTTOM: "bottom"
};

export const BANNER_TYPE = {
    FINANCE_BANNER: "FINANCE_BANNER"
};

export const LANDING_ROUTE_CONSTANTS = {
    "FINANCEYOURCAR": "/finance-your-car",
    "SELLYOURCAR": "/sell-your-car",
    "DEFAULT": "/"
};

export const SUPPORT_WIDGET_HIDDEN_ROUTES = [
    "/financing",
    "/financing/",
    "/checkout/select-offer",
    "/checkout/select-offer/",
    "/checkout/add-ons",
    "/checkout/add-ons/"
];

export const C2B_ROUTES = [
    "/sell-your-car",
    "/car-valuation",
    "/my-sell-orders",
    "/book-inspection"
];

export const EXCLUDING_GOVT_CHARGES = "Excl. Govt. Charges";
export const DRIVE_AWAY_PRICE = "Drive-away price";

export const CONTACT_US_ENQUIRY_REASON = [
    { label: "I am interested in buying a car.", value: "I am interested in buying a car." },
    { label: "I am interested in selling a car.", value: "I am interested in selling a car." },
    { label: "I am waiting for my car delivery.", value: "I am waiting for my car delivery." },
    { label: "I have received my car and need help.", value: "I have received my car and need help." },
    { label: "I have a warranty claim.", value: "I have a warranty claim." },
    { label: "Other", value: "Other" }
];

export const EMAIL_US_ENQUIRY_REASON = [
    { label: "Interested in buying a car", value: "I am interested in buying a car" },
    { label: "Interested in selling a car", value: "I am interested in selling a car" },
    { label: "Question about my purchase", value: "Question about my purchase" },
    { label: "Finance enquiry", value: "Finance enquiry" },
    { label: "Warranty enquiry", value: "Warranty enquiry" },
    { label: "Other", value: "Other" }
];

export const REFERRER_SOURCE = "referrerSource";

export const SEO_COUNTRY_SELL_CAR_ALLOWED = [{ label: "Brisbane", spath: "sell-your-car-brisbane/" }, { label: "Melbourne", spath: "sell-your-car-melbourne/" }, { label: "Sydney", spath: "sell-your-car-sydney/" }];

export const WARRANTY_FINANCE = "warranty_finance";
export const FILTER_ENTITIES = {
    BODY_TYPE: "bodyType",
    PRICE: "quotedPrice",
    FUEL_TYPE: "fuelType",
    OWNERS: "ownerNumber",
    ODOMETER_READING: "odometerReading",
    TRANSMISSION: "transmissionType",
    REGISTRATION_CITY: "registrationCity",
    YEAR: "year",
    MAKE: "make",
    MAKE_DISCOUNT: "makeDiscount",
    MODEL: "model",
    COLOR: "colorCategory",
    DISCOUNT: "promo",
    DOWNPAYMENT: "downPayment",
    MONTHLY_EMI: "monthlyEmi",
    DOORS: "doors",
    SEATS: "seats",
    DRIVETYPE: "driveType",
    HUB: "hub",
    LIFE_STYLE: "lifestyle",
    DELIVERYTIME: "deliveryTime",
    PROMOTION: "promotion",
    SAFETYANDQUALITY: "ancapRating",
    MAKECITYNAME: "makeCityName",
    MODELCITYNAME: "modelCityName",
    CITYNAME: "cityName",
    TEST_DRIVE: "testDrive",
    EMI_PER_WEEK: "emiPerWeek",
    DEPOSIT: "deposit",
    BUDGET_RANGE: "budgetRange"
};

export const MORE_FILTERS_DESKTOP = [
    FILTER_ENTITIES.FUEL_TYPE,
    FILTER_ENTITIES.TRANSMISSION,
    FILTER_ENTITIES.COLOR,
    FILTER_ENTITIES.SEATS,
    FILTER_ENTITIES.SAFETYANDQUALITY,
    FILTER_ENTITIES.DRIVETYPE,
    FILTER_ENTITIES.DELIVERYTIME,
    FILTER_ENTITIES.DOORS,
    FILTER_ENTITIES.DISCOUNT,
    FILTER_ENTITIES.HUB,
    FILTER_ENTITIES.PROMOTION
];

export const AU_RECENTLY_VIEWD_KEY = "au_rv";
export const OFF_SALE = "Off sale";

export const EXTENDED_WARRANTY_OPTED_PAGE = {
    CAR_COVER: "CAR_COVER",
    ORDER_SUMMARY: "ORDER_SUMMARY"
};

export const GALLERY_TAB_TYPE = {
    exterior: "exterior",
    interior: "interior",
    imperfections: "imperfections",
    threeSixtyView: "threeSixtyView",
    threeSixtyViewUrl: "threeSixtyViewUrl",
    gallery: "gallery"
};

export const IMPERFECTION_GUIDE_URL = "https://au.viewer.cars24.com/assets/docs/refurb_pdf.pdf";
export const LENDER_SCREEN = "LENDER_SCREEN";
export const WARRANTY_AMOUNT_TYPE = "EMI";
export const TRADE_IN_REJECTION_TYPE = {
    HIGH_VALUATION: "HIGH_VALUATION",
    HARD_REJECTION: "HARD_REJECTION"
};

export const ENABLE_MINIMUM_SPLIT_PAY = "enableMinimumSplitPay";
export const DAP_CALLOUT_VIEWD = "dapCalloutViewd";

export const CALLBACK_SOURCE_SCREEN = {
    LOAN_OFFERS: "LOAN_OFFERS",
    LENDER_DETAILS: "LENDER_DETAILS",
    PAYMENT_DETAILS: "PAYMENT_DETAILS",
    TEST_DRIVE_CONFIRMATION: "TEST_DRIVE_CONFIRMATION"
};
export const DELIVERY_TIMELINE_MAPPING = {
    Queensland: {
        code: "QLD",
        pincode: 4000,
        fullStateName: "Queensland"
    },
    Victoria: {
        code: "VIC",
        pincode: 3000,
        fullStateName: "Victoria"

    },
    Newsouthwales: {
        code: "NSW",
        pincode: 2000,
        fullStateName: "New South Wales"
    },
    "New South Wales": {
        code: "NSW",
        pincode: 2000,
        fullStateName: "New South Wales"
    }
};

export const SET_DELIVERY_TIMELINE = "SET_DELIVERY_TIMELINE";

export const POST_JOURNEY_STEPS_TYPE = {
    POST_PAYMENT_JOURNEY: "postPaymentJourney",
    TASK_JOURNEY: "taskJourney",
    ST_TASK_JOURNEY: "stTaskJourney",
    GUEST_POST_PAYMENT_JOURNEY: "guestPostPaymentJourney"
};

export const BANK_TRANSFER = [
    {
        label: "Name",
        value: "GLOBAL CARS AUS PTY LTD",
        eventLabel: "WTName",
        key: "bankAccountName",
        amplitudeKey: "WT Name copied",
        amplitudeEvtProperty: { "BT Name copied": "yes" }
    },
    {
        label: "Account number",
        value: "850046",
        eventLabel: "WTAccNo",
        key: "bankAccountNumber",
        amplitudeKey: "WT AccNo copied",
        amplitudeEvtProperty: { "BT Accno copied": "yes" }
    },
    {
        label: "BSB",
        value: "032 044",
        eventLabel: "WT BSB",
        key: "bsb",
        amplitudeKey: "WT BSB copied",
        amplitudeEvtProperty: { "BT BSB copied": "yes" }
    },
    {
        label: "Reference",
        value: "",
        eventLabel: "WT reference",
        key: "reference",
        amplitudeKey: "WT reference copied",
        amplitudeEvtProperty: { "BT reference copied": "yes" }
    },
    {
        label: "Amount",
        value: "",
        eventLabel: "WT Amount",
        key: "bookingAmount",
        amplitudeKey: "WT Amount copied",
        amplitudeEvtProperty: { "BT amount copied": "yes" }
    }
];

export const USER_LOGIN_STATUS = {
    LOGGED_IN: "loggedin",
    LOGGED_OUT: "loggedout"
};
export const USER_LOGIN_MEDIUM = {
    EMAIL: "email",
    GOOGLE: "google",
    FACEBOOK: "facebook",
    APPLE: "apple"
};
export const LISTING_TYPE = {
    ALL_CARS: "all_cars",
    SEARCH: "search_list"
};
export const SALE_KEY = {
    BLACK_FRIDAY: "BLACK_FRIDAY",
    CHRISTMAS_SALE: "CHRISTMAS_SALE",
    EOFY_SALE: "EOFY_SALE"
};
export const USER_GUEST_EMAIL = "USER_GUEST_EMAIL";
export const SHOW_GUEST_EXIT_INTENT = "SHOW_GUEST_EXIT_INTENT";

export const PAYMENT_SUCCESS_TEXT = {
    DRIVA: "FINANCE",
    BYOF: "FINANCE",
    CARS24: "FINANCE",
    NONE: "NONE",
    BT: "BT",
    ZERO_DP: "ZERO_DP"
};

export const SMS_CHAT_NUMBER = { label: "+61 480 098 379", value: "+61480098379" };

export const WHATS_APP_MESSAGE = {
    WEB: "Hey Cars24 AU, I need help",
    MSITE: "I am looking for an assistance"
};

export const SMS_MESSAGE = {
    MSITE: "Hey Cars24, I have a query"
};

export const DELIVERY_ZONE = {
    ZONE_A: "Zone A",
    ZONE_B: "Zone B",
    ZONE_C: "Zone C"
};

export const FB_EVENTS_CATEGORY = {
    HOME_PAGE_MAKE: "homepage_make",
    HOME_PAGE_MAKE_MODEL: "homepage_make_model",
    LISTING_PAGE_MAKE: "listing_make",
    LISTING_PAGE_MAKE_MODEL: "homepage_make_model",
    SEARCH_SUGGESTIONS: "search_suggestions"
};

export const SELL_CAR_SEO_MENU = [
    {
        label: "Sell Car in Brisbane",
        spath: "sell-your-car-brisbane/"
    },
    {
        label: "Sell Car in Melbourne",
        spath: "sell-your-car-melbourne/"
    },
    {
        label: "Sell Car in Sydney",
        spath: "sell-your-car-sydney/"
    },
    {
        label: "Used Car Valuation",
        spath: "car-valuation/"
    }
];

export const TRADE_IN_EXPIRED = {
    APPLY_AGAIN: "APPLY_AGAIN"
};

export const TRADE_IN_UI = {
    INTRO: "INTRO",
    RESUME_VALUATION: "RESUME_VALUATION",
    EDIT_DETAILS: "EDIT_DETAILS",
    TRADE_IN_EXPIRED: "TRADE_IN_EXPIRED",
    WRITTEN_OFF: "WRITTEN_OFF"
};

export const SCREEN_ORIENTATION = {
    PORTRAIT: "Portrait",
    LANDSCAPE: "Landscape"
};
export const SEARCH_ANIMATED_PLACEHOLDER = ["Search Your Dream Car", "Search “Toyota Corolla”", "Search “Hyundai i30”", "Search Your Dream Car"];

export const PAYMENT_METHODS_NAME = "Debit/Credit card";
export const CDP_REVIEW_LINK = {
    google_review: "https://www.google.com/maps/place/CARS24+Australia/@-32.7153521,148.955806,6z/data=!4m6!3m5!1s0x6ad64304dde9db3d:0xdcbe0bace6f08656!8m2!3d-32.7153521!4d148.955806!16s%2Fg%2F11nghy02c_",
    trust_pilot: "https://au.trustpilot.com/review/cars24.com/au",
    product_review: "https://www.productreview.com.au/listings/cars24-australia"
};

export const PRELOAD_INDEX = [NUMBER.ZERO];
export const BANK_TRANSFER_SCREEN_STATUS = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    SHOW_PAYMENT_SUMMARY_INFO: "SHOW_PAYMENT_SUMMARY_INFO",
    RETRY: "RETRY"
};

export const PAGE_SOURCE = {
    CLP: "clp",
    CDP: "cdp",
    CDP_SIMILAR_CARS: "cdp - similar cars",
    HOME_PAGE: "homepage",
    WISHLIST: "wishlist",
    FINANCE_LANDING_PAGE: "finance_landing_page",
    SELL_VALUATION_PAGE: "sell_valuation_page",
    SELL: "C2B_flow"
};

export const GA_EVENT_CATEGORY = {
    [PAGE_SOURCE.CLP]: "Cars24_listing_page",
    [PAGE_SOURCE.CDP]: "Cars24_detail_page",
    [PAGE_SOURCE.HOME_PAGE]: "Cars24_home_page",
    [PAGE_SOURCE.SELL]: "C2B_flow"
};

export const BOOL_STRING = {
    TRUE: "true",
    FALSE: "false"
};

export const BOTTOM_NAV_ROUTES = {
    HOME: "au.home",
    LISTING: "au.listing",
    SELL_CAR: "c2b-trade",
    FINANCE_PAGE: "finance"
};

export const BOTTOM_NAV_ROUTES_CONFIG = {
    HOME: "/",
    SELL_CAR: "/sell-your-car/",
    FINANCE_PAGE: "/cars24-finance/"
};
export const SIMILAR_CAR_HEADING = "Similar cars you may like";
export const CLP_COLOR = {
    WHITE: "white"
};

export const HTTP_API_ERROR = "HTTP_API_ERROR";

export const DESKTOP_CONTRACT_STEP = {
    FORMVIEW: "FORMVIEW",
    DOCUMENT: "DOCUMENT"
};
export const MODEL_YEAR = "Model Year";

export const BOOTSPACELABEL = "Boot Inside View with Luggage";
export const BOOT_SPACE = "Boot Space";
export const SHOW_CDP_SEARCH_TOOLTIP = "SHOW_CDP_SEARCH_TOOLTIP";
export const FRONT_VIEW = "front view";

export const FILTER_TYPES = {
    RANGE_FILTER: "rf",
    STRING_FILTER: "sf"
};
export const LISTING_SPATH = "buy-used-cars-australia";

export const SOCIAL_MEDIA_DATA = [
    {
        link: "https://www.instagram.com/cars24au/",
        title: "Instagram"
    },
    {
        link: "https://www.facebook.com/CARS24Australia/",
        title: "Facebook"
    },
    {
        link: "https://www.linkedin.com/company/cars24-australia/",
        title: "LinkedIn"
    },
    {
        link: "https://twitter.com/cars24au",
        title: "Twitter"
    }
];

export const FOOTER_GEOS = [
    {
        link: "https://www.cars24.com/",
        title: "India"
    },
    {
        link: "https://www.cars24.ae/",
        title: "UAE"
    }
];

export const FINANCE_TNC_LINK = "https://drive.google.com/file/d/1F0bWk5o1KoG8kyRZ120nhGLePuISAvQq/view";

export const PROMISE_STATUS = {
    FULFILLED: "fulfilled",
    REJECTED: "rejected",
    PENDING: "pending"
};

export const API_PROMISE_REJECTED = {
    CLP_RESPONSE_REJECTED: "SSR ERROR in getCarListing AU PROMISE REJECTED",
    CLP_DATA_RESPONSE_REJECTED: "SSR ERROR in getClpData AU PROMISE REJECTED"
};

export const CALCULATOR_CONSTANTS = {
    PRINCIPAL: "principal",
    DEPOSIT: "deposit",
    RATE: "rate"
};

export const FINANCE_METADATA_SCENARIO = {
    APPROVED_AND_NON_BI: "Approved_And_Non_Bi",
    NON_APPROVED_AND_NON_BI: "Non_Approved_And_Non_Bi"
};
export const RECENTLY_VIEWED = "recentlyViewed";
export const PICKS_FOR_YOU = "picksForYou";
export const TASK_SOURCES = {
    IN_HOUSE_FINANCE: "IN_HOUSE_FINANCE",
    DOC_SIGN: "DOC_SIGN",
    DMS: "DMS"
};

export const TASK_SOURCES_ALERT = {
    FINISH_LOAN_APPLICATION: "FINISH_LOAN_APPLICATION",
    UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
    DL_VERIFY: "DL_VERIFY",
    SALE: "SALE",
    SPLIT_PAYMENT: "SPLIT_PAYMENT"
};

export const ENABLE_NEW_POST_BOOK_FLOW = {
    UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
    DL_VERIFY: "DL_VERIFY",
    SPLIT_PAYMENT: "SPLIT_PAYMENT",
    SALE: "SALE"
};

export const STEP_ROUTE_MAP = {
    SELECT_CAR_INFO: "car-details",
    VALUATION: "valuation",
    CAR_CONDITION: "exterior-condition",
    PRECONDITIONS: "pre-conditions",
    EXTERIOR_CONDITION: "exterior-condition",
    MECHANICAL_CONDITION: "mechanical-condition",
    INTERIOR_CONDITION: "interior-condition",
    CAR_CONDITION_AND_DETAILS: "exterior-condition",
    ESTIMATED_VALUATION: "estimated-valuation",
    ADDITIONAL_DETAILS: "extra-details"
};

export const INSPECTION_ROUTE_MAP = {
    SELECT_DAY: "select-day",
    PERSONAL_DETAILS: "personal-details"
};

export const SEARCH_ALL_CARS_ROUTE = `/buy-used-cars-${COUNTRY.slug}`;

export const HUB_LOCATION_MAP_EMBED_URLS = {
    VIC: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3150.950658265672!2d144.79111467641053!3d-37.83804097196955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6617696168445%3A0xf55bfeb22f39c563!2sCARS24%20Melbourne%20-%20Buy%2C%20Sell%20%26%20Finance%20Used%20Cars!5e0!3m2!1sen!2sin!4v1702877111815!5m2!1sen!2sin",
    QLD: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3540.5252767548295!2d153.07767607492386!3d-27.452901776329313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91599b8d1769e3%3A0xdcad7d4abd99afbc!2sCARS24%20Brisbane%20-%20Buy%2C%20Sell%20%26%20Finance%20Used%20Cars!5e0!3m2!1sen!2sin!4v1717148515062!5m2!1sen!2sin",
    NSW: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.3291852418492!2d151.0079709752067!3d-33.829620373241674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a32fbe522c93%3A0xa9bcd3c7b0379078!2sCARS24%20Sydney%20-%20Buy%2C%20Sell%20%26%20Finance%20Used%20Cars!5e0!3m2!1sen!2sin!4v1717148574988!5m2!1sen!2sin"
};

export const FINANCE_CALCULATOR = {
    DAP: "dap",
    DEPOSIT: "deposit",
    INTEREST: "interest"
};
export const BUY_WITH_CONFIDENCE_KEYS = {
    RETURN_WITHIN_X_DAYS_KEY: "x_days_return",
    WARRANTY_KEY: "x_months_warranty"
};

export const MINOR_IMPERFECTION = "totalImperfectionsCount";

export const financeMyAccountStepId = ["loan-details",  "financing"];
export const EQUIFAX_OUTAGE = 1017;
export const NO_CREDIT_SCORE_FOUND = 1006;
export const EXTENDED_WARRANTY_DURATION = {
    MIN: 16,
    MAX: 33
};

export const SWIPE_EVENT_FIRED = "SWIPE_EVENT_FIRED";

export const INVENTORY_BANNER_INDEXES = [NUMBER.FOUR, NUMBER.SEVEN, NUMBER.TEN, NUMBER.THIRTEEN, NUMBER.SIXTEEN, NUMBER.TWENTY, NUMBER.TWENTY_FIVE, NUMBER.THIRTY, NUMBER.THIRTY_FIVE, NUMBER.FOURTY];
export const CURRENT_SALE = "SALE_EOFY";

export const TEST_DRIVE_FINANCE_OPTIONS = {
    TD__FINANCE: "TD__FINANCE",
    TD__CASH: "TD__CASH",
    TD__BYO: "TD__BYO"
};

export const LOAN_QUOTES_SOURCE = {
    DEFAULT_CHECKOUT: "default",
    TEST_DRIVE_FLOW: "test_drive"
};

export const CUSTOM_SELECT_FILTER_PILL_NAMES = {
    TEST_DRIVE: "Test Drive",
    SALE: "EOFY Sale"
};

export const FINANCE_CENTRIC_CONSTANTS = {
    QUERY_PARAM: "fc",
    DEFAULT_CLP: "DEFAULT_CLP",
    FINANCE_CENTRIC_CLP: "FINANCE_CENTRIC_CLP",
    REDIRECT_TO_FINANCE_CENTRIC_CLP: "REDIRECT_TO_FINANCE_CENTRIC_CLP",
    REDIRECT_TO_DEFAULT_CLP: "REDIRECT_TO_DEFAULT_CLP",
    FINANCE_CENTRIC_MODAL: "FINANCE_CENTRIC_MODAL"
};
export const MAXIMUM_REPAYMENT = NUMBER.NINE_NINETY_NINE;

export const INVENTORY_CAR_CARD_LABELS = {
    RESERVED: "reserved",
    VIEWED: "viewed",
    SOLD: "sold",
    DISCOUNT: "discount",
    NEW: "new",
    PRICE_DROP: "priceDrop"
};

export const FALSY_VALUES = {
    INVALID: "Invalid",
    NULL: null,
    FALSE: false,
    ZERO: 0,
    EMPTY_STRING: ""
};

export const ACQUISITION_TYPE = {
    SELF_BOOKING_LINK: "SELF_BOOKING_LINK"
};
