/* eslint-disable complexity */
import qs from "query-string";
// import { FEATURE_FLAG_KEYS, NUMBER } from "../../constants/app-constants";
import { ORDER_STATUS } from "../../constants/checkout-constants";
import { EXPERIMENT_TYPE } from "../../constants/optimize-constants";
import isTestDriveEnabledState from "./is-test-drive-enabled-state";
// Keep Variant A as default/original view

export const getAbExpirementVariant = (key = {}, variant = "VARIANT_A") => (key && key.data === EXPERIMENT_TYPE[variant]) || false;

const BI_CTA_MAPPING = {
    [EXPERIMENT_TYPE.VARIANT_A]: {
        text: "Get Started",
        variant: (EXPERIMENT_TYPE.VARIANT_A).replace("_", " ").toLowerCase()
    },
    [EXPERIMENT_TYPE.VARIANT_B]: {
        text: "Buy Now",
        variant: (EXPERIMENT_TYPE.VARIANT_B).replace("_", " ").toLowerCase()
    },
    [EXPERIMENT_TYPE.VARIANT_C]: {
        text: "Reserve Now",
        variant: (EXPERIMENT_TYPE.VARIANT_C).replace("_", " ").toLowerCase()
    }
};

export const isExperimentNull = (value) => (!EXPERIMENT_TYPE.hasOwnProperty(value)) || false;

export const showTestDriveVariant = ({order = {}, content = {}, orderStatus = ""}) => {
    const {status = ""} = order || {};
    const {reserved, booked, displayTestDriveFlow} = content || {};
    // const {data: showTestDriveData} = showTestDrive || {};
    // let testDriveViaFeatureFlag = false;
    let  showTdfViaQuery = false;
    // if (showTestDriveData === "NA") {
    //     testDriveViaFeatureFlag = showFeature(FEATURE_FLAG_KEYS.TEST_DRIVE_FLAG, NUMBER.FIFTY);
    // }
    if (typeof window !== "undefined") {
        const queryParams = qs.parse(window.location.search);
        showTdfViaQuery = (queryParams || {}).tdf === "true";//tdf => testdriveflow
    }
    const isEnabled = (showTdfViaQuery) &&
    (!booked) && !reserved &&
    (status || orderStatus) !== ORDER_STATUS.TEST_DRIVE_RESERVED &&
    (status || orderStatus) !== ORDER_STATUS.RESERVED &&
    (displayTestDriveFlow);
    return isEnabled;
};

export const showNewTestDriveVariant = ({order = {}, content = {}, orderStatus = "", showTestDriveLocationVariant, userState}) => {
    const {status = "", testDriveStatus} = order || {};
    const {reserved, comingSoon, booked, state, listingActive} = content || {};
    const isEnabled =
    !booked && !comingSoon && !reserved && listingActive && testDriveStatus !== ORDER_STATUS.TEST_DRIVE_COMPLETED && isTestDriveEnabledState(state, userState) &&
    (status || orderStatus) !== ORDER_STATUS.RESERVED && showTestDriveLocationVariant;
    return isEnabled;
};

export const showPostWarrantyVariant = (showWarrantyPageShift) => {
    const {data: showWarrantyPageShiftData = "VARIANT_B"} = showWarrantyPageShift || {};
    const showPostWarrantyPage = showWarrantyPageShiftData !== EXPERIMENT_TYPE.VARIANT_B;
    return showPostWarrantyPage;
};

export const showCdpBiCtaVariant = (experiment) => {
    const {data} = experiment || {};
    const variantDetails = BI_CTA_MAPPING[data];
    return variantDetails;
};

export const getFinanceDiscoveryVariant = ({showFinanceCentericDiscovery: experiment = {}, queryParams: params = {}, preApproved = false, showFinanceCentricListing = false}) => {
    const { fc = false } = params || {};
    const {data: financeDiscoveryVariant} = experiment;
    const isFinanceDiscoveryVariant = !getAbExpirementVariant(experiment, EXPERIMENT_TYPE.VARIANT_A) && !preApproved;
    const isFinanceDiscoveryLayout = fc || showFinanceCentricListing;
    return {isFinanceDiscoveryVariant, isFinanceDiscoveryLayout, financeDiscoveryVariant};
};
