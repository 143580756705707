
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PaymentMode from "./component";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { useLocation } from "react-router";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import ErrorIcon from "./images/error-icon.png";
import { getPaymentModeConfig } from "./payment-mode-config";

// eslint-disable-next-line complexity
const UIPaymentMode = ({
    amountBeingPaid,
    showHeader = true,
    finalPrice,
    isSplitPaymentOpted,
    isFinanceOpted = false,
    handlePaymentMode = () => {},
    agreedToTerms,
    isByoOpted = false,
    isInHouse,
    config,
    canMakePayment,
    disablePaymentMode = {},
    financeData,
    nudgeCxForLowerAmount = false,
    showPaymentError = false,
    setShowPaymentError = () => {},
    origin = ""
}) => {
    const [paymentMethodType, setPaymentMethodType] = useState();
    // const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [cardType, setCardType] = useState();
    const [paymentGateway, setPaymentGateway] = useState();
    // const [paymentStatus, setPaymentStatus] = useState();
    const paymentMethodTypeRef = useRef();
    const location = useLocation();
    const queryParams = parseLocationSearchParams(location.search);

    const { selectedQuote } = financeData || {};
    const { totalLoanAmount } = selectedQuote || {};
    const paymentModeConfig = getPaymentModeConfig({
        amountBeingPaid,
        isInHouse,
        config
    });

    useEffect(() => {
        if (queryParams && queryParams.status) {
            //TODO: payment status
            // setPaymentStatus(queryParams.status);
            setCardType(queryParams.cardType);
            setPaymentMethodType(queryParams.type);
            setPaymentGateway(queryParams.gateway);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handlePaymentMode({
            acceptedTerms: agreedToTerms,
            cardType,
            paymentMethodType,
            paymentGateway,
            isValid: paymentMethodType && agreedToTerms
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreedToTerms, paymentGateway, paymentMethodType, cardType, agreedToTerms]);

    // useEffect(() => {
    //     if (paymentMethodTypeRef.current) {
    //         paymentMethodTypeRef.current.scrollIntoView();
    //     }
    // }, [paymentMethodType]);

    const onSelectCard = (data) => {
        //TODO: track ga events
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_ORDER_SUMMARY, {eventLabel: data.eventLabel});
        if (data.cardType) { setCardType(data.mode === paymentMethodType  && data.cardType === cardType ? null : data.cardType);}
        setPaymentMethodType(data.mode === paymentMethodType && data.cardType === cardType ? null : data.mode);
        setPaymentGateway(data.mode === paymentMethodType  && data.cardType === cardType  ? null : data.gateWay);
        setShowPaymentError(false);
    };

    const getFooterText = () => {
        if (isByoOpted) {
            return <React.Fragment>Balance <span>&nbsp;{makePriceLabelRound(finalPrice - amountBeingPaid)}&nbsp;</span> will be transferred by your lender</React.Fragment>;
        } else if (isFinanceOpted) {
            return <React.Fragment>Balance <span>&nbsp;{makePriceLabelRound(totalLoanAmount)}&nbsp;</span> will be transferred by lender after full approval</React.Fragment>;
        } else if (origin === "variantB") {
            return <React.Fragment><span>Balance amount of {makePriceLabelRound(finalPrice - amountBeingPaid)}</span>&nbsp;to be paid before 24 hrs of delivery</React.Fragment>;
        } else {
            return <React.Fragment>Balance <span>&nbsp;{makePriceLabelRound(finalPrice - amountBeingPaid)}</span>&nbsp;to be paid 24 hrs before delivery</React.Fragment>;
        }
    };

    const getModeView = (item) => {
        const {applePay = false, googlePay = false} = canMakePayment || {};
        if (disablePaymentMode[item.key]) {
            return false;
        } else if (item.key === "googlePay") {
            return googlePay;
        } else  if (item.key === "applePay") {
            return applePay;
        }
        return true;
    };
    return (
        <React.Fragment>
            {nudgeCxForLowerAmount && (<><p styleName={"styles.lowerAmount"}>You can still book the car by paying just $500</p></>) }
            {showHeader && !nudgeCxForLowerAmount && (<><p styleName={"styles.paymentMode"}>{makePriceLabelRound(amountBeingPaid)} {(isSplitPaymentOpted || isFinanceOpted) ? "Deposit (Payable now)" : "(Full pay)"}</p><p styleName={"styles.refundable"}><span>100% refundable</span> if you change your mind</p></>)}
            <div styleName={"styles.paymentWrapper"}>
                { showPaymentError &&  <div styleName={"styles.errormsgWrap"}>
                    <img src={ErrorIcon} />
                    <p styleName={"styles.errorMsg"}>Please select a payment mode</p>
                </div>}
                <p styleName={"styles.paymentHeader"}>Select payment mode</p>
                {paymentModeConfig.map((item, index) =>
                    (getModeView(item) &&
                        <PaymentMode
                            key={index}
                            label = {item.label}
                            description= {item.description}
                            mode={item.data}
                            recommended={item.recommended || false}
                            isSelected={item.data.mode === paymentMethodType && item.data.cardType &&  item.data.cardType === cardType }
                            paymentMethodTypeRef={paymentMethodTypeRef}
                            onClick={onSelectCard}
                            imgSrc={item.imgSrc}
                            displayRightLabel={item.displayRightLabel}
                            finalPrice={finalPrice}
                            cardColor={item.cardColor}
                        />
                    )
                )}
            </div>
            {(isSplitPaymentOpted || isFinanceOpted) && (finalPrice - amountBeingPaid >0) && <p styleName={origin === "variantB" ? "styles.balanceAmountVariantB" : "styles.balanceAmount"}>{getFooterText()}</p>}
        </React.Fragment>
    );
};

const mapStateToProps = ({
    checkout: {
        financeData
    },
    config
}) => ({
    financeData,
    config
});

UIPaymentMode.propTypes = {
    isSelected: PropTypes.bool,
    paymentMethodTypeRef: PropTypes.any,
    mode: PropTypes.string,
    onClick: PropTypes.func,
    imgSrc: PropTypes.any,
    label: PropTypes.string,
    description: PropTypes.string,
    orderId: PropTypes.string,
    handlePaymentMode: PropTypes.func,
    agreedToTerms: PropTypes.bool,
    canMakePayment: PropTypes.object,
    config: PropTypes.object,
    isInHouse: PropTypes.bool,
    disablePaymentMode: PropTypes.object,
    isReservationVariant: PropTypes.bool,
    finalPrice: PropTypes.number,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    selectedSplitAmount: PropTypes.number,
    isSplitPaymentOpted: PropTypes.bool,
    isFinanceOpted: PropTypes.bool,
    amountBeingPaid: PropTypes.number,
    isByoOpted: PropTypes.bool,
    financeData: PropTypes.object,
    nudgeCxForLowerAmount: PropTypes.bool,
    showPaymentError: PropTypes.bool,
    setShowPaymentError: PropTypes.func,
    origin: PropTypes.string
};

export default connect(mapStateToProps)(UIPaymentMode);
