/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unknown-property */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect  } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import CIRCLEICON from "./image/circle.svg";
import FAQICON from "./image/faqicon.svg";
import Button from "../../shared/button";
import GREENCHECK from "./image/green-check.svg";
import CheckoutLayout from "../checkout-layout";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { useHistory } from "react-router";
import { EXTENDEDWARRANTY, EXTENDED_WARRANTY_OPTED_PAGE, NUMBER, WARRANTY_FINANCE } from "../../../constants/app-constants";
import { checkoutRoutes } from "../../../constants/au.desktop/checkout-routes";
import { trackAmplitude, trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { CHECKOUT_STEPS, CHECKOUT_STEPS_STATUS } from "../../../constants/checkout-constants";
import SeeWhatCoverd from "../see-what-coverd";
import HowWeCompare from "../how-we-compare";
import ExtendedYourProtection from "../extended-your-protection";
import PlatinumCover from "../platinum-cover";
import InfoIcon from "./image/info-icon.svg";
import Tooltip from "../../shared/tooltip";
import FinanceDisclaimerTooltipBody from "../../shared/finance-disclaimer-tooltip-body/component";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import { SCREEN_TYPE } from "../add-ons/reducers";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import ManufacturerWarrantyCard from "../manufacturer-warranty-card/component";
import Cars24PlatinumCover from "../cars24-platinum-cover";
const Cars24Cover = ({
    routeData,
    content,
    order,
    tradeInData,
    tradeinOpted,
    warrantyPlanInfo = {},
    getWarrantyPlansConnect,
    updateWarrantyPlansConnect,
    updateCheckoutStepsConnect,
    vasCartData,
    getVASCartConnect = () => {},
    setAddonScreenTypeConnect = () => {}
}) => {
    const [largestPlanIdx, setLargestPlanIdx] = React.useState(null);
    const [largestEmiIdx, setLargestEmiIdx] = React.useState(null);
    const vasCartItems = (vasCartData || {}).items || [];

    const history = useHistory();
    const {personalised, oldTimer} =  content;
    const { appointmentId, orderId, chargeDetails: chargeData, totalAmount} = order || {};
    const { extendedWarranty = {}, coverTitle = "", warrantyPoints = [], coverDate = "", manufacturerWarranty = {} } = warrantyPlanInfo;
    const {expired = false} = manufacturerWarranty || {};
    const { startDateLabel = "",   comparisonRate = 0, interestRate} = extendedWarranty || {};
    const plans = extendedWarranty && extendedWarranty.plans || [];
    const emiPlans = extendedWarranty && extendedWarranty.emiPlans || [];
    const showRevampDesign = ((plans || []).length > NUMBER.ZERO && (plans || []).length <= NUMBER.TWO) && !expired && !oldTimer;
    const { offeredPrice = 0 } = tradeInData || {};

    const stepData = {
        appointmentId: content.appointmentId,
        step: CHECKOUT_STEPS.CARCOVER,
        state: CHECKOUT_STEPS_STATUS.COMPLETE
    };
    // const [showFullDetailsModal, setShowFullDetailsModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [toggleState, setToggleState] = useState(NUMBER.ONE);

    useEffect(() => {
        if (orderId) {
            getVASCartConnect(orderId);
        }
    }, [getVASCartConnect, orderId]);

    const toggleTab = (index) => {
        if (index === NUMBER.ONE) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CLICK_ADD_TO_LOAN);
        }
        setToggleState(index);
    };

    const getBestPlanIndex = (plansArr) => {
        let idx = null;
        let max = 0;

        for (let i = 0; i < plansArr.length; i++) {
            if (plansArr[i].months > max) {
                max = plansArr[i].months;
                idx = i;
            }
        }
        return idx;
    };

    useEffect(() => {
        if (appointmentId) {
            getWarrantyPlansConnect().then((data) => {
                const { extendedWarranty: warranty = {} } = data || {};
                if ((warranty.plans || []).length > 0) {
                    setLargestPlanIdx(getBestPlanIndex(warranty.plans));
                }
                if ((warranty.emiPlans || []).length > 0) {
                    setLargestEmiIdx(getBestPlanIndex(warranty.emiPlans));
                }
                const preSelectedPlan = chargeData.find(item => item.key === EXTENDEDWARRANTY);
                const currentPlanIndex = (warranty && warranty.plans || []).findIndex(item => item.key === (preSelectedPlan && preSelectedPlan.name));
                if (oldTimer) {
                    setActiveTab(currentPlanIndex >= 0 ? currentPlanIndex : 0);
                } else {
                    setActiveTab(currentPlanIndex);
                }
            });
            if (personalised) {
                trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.VIEW_FINANCE);
            }
            //setToggleState(personalised ? NUMBER.ONE : NUMBER.TWO);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId]);

    useEffect(() => {
        if (oldTimer) {
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.CDP_NO_SERVICE_OLD_TIMERS_EW_CHECKOUT, {
                "No_service_old_timer_EW_checkout": "Viewed"
            });
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.EW_CHECKOUT_NO_SERVICE_OLD_TIMER);
        }
    }, [oldTimer]);

    const trackEvent = (eventLabel) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CARS24_COVER, { eventLabel });
    };

    const navigate = () => {
        const { amount = 0 } = plans[activeTab] || {};
        if (tradeinOpted && (offeredPrice >= (totalAmount + amount))) {
            const { relativeURL: nextURL } = getCheckoutPageURL(content, checkoutRoutes.summary.route);
            history.push(nextURL);
        } else {
            const { relativeURL: nextURL } = getCheckoutPageURL(content, routeData.defaultNext);
            history.push(nextURL);
        }
    };

    const getPayload = (extendedWarrantyOpted) => {
        const { key, amount, label } = plans[activeTab] || {};
        trackEvent(extendedWarrantyOpted ? AU_DESKTOP_EVENTS.ADD_AND_PROCEED : AU_DESKTOP_EVENTS.SKIP);
        const chargeDetails = { "name": key, amount, label };
        return ({ extendedWarrantyOpted, chargeDetails, extendedWarrantyOptedPage: EXTENDED_WARRANTY_OPTED_PAGE.CAR_COVER });
    };

    const handleTab = (index, type) => {
        const { label } = plans[index] || {};
        const eventLabel = type === WARRANTY_FINANCE ?  `${label}_${AU_DESKTOP_EVENTS.FINANCE}` : label;
        if (label) {
            trackEvent(`${AU_DESKTOP_EVENTS.PLAN_SELECTED}_${eventLabel}`);
        }
        setActiveTab(index);
    };

    const handleClick = () => {
        if (toggleState === NUMBER.ONE) {
            const { months } = (plans[activeTab] || {});
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CARS24_COVER_ADD_TO_LOAN);
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.EXTENDED_WARRANTY_SELECTED, {
                "Extended warranty_select_plan": `${months} Months`
            });
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.EXTENDED_WARRANTY_MODE, {
                "Extended warranty_select_mode": "Add to loan"
            });

        } else if (toggleState === NUMBER.TWO) {
            const { months } = (emiPlans[activeTab] || {});
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CARS24_COVER_PAY_OUTRIGHT);
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.EXTENDED_WARRANTY_SELECTED, {
                "Extended warranty_select_plan": `${months} Months`
            });
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.EXTENDED_WARRANTY_MODE, {
                "Extended warranty_select_mode": "Pay outright"
            });
        }
        return updateWarrantyPlansConnect(orderId, getPayload(true)).then(() => {
            // history.push(checkoutRoutes.summary.route);
            if (vasCartItems.length > 0) setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.CART_VIEW });
            else setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.LANDING_PAGE });
            updateCheckoutStepsConnect(stepData);
            navigate();
        }).catch(() => {
            //  navigate()
        }
        );
    };

    const handleSkip = () => {
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.EXTENDED_WARRANTY_SKIPPED, {
            "Extended warranty_skipped": "Yes"
        });
        return updateWarrantyPlansConnect(orderId, getPayload(false)).then(() => {
            if (vasCartItems.length > 0) setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.CART_VIEW });
            else setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.LANDING_PAGE });
            updateCheckoutStepsConnect({ ...stepData, state: CHECKOUT_STEPS_STATUS.SKIPPED });
            navigate();
        }
        ).catch(() => navigate());
    };

    const getActiveClass = (index, styleName) => toggleState === index ? styleName : "";
    const freePlan = (plans || []).filter((item) => item.amount === 0);

    const displayFreeWarranty = () => {
        return (
            <div>
                {oldTimer && freePlan.length ? <div>
                    <p styleName={"styles.freeBox"}>CARS24 Platinum Cover</p>
                    <div styleName={"styles.planWrapperExtend"}>
                        <div styleName="styles.displayFlex">
                            <img src={GREENCHECK} alt={GREENCHECK} />
                            <p styleName={"styles.activeRadio"}>12 months plan
                                <div styleName={"styles.free_value"}>
                                    <span>FREE</span>
                                </div>
                            </p>
                        </div>
                        <div styleName={`styles.planWrapperFooter`}>
                            <div>Extended warranty with this car</div>
                            <div styleName={"styles.originalPrice"}>{makePriceLabelRound(freePlan[0].originalAmount)}</div>
                        </div>
                    </div>
                </div> : null}
            </div>
        );
    };

    return (
        <CheckoutLayout
            routeData={routeData}
            showFooter={false}
        >
            <div styleName={"styles.car_cover_main"}>
                <div className="row">
                    <div className="col-lg-7">
                        <React.Fragment>
                            {!emiPlans ?
                                <div>
                                    {displayFreeWarranty()}
                                    {showRevampDesign && <ManufacturerWarrantyCard warrantyPlanInfo={warrantyPlanInfo}/>}
                                    {showRevampDesign ? <Cars24PlatinumCover  /> : <ExtendedYourProtection />}
                                    <SeeWhatCoverd/>
                                    <HowWeCompare/>
                                    <div styleName={"styles.page"}>
                                        <div styleName={"styles.cars_cover"}>
                                            <h3>Choose coverage term</h3>
                                        </div>
                                        <div styleName={"styles.details"}>
                                            <h5 styleName={"styles.new-text"}>{`${coverTitle || ""} ${coverDate || ""}.`}</h5>
                                            {plans.map((plan, index) =>
                                                (<div key={index} styleName={activeTab === index ? "styles.planWrapperExtend" : largestPlanIdx === index ? "styles.planWrapperUnselected" : "styles.planWrapper"}
                                                    onClick={() => handleTab(index)}>
                                                    {largestPlanIdx === index && !oldTimer &&
                                                        <div styleName={"styles.best_value_save"}>
                                                            <h5>Best Value </h5>
                                                        </div>
                                                    }
                                                    <div styleName="styles.displayFlex">
                                                        <img src={activeTab === index ? GREENCHECK : CIRCLEICON} alt={CIRCLEICON} />
                                                        <p styleName={"styles.activeRadio"}>
                                                            <div>
                                                                {(plan.label || "")}
                                                                {index === 0 && oldTimer && <span styleName={"styles.free_value"}>
                                                                    <span styleName={"styles.ml-5"}>FREE</span>
                                                                </span>}
                                                            </div>
                                                            <span>{makePriceLabelRound(plan.amount)}</span>
                                                        </p>
                                                    </div>

                                                    <div styleName={`styles.planWrapperFooter`}>
                                                        <div>{plan.endDateLabel}</div>
                                                        {oldTimer && <div styleName={"styles.originalPrice"}>{makePriceLabelRound(plan.originalAmount)}</div>}
                                                    </div>

                                                </div>
                                                ))}
                                            <div styleName={"styles.bottom_text"}>
                                                <img src={FAQICON} alt={FAQICON} />
                                                <p>{startDateLabel || "Your Cars24 Extended Cover will start 3 months after your car delivery date and is payable today."}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div styleName={"styles.buttonWrapper"}>
                                        {!oldTimer && <div styleName={"styles.btn1"}>
                                            <Button text="SKIP" onClick={handleSkip} />
                                        </div>}
                                        <div styleName={`${oldTimer ? "styles.fullWidth" : "styles.btn2"}`}>
                                            <Button text="ADD & PROCEED" disabled={activeTab < 0} onClick={handleClick} />
                                        </div>
                                    </div>
                                </div>

                                :
                                <div>
                                    {displayFreeWarranty()}
                                    {showRevampDesign && <ManufacturerWarrantyCard warrantyPlanInfo={warrantyPlanInfo}/>}
                                    {showRevampDesign ? <Cars24PlatinumCover /> : <ExtendedYourProtection largestPlan={plans[largestPlanIdx]} />}
                                    <SeeWhatCoverd />
                                    <HowWeCompare  />
                                    <div>
                                        <h3 styleName={"styles.chooseCoverage"}>
                                            {showRevampDesign && <span styleName="styles.blueBox">&nbsp;</span>}
                                            Choose coverage term
                                        </h3>
                                        <div>
                                            <h5 styleName={"styles.new-text"}>{`${coverTitle || ""} ${coverDate || ""}.`}</h5>

                                            <div styleName={"styles.tabContainer"}>
                                                <ul styleName="tabList">
                                                    <li
                                                        styleName={`tabs ${getActiveClass(1, "activeTab")}`}
                                                        onClick={() => toggleTab(NUMBER.ONE)}
                                                    >
                                                        Add to loan
                                                        {!oldTimer && <span>From {makePriceLabelRound((emiPlans || []).length > 0 && emiPlans[NUMBER.ZERO].emiDetails.emiPerWeek)}/week</span>}
                                                    </li>
                                                    <li
                                                        styleName={`tabs ${getActiveClass(NUMBER.TWO, "activeTab")}`}
                                                        onClick={() => toggleTab(NUMBER.TWO)}
                                                    >
                                                        Pay outright
                                                    </li>
                                                </ul>
                                                <div styleName={`content ${getActiveClass(1, "activeContent")}`}>
                                                    <p styleName={"styles.addLoanText"}>
                                                        {personalised ?
                                                            "Directly added to your weekly loan repayments, subject to final finance approval." :
                                                            "Price of plan will be added to your weekly loan repayments, if you opt for finance."
                                                        }
                                                    </p>
                                                    <p styleName={"styles.startingFrom"}>Starting from</p>
                                                    {emiPlans.map((plan, index) =>
                                                        (<div key={index} styleName={activeTab === index ? "styles.planWrapperExtend" : largestEmiIdx === index ? "styles.planWrapperUnselected" : "styles.planWrapper"}
                                                            onClick={() => handleTab(index, WARRANTY_FINANCE)}>
                                                            {largestEmiIdx === index && !oldTimer &&
                                                                <div styleName={"styles.best_value_save"}>
                                                                    <h5>Best Value </h5>
                                                                </div>
                                                            }

                                                            <div styleName="styles.displayFlex">
                                                                <img src={activeTab === index ? GREENCHECK : CIRCLEICON} alt={CIRCLEICON} />
                                                                <p styleName={"styles.activeRadio"}>
                                                                    <div>
                                                                        {(plan.label || "")}
                                                                        {index === 0 && oldTimer && <span styleName={"styles.free_value"}>
                                                                            <span styleName={"styles.ml-5"}>FREE</span>
                                                                        </span>}
                                                                    </div>
                                                                    <span>{makePriceLabelRound(plan.emiDetails.emiPerWeek)}<small>/week</small></span>
                                                                </p>
                                                            </div>

                                                            <div styleName={`styles.planWrapperFooter`}>
                                                                <div>{plan.endDateLabel}</div>
                                                                {oldTimer && <div styleName={"styles.originalPrice"}>{makePriceLabelRound(plan.originalEmiDetails.emiPerWeek)}/week</div>}
                                                            </div>

                                                        </div>
                                                        ))}
                                                    <p styleName={"styles.information"}>
                                                        {personalised ?
                                                            "Weekly payments are based on the below rates received during your pre-approval and are subject to change." :
                                                            "Weekly repayments calculated based on"
                                                        }
                                                        <br/>
                                                        {(interestRate || 0).toFixed(NUMBER.TWO)}% interest rate (p.a) {!personalised && " and"}
                                                        <br/>
                                                        {comparisonRate.toFixed(NUMBER.TWO)}% comparison rate
                                                        <Tooltip heading={``} displayComponent={FinanceDisclaimerTooltipBody}  showComponent={true} displayComponentProp={{ showInHouse: true}}>
                                                            <span><img src={InfoIcon} /></span>
                                                        </Tooltip>
                                                        {!personalised && <br/>}
                                                        {!personalised && " Rates are subject to change, per your profile."}
                                                    </p>
                                                </div>
                                                <div styleName={`content ${getActiveClass(NUMBER.TWO, "activeContent")}`}>
                                                    <p styleName={"styles.addLoanText"}>Lump sum added to your vehicle’s price and payable prior to delivery</p>
                                                    {plans.map((plan, index) =>
                                                        (<div key={index} styleName={activeTab === index ? "styles.planWrapperExtend" : largestPlanIdx === index ? "styles.planWrapperUnselected" : "styles.planWrapper"}
                                                            onClick={() => handleTab(index)}>
                                                            {largestPlanIdx === index && !oldTimer &&
                                                                <div styleName={"styles.best_value_save"}>
                                                                    <h5>Best Value </h5>
                                                                </div>
                                                            }

                                                            <div styleName="styles.displayFlex">
                                                                <img src={activeTab === index ? GREENCHECK : CIRCLEICON} alt={CIRCLEICON} />
                                                                <p styleName={"styles.activeRadio"}>
                                                                    <div>
                                                                        {(plan.label || "")}
                                                                        {index === 0 && oldTimer && <span styleName={"styles.free_value"}>
                                                                            <span styleName={"styles.ml-5"}>FREE</span>
                                                                        </span>}
                                                                    </div>
                                                                    <span>{makePriceLabelRound(plan.amount)}</span>
                                                                </p>
                                                            </div>

                                                            <div styleName={`styles.planWrapperFooter`}>
                                                                <div>{plan.endDateLabel}</div>
                                                                {oldTimer && <div styleName={"styles.originalPrice"}>{makePriceLabelRound(plan.originalAmount)}</div>}
                                                            </div>

                                                        </div>
                                                        ))}
                                                </div>
                                            </div>
                                            <div styleName={"styles.buttonWrapper"}>
                                                {!oldTimer && <div styleName={"styles.btn1"}>
                                                    <Button text="SKIP" onClick={handleSkip} />
                                                </div>}
                                                <div styleName={`${oldTimer ? "styles.fullWidth" : "styles.btn2"}`}>
                                                    <Button text="ADD & PROCEED" disabled={activeTab < 0} onClick={handleClick}  />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    </div>
                    <div className="col-lg-5">
                        <PlatinumCover content={warrantyPoints} />
                        <p styleName={"styles.carspara"}>CARS24 Cover™ is a suite of products designed to offer you various protection for your vehicle. Some products may be offered as an insurance policy and some may not be. Please always read the relevant terms and conditions before purchasing.</p>
                    </div>
                </div>
            </div>
        </CheckoutLayout>
    );
};

Cars24Cover.propTypes = {
    routeData: PropTypes.object,
    order: PropTypes.object,
    warrantyPlanInfo: PropTypes.object,
    getWarrantyPlansConnect: PropTypes.func,
    updateWarrantyPlansConnect: PropTypes.func,
    config: PropTypes.object,
    content: PropTypes.object,
    eligibleForExtendedWarranty: PropTypes.bool,
    tradeInData: PropTypes.object,
    tradeinOpted: PropTypes.bool,
    isAffiliate: PropTypes.bool,
    updateCheckoutStepsConnect: PropTypes.func,
    setAddonScreenTypeConnect: PropTypes.func,
    extendedWarrantyLoading: PropTypes.bool,
    vasCartData: PropTypes.object,
    getVASCartConnect: PropTypes.func
};

export default Cars24Cover;

