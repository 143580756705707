/* eslint-disable max-statements */
/* eslint-disable new-cap */
import React from "react";
import styles from "./styles.css";
//import "@adyen/adyen-web/dist/adyen.css";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import CheckoutHeader from "../checkout-header";
import { getLogoURL } from "../../../constants/url-constants";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import BookingsFooter from "../bookings-footer";
import AdyenPaymentComp from "./adyen-component";
import { trackAmplitude } from "../../../tracking";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import { LOAN_PROVIDER } from "../../../constants/app-constants";

const AdyenPay = ({
    paymentConfig,
    content,
    currentDeliveryStep = {},
    routeData,
    isDrivaScreen = false,
    showPill = false,
    showPercentageBar = false,
    showNavigation = false,
    sessionData,
    order,
    resetApplyPromoCodeConnect
}) => {
    const history = useHistory();

    const {amount } = paymentConfig || {};

    const {financeProvider} = order || {};

    const handleBackClick = () => {
        const { relativeURL: backURL } = getCheckoutPageURL(content, checkoutRoutes.paymentSelection.route);
        history.push(backURL);
    };

    const defaultCallClick = () => {
        // trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_HEADER_CALL, {
        //     eventLabel: `options ${routeData.gtmPageName}`
        // });
    };

    const trackFooter = (eventName) => {

        let paymentMethodType = "";
        if (LOAN_PROVIDER.NONE === financeProvider) {
            paymentMethodType = "Card";
        } else if (LOAN_PROVIDER.BYOF === financeProvider) {
            paymentMethodType = "BYOF";
        } else if (LOAN_PROVIDER.CARS24 === financeProvider) {
            paymentMethodType = "Finance";
        }

        const amplitudeEventMapping = {
            "call_us": {
                event: AMPLITUDE_EVENTS_CATEGORY.CARD_CALL,
                properties: { [`${paymentMethodType} call clicked`]: "Yes" }
            },
            "email_us": {
                event: AMPLITUDE_EVENTS_CATEGORY.CARD_EMAIL,
                properties: {[`${paymentMethodType} email clicked`]: "Yes"}
            },
            "text_us": {
                event: AMPLITUDE_EVENTS_CATEGORY.CARD_TEXT,
                properties: { [`${paymentMethodType} text clicked`]: "Yes"}
            }
        };

        trackAmplitude(amplitudeEventMapping[eventName]);

    };

    React.useEffect(() => {
        return () =>  resetApplyPromoCodeConnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-7">
                    <CheckoutHeader
                        currentDeliveryStep={currentDeliveryStep}
                        currentStep={routeData}
                        onBackClick={handleBackClick}
                        showExtraInfo={showNavigation}
                        onCallClick={defaultCallClick}
                        showPill={showPill}
                        isDrivaScreen={isDrivaScreen}
                        showPercentageBar={showPercentageBar}
                    />
                    <div styleName={"styles.wrapper"}>
                        <img styleName={"styles.logoImage"} src={getLogoURL()} alt ="cars24 logo" height={40} width={100} />
                        <div>
                            <p styleName="styles.bookingAmount">AUD {makePriceLabelRound(amount)}</p>
                        </div>
                        <AdyenPaymentComp sessionData={sessionData} paymentConfig={paymentConfig}/>
                    </div>
                </div>
                <div className="col-5">
                    <div styleName={"styles.payPartialWrappers"}>
                        <BookingsFooter
                            title="Need help in transferring funds?"
                            description="We are always here to help you out"
                            hideCancellationStrip={true}
                            trackButtonClick={trackFooter}
                        />
                        {/* <p styleName={"styles.payPartialTxt"}>If you need additional help while transferring the funds, we are always here for help</p>
                        <p styleName={"styles.contactUs"}>Contact us</p> */}

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

AdyenPay.propTypes = {
    paymentConfig: PropTypes.object,
    content: PropTypes.object,
    imageConfig: PropTypes.object,
    currentDeliveryStep: PropTypes.object,
    routeData: PropTypes.object,
    isDrivaScreen: PropTypes.bool,
    showPill: PropTypes.bool,
    showPercentageBar: PropTypes.bool,
    showNavigation: PropTypes.bool,
    order: PropTypes.object,
    sessionData: PropTypes.string,
    resetApplyPromoCodeConnect: PropTypes.func
};

export default AdyenPay;

