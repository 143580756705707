/* eslint-disable complexity */
import {useEffect, useState} from "react";
import AmplitudeAB from "../../utils/amplitude-ab-utils.js";
import { useHistory } from "react-router";
import EXPERIMENT_KEYS, { EXPOSURE_EVENT_KEYS, EXPOSURE_EVENT_MSITE, EXPOSURE_EVENT_DESKTOP} from "../../utils/amplitude-ab-utils.js/constants.js";
import getCurrentPage from "../../utils/helpers/get-current-page.js";
import { emitEventNames } from "../../constants/event-constants.js";
import { getDeviceType } from "../../utils/helpers/get-device-type.js";
import { API_SOURCE } from "../../constants/app-constants.js";

const useAbExperimentWrapper = ({ amplitudeDeviceId, email, abExperiment, setAllAbExperimentConnect }) => {
    const {location} = useHistory();
    const {pathname} = location;
    const [isAbInitialized, setAbInitialized] = useState(false);
    // const eventArray = getAbEventAndKeys(abExperiment);

    useEffect(() => {
        window.EventHandler.subscribeEvent(emitEventNames.global.IS_AMP_AB_INITIALIZED, () => {
            setAbInitialized(true);
        });
        return () => window.EventHandler.removeListeners(emitEventNames.global.IS_AMP_AB_INITIALIZED);
    }, []);

    // eslint-disable-next-line max-statements
    useEffect(() => {
        let key = "";
        const arrayOfKeys = Object.keys(abExperiment);
        const {isDetailsPage, isListingPage, isSelectOfferPage, isPaymentOptionsPage, isHomePage, isC2BLandingPage, isFinanceFirstIntroPage, isSearchPage } = getCurrentPage();
        if (isHomePage) {
            key = EXPOSURE_EVENT_KEYS.HOME_PAGE;
        } else if (isDetailsPage) {
            key = EXPOSURE_EVENT_KEYS.DETAIL_PAGE;
        } else if (isListingPage) {
            key = EXPOSURE_EVENT_KEYS.LISTING_PAGE;
        } else if (isSelectOfferPage) {
            key = EXPOSURE_EVENT_KEYS.SELECT_OFFER_PAGE;
        } else if (isPaymentOptionsPage) {
            key = EXPOSURE_EVENT_KEYS.PAYMENT_OPTIONS_PAGE;
        } else if (isPaymentOptionsPage) {
            key = EXPOSURE_EVENT_KEYS.PAYMENT_OPTIONS_PAGE;
        } else if (isC2BLandingPage) {
            key = EXPOSURE_EVENT_KEYS.C2B_LANDING_PAGE;
        } else if (isFinanceFirstIntroPage) {
            key = EXPOSURE_EVENT_KEYS.FINANCE_FIRST_INTRO_PAGE;
        } else if (isSearchPage) {
            key = EXPOSURE_EVENT_KEYS.SEARCH_PAGE;
        }
        let eventArray = [];
        const deviceType =  getDeviceType();
        if (deviceType === API_SOURCE.M_SITE) {
            eventArray = EXPOSURE_EVENT_MSITE[key] || [];
        } else {
            eventArray = EXPOSURE_EVENT_DESKTOP[key] || [];
        }

        const initializeAb = async () => {
            AmplitudeAB.updateUserInfo(amplitudeDeviceId, email);
            const variants =  await AmplitudeAB.getAllVariants(abExperiment);
            setAllAbExperimentConnect(variants);

            if (eventArray.length > 0) {
                arrayOfKeys.forEach((item) => {
                    const expKey = EXPERIMENT_KEYS[item];
                    const isValid = eventArray.includes(expKey);
                    const index = eventArray.indexOf(expKey);
                    if (expKey && isValid && index > -1) {
                        AmplitudeAB.getABVariant(expKey);
                    }
                });
            }
        };
        if (isAbInitialized) {
            initializeAb();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amplitudeDeviceId, email, isAbInitialized, pathname]);

    return;

};

export default useAbExperimentWrapper;
