import {Experiment} from "@amplitude/experiment-js-client";
import sanitizeItemsToUppercase from "../helpers/sanitize-items-to-uppercase";
import EXPERIMENT_KEYS from "./constants";

const config = {
    retryFetchOnFailure: false
};

const AmplitudeAB = {
    user: {},
    userProperties: {  "premium": true, country: "AU" },

    isInitialized() {
        return !!this.experiment;
    },

    initialize(customConfig = {}) {
        if (this.isInitialized()) return;
        const experiment = Experiment.initializeWithAmplitudeAnalytics(process.env.AMPLITUDE_DEPLOYMENT_KEY, {...config, ...customConfig});
        this.experiment = experiment;
        window.EventHandler.emitEvent("IS_AMP_AB_INITIALIZED", true);
    },

    async getExperiments() {
        const variants =  await this.experiment.fetch(this.user);
        const allVariants = variants.all();
        return allVariants;
    },

    async updateUserInfo(deviceId, email) {
        this.user = {
            device_id: deviceId,
            ...this.user,
            user_properties: {
                ...this.userProperties,
                ...this.user.user_properties
            },
            ...(email && {user_id: email})
        };
        await this.getExperiments();
    },

    getABVariant(key) {
        const variant = this.experiment.variant(key);
        return sanitizeItemsToUppercase((variant || {}).value) || "";
    },

    async getAllVariants(abExperiment) {
        let variants = {};
        const arrayOfKeys = Object.keys(abExperiment);
        const allVariants = await this.getExperiments();
        if (this.isInitialized()) {
            arrayOfKeys.forEach(item => {
                const key = EXPERIMENT_KEYS[item];
                if (key) {
                    const {value} = allVariants[key] || {};
                    variants = {
                        ...variants, [item]: {
                            data: sanitizeItemsToUppercase(value) || abExperiment[item].data
                        }
                    };
                }
            });
        }
        return variants;
    },

    clearExperiment() {
        this.experiment.clear();
    }
};

export default AmplitudeAB;

