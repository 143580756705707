const EXPERIMENT_KEYS =  {
    showZeroDownpayment: "web-show-zero-downpayment",
    showFinanceFirstExperiment: "finance-first-experiment",
    showLenderDisplayExperiment: "web-show-lender-display-experiment",
    showChatEmailModal: "web-show-chat-email-modal",
    showTestDrive: "web-show-test-drive",
    showLoanOfferScreen: "web-loan-offer-screen",
    showSellCarBanner: "web-show-sell-car-banner-v2",
    showCdpGalleryVariant: "web-show-cdp-gallery-variant-v2",
    showDesktopHomepageRevamp: "web-desktop-homepage-revamp",
    showOrderSummaryCash: "order-summary-cash",
    showOrderSummaryFinance: "order-summary-finance",
    showC2BLandingRevamp: "web-c2b-how-it-works",
    showOrderSummaryByof: "order-summary-byof",
    showWebTrustElements: "web-trust-elements",
    showWebToAppNudges: "web-to-app-nudges",
    showServiceHistoryText: "service-history-text-exp",
    cdpBiCta: "bi-cta",
    showIntermediateValuation: "web-intermediate-valuation",
    showTestDriveLocation: "testdrive_cdp_location",
    showTestDriveV2: "test-drive-location",
    benefitsIncluded: "benefits-included",
    guestLoginMobile: "guest_login_mobile",
    webCustomStateExperiment: "web-custom-state-experiment",
    showDesktopCdpRevamp: "web-desktop-cdp-revamp",
    showMobileSearchRevamp: "web-mobile-search-revamp",
    showSellCarAnimation: "sell-car-animation",
    showCdpAtfRevamp: "web-mobile-cdp-atf-revamp",
    showFinanceFirstCheckout: "finance-first-checkout-revamp",
    showInspectionSectionRevamp: "web-mobile-inspection-revamp",
    c2bSellCarSurvey: "c2b-sell-car-survey",
    showPriceConfidence: "web-price-confidence",
    showC2bMechanicalCondition: "c2b-mechanical-condition",
    c2bValuationTimer: "c2b-valuation-timer",
    showTestDriveFinanceInfusion: "test-drive-finance-infusion",
    showWebToAppBanner: "web-2-app-banner",
    showFinanceCentericDiscovery: "finance-centric-discovery",
    showClpRevamp: "web-mobile-clp-revamp",
    showSimilarCarCLP: "web-similar-car-clp",
    showDesktopListingCardGallery: "web-desktop-listing-card-gallery"
};

export const AMPLITUDE_EXPERIMENT_KEYS_COOKIE =  {
    showZeroDownpayment: "show_zero_downpayment",
    showFinanceFirstExperiment: "finance-first-experiment",
    showLenderDisplayExperiment: "show_lender_display_experiment",
    showChatEmailModal: "show_chat_email_modal",
    showSellCarBanner: "show_sell_car_banner",
    showCdpGalleryVariant: "show_cdp_gallery_variantv2",
    showTestDrive: "show_test_drive",
    showSellCarBannerV2: "web-show-sell-car-banner-v2",
    showCdpGalleryVariantV2: "web-show-cdp-gallery-variant-v2",
    showDesktopHomepageRevamp: "web-desktop-homepage-revamp",
    showC2BLandingRevamp: "web-c2b-how-it-works",
    showWebTrustElements: "web-trust-elements",
    showWebToAppNudges: "web-to-app-nudges",
    showIntermediateValuation: "web-intermediate-valuation",
    cdpBiCta: "bi-cta",
    showServiceHistoryText: "service-history-text-exp",
    showTestDriveLocation: "testdrive_cdp_location",
    showTestDriveV2: "test-drive-location",
    benefitsIncluded: "benefits-included",
    guestLoginMobile: "guest_login_mobile",
    webCustomStateExperiment: "web-custom-state-experiment",
    showDesktopCdpRevamp: "web-desktop-cdp-revamp",
    showMobileSearchRevamp: "web-mobile-search-revamp",
    showCdpAtfRevamp: "web-mobile-cdp-atf-revamp",
    showFinanceFirstCheckout: "finance-first-checkout-revamp",
    showInspectionSectionRevamp: "web-mobile-inspection-revamp",
    c2bSellCarSurvey: "c2b-sell-car-survey",
    showPriceConfidence: "web-price-confidence",
    showClpOptimized: "mobile-clp-optimization",
    showTestDriveFinanceInfusion: "test-drive-finance-infusion",
    showC2bMechanicalCondition: "c2b-mechanical-condition",
    showWebToAppBanner: "web-2-app-banner",
    showFinanceCentericDiscovery: "finance-centric-discovery",
    c2bValuationTimer: "c2b-valuation-timer",
    showClpRevamp: "web-mobile-clp-revamp",
    showSimilarCarCLP: "web-similar-car-clp",
    showDesktopListingCardGallery: "web-desktop-listing-card-gallery"
};

export const EXPOSURE_EVENT_KEYS = {
    HOME_PAGE: "home_page",
    LISTING_PAGE: "listing_page",
    DETAIL_PAGE: "detail_page",
    PAYMENT_OPTIONS_PAGE: "payment_options_page",
    SELECT_OFFER_PAGE: "select_offer_page",
    ORDER_SUMMARY_PAGE_CASH: "order_summary_page_cash",
    ORDER_SUMMARY_PAGE_FINANCE: "order_summary_page_finance",
    C2B_LANDING_PAGE: "c2b_landing_page",
    ORDER_SUMMARY_PAGE_BYOF: "order_summary_page_byof",
    SHOW_SERVICE_HISTORY_TEXT: "service_history_text_exp",
    FINANCE_FIRST_INTRO_PAGE: "finance_first_intro_page",
    CDP_BI_CTA: "bi_cta",
    guestLoginMobile: "guest_login_mobile",
    SEARCH_PAGE: "search_page"
};

export const EXPOSURE_EVENT_MSITE = {
    [EXPOSURE_EVENT_KEYS.HOME_PAGE]: [EXPERIMENT_KEYS.showTestDriveV2, EXPERIMENT_KEYS.showFinanceCentericDiscovery, EXPERIMENT_KEYS.showWebToAppBanner],
    [EXPOSURE_EVENT_KEYS.LISTING_PAGE]: [EXPERIMENT_KEYS.showSellCarBanner, EXPERIMENT_KEYS.showTestDriveV2, EXPERIMENT_KEYS.webCustomStateExperiment, EXPERIMENT_KEYS.showPriceConfidence, EXPERIMENT_KEYS.showFinanceCentericDiscovery, EXPERIMENT_KEYS.showWebToAppBanners, EXPERIMENT_KEYS.showClpRevamp, EXPERIMENT_KEYS.showSimilarCarCLP],
    [EXPOSURE_EVENT_KEYS.DETAIL_PAGE]: [EXPERIMENT_KEYS.showTestDriveLocation, EXPERIMENT_KEYS.benefitsIncluded, EXPERIMENT_KEYS.webCustomStateExperiment, EXPERIMENT_KEYS.showCdpAtfRevamp, EXPERIMENT_KEYS.showInspectionSectionRevamp, EXPERIMENT_KEYS.showPriceConfidence],
    [EXPOSURE_EVENT_KEYS.PAYMENT_OPTIONS_PAGE]: [EXPERIMENT_KEYS.showLoanOfferScreen],
    [EXPOSURE_EVENT_KEYS.SELECT_OFFER_PAGE]: [EXPERIMENT_KEYS.showLoanOfferScreen],
    [EXPOSURE_EVENT_KEYS.ORDER_SUMMARY_PAGE_CASH]: [EXPERIMENT_KEYS.showOrderSummaryCash],
    [EXPOSURE_EVENT_KEYS.ORDER_SUMMARY_PAGE_FINANCE]: [EXPERIMENT_KEYS.showOrderSummaryFinance],
    [EXPOSURE_EVENT_KEYS.C2B_LANDING_PAGE]: [EXPERIMENT_KEYS.showC2BLandingRevamp, EXPERIMENT_KEYS.guestLoginMobile],
    [EXPOSURE_EVENT_KEYS.ORDER_SUMMARY_PAGE_BYOF]: [EXPERIMENT_KEYS.showOrderSummaryByof],
    [EXPOSURE_EVENT_KEYS.FINANCE_FIRST_INTRO_PAGE]: [EXPERIMENT_KEYS.showFinanceFirstExperiment],
    [EXPOSURE_EVENT_KEYS.SEARCH_PAGE]: [EXPERIMENT_KEYS.showMobileSearchRevamp]
};

export const EXPOSURE_EVENT_DESKTOP = {
    [EXPOSURE_EVENT_KEYS.HOME_PAGE]: [EXPERIMENT_KEYS.showDesktopHomepageRevamp, EXPERIMENT_KEYS.showTestDriveV2],
    [EXPOSURE_EVENT_KEYS.DETAIL_PAGE]: [EXPERIMENT_KEYS.showCdpGalleryVariant, EXPERIMENT_KEYS.cdpBiCta, EXPERIMENT_KEYS.showTestDriveV2, EXPERIMENT_KEYS.webCustomStateExperiment, EXPERIMENT_KEYS.showCdpGalleryVariant],
    [EXPOSURE_EVENT_KEYS.LISTING_PAGE]: [EXPERIMENT_KEYS.showTestDriveV2, EXPERIMENT_KEYS.webCustomStateExperiment, EXPERIMENT_KEYS.showDesktopListingCardGallery],
    [EXPOSURE_EVENT_KEYS.PAYMENT_OPTIONS_PAGE]: [EXPERIMENT_KEYS.showLoanOfferScreen],
    [EXPOSURE_EVENT_KEYS.SELECT_OFFER_PAGE]: [EXPERIMENT_KEYS.showLoanOfferScreen],
    [EXPOSURE_EVENT_KEYS.ORDER_SUMMARY_PAGE_CASH]: [EXPERIMENT_KEYS.showOrderSummaryCash],
    [EXPOSURE_EVENT_KEYS.ORDER_SUMMARY_PAGE_FINANCE]: [EXPERIMENT_KEYS.showOrderSummaryFinance],
    [EXPOSURE_EVENT_KEYS.ORDER_SUMMARY_PAGE_BYOF]: [EXPERIMENT_KEYS.showOrderSummaryByof],
    [EXPOSURE_EVENT_KEYS.SHOW_SERVICE_HISTORY_TEXT]: [EXPERIMENT_KEYS.showServiceHistoryText],
    [EXPOSURE_EVENT_KEYS.FINANCE_FIRST_INTRO_PAGE]: [EXPERIMENT_KEYS.showFinanceFirstExperiment],
    [EXPOSURE_EVENT_KEYS.CDP_BI_CTA]: [EXPERIMENT_KEYS.cdpBiCta]
};

export default EXPERIMENT_KEYS;
