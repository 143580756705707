export const EXPERIMENT_NAME = {
    ab_test: "ab_test",
    c2bSellLandingMobileNumber: "c2b_sell_landing_mobile_number"
};

export const AB_TEST_VARIATIONS = {
    CONTROL: "CONTROL",
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B"
};
