/* eslint-disable max-statements */
const DEBUG = process.env.NODE_ENV === "development";

export default () => {
    if (typeof window !== "undefined") {
        const { pathname } = window.location;
        const isHomePage = pathname === "/";
        const isCheckoutPage = !!(pathname.match("checkout") && pathname.match("checkout").length);
        const isDetailsPage = new RegExp(/(\/buy-used-[a-zA-Z0-9-]*-car-[0-9]*)/).test(pathname);
        const isListingPage = new RegExp(/(\/buy-used-*)/).test(pathname) && !isDetailsPage;
        const isSelectOfferPage = pathname.includes("select-offer");
        const isPaymentOptionsPage = pathname.includes("payment-options");
        const isOrderSummaryPage = pathname.includes("/checkout/summary");
        const isC2BLandingPage = pathname.includes("sell-your-car");
        const isFinanceFirstIntroPage = pathname.includes("/financing/get-pre-approval");
        const isSearchPage = pathname.includes("/search");

        const currentPage = {
            isDetailsPage: isDetailsPage && !isCheckoutPage,
            isListingPage,
            isCheckoutPage,
            isSelectOfferPage,
            isPaymentOptionsPage,
            isHomePage,
            isOrderSummaryPage,
            isC2BLandingPage,
            isFinanceFirstIntroPage,
            isSearchPage
        };
        if (DEBUG) window.console.debug(currentPage);
        return currentPage;
    }
    return false;
};
