import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
//import Call from "./images/phone-icon.svg";
import { CALLBACK_SOURCE_SCREEN, NUMBER, PAYMENT_OPTIONS } from "../../../constants/app-constants";
import { CallBackRequest } from "../call-back/component";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { useLocation, useParams } from "react-router-dom";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import CallbackUsModal from "../callback-us-modal";
import { contactUsFormMetaData, contactUsFormQuerySources } from "../../../utils/helpers/contactus-form-metadata";
import isCarDetailsPage from "../../../utils/helpers/is-car-details-page";
import { trackAmplitude } from "../../../tracking";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import { stringConstants } from "../../../au-c2b/constants/app-constants";

// eslint-disable-next-line max-statements
const HelpCenterModalDoubts = ({
    checkoutCallbackRequestConnect,
    checkoutCallbackRequestExpired,
    checkoutCallbackRequestLoading,
    onBackClick,
    mobile,
    onModalClose,
    open,
    loan,
    submitContactUsConnect,
    phoneNumber,
    firstName,
    lastName,
    carLocation,
    isUserZeroDpVariant,
    financeOpted
}) => {
    const { deposit } =  loan || {};
    const location = useLocation();
    const params = useParams();
    const [mobileNumber, setMobileNumber] = useState();
    const [callbackSupportModal, setCallbackSupportModal] = useState(false);
    const [checkoutCallbackSuccess, setCheckoutCallbackSuccess] = useState(false);
    const isCheckoutFLow =  params.stepid === checkoutRoutes.paymentSelection.route;
    const isSummaryScreen = params.stepid === checkoutRoutes.summary.route;
    const handleGAEvent = (event, properties) => {
        trackDesktopCustomEventsAU(event, {...properties});
    };

    const isFinanceOpted = (event) => {
        if (financeOpted) {
            return `${PAYMENT_OPTIONS.FINANCE}_${event}`;
        } else {
            return `${PAYMENT_OPTIONS.CASH}_${event}`;
        }
    };
    const handleAmplitudeEvent = (event, properties) => {
        trackAmplitude(event, {...properties});
    };

    const handleCallbackRequest = async () => {
        try {
            await checkoutCallbackRequestConnect(CALLBACK_SOURCE_SCREEN.LOAN_OFFERS);
            window.setTimeout(onModalClose, NUMBER.THOUSAND);
            handleGAEvent(AU_DESKTOP_EVENTS.PRE_APPROVED_LOAN_OFFERS, {
                eventAction: "exit",
                eventLabel: "Callback"
            });
        } catch (error) {
            //console.log(error)
        }
    };

    const handleCheckoutCallBackRequest = async() => {
        try {
            const payload = {
                phoneNumber: mobileNumber || phoneNumber || mobile,
                name: `${firstName} ${lastName}`,
                sourceUrl: location.pathname,
                ...contactUsFormMetaData({
                    querySource: contactUsFormQuerySources.helpSupportButton
                }),
                ...(isCarDetailsPage() && {carLocation})
            };
            if (phoneNumber) {
                await submitContactUsConnect(payload);
                // window.setTimeout(onModalClose, NUMBER.TWO_THOUSAND);
                setCheckoutCallbackSuccess(true);
                handleGAEvent(AU_DESKTOP_EVENTS.PRE_APPROVED_LOAN_OFFERS, {
                    eventAction: "exit",
                    eventLabel: "Callback"
                });
            } else {
                setCallbackSupportModal(true);
            //   setCheckoutCallbackSuccess(true);
            }
            handleAmplitudeEvent(isFinanceOpted(AMPLITUDE_EVENTS_CATEGORY.CALLBACK_REQUEST_LINK_CLICKED), {clicked: stringConstants.YES});

            handleGAEvent(isFinanceOpted(AMPLITUDE_EVENTS_CATEGORY.CALLBACK_REQUEST_LINK_CLICKED), {
                event: "custom_event",
                eventCategory: "Cars24_checkout_page",
                eventAction: "Call back request generator",
                eventLabel: isFinanceOpted(AMPLITUDE_EVENTS_CATEGORY.CALLBACK_REQUEST_LINK_CLICKED),
                clicked: "yes"
            });
        } catch (error) {
            //console.log(error)
        }
    };

    const handleLeaveClick = () => {
        handleGAEvent(AU_DESKTOP_EVENTS.PRE_APPROVED_LOAN_OFFERS, {
            eventAction: "exit",
            eventLabel: "Leave"
        });
        onModalClose();
        onBackClick();
    };

    useEffect(() => {
        handleAmplitudeEvent(isFinanceOpted(AMPLITUDE_EVENTS_CATEGORY.CALLBACK_REQUEST_MODAL_OPENED), {}); // Amplitude event
        handleGAEvent(isFinanceOpted(AU_DESKTOP_EVENTS.CALLBACK_REQUEST_MODAL_OPENED), { // GA Event
            eventCategory: "Cars24_checkout_page",
            eventAction: "Call back request generator",
            event: "custom_event",
            eventLabel: AU_DESKTOP_EVENTS.CALLBACK_REQUEST_MODAL_OPENED
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const displayZeroDpFLow = () => {
        return (
            <Modal isOpen={open} close={onModalClose}>
                <div styleName={"styles.loginModalZeroDp"}>
                    <p styleName={"styles.header"}>Before you leave we want to tell you that you can:</p>
                    <ul>
                        <p styleName={"styles.listItem"}>Opt-in for <span>$0 deposit</span>, which will allow you reserve car without any deposit amount</p>
                        <p styleName={"styles.listItem"}><span>Get in touch</span> with our finance team so that we can help you out with loan offers</p>
                    </ul>
                    <div styleName={"styles.flex"}>
                        <p onClick={handleLeaveClick} styleName={"styles.leave"}>I’ll still leave</p>
                        <CallBackRequest showSubText={false} phoneNumber={phoneNumber} loading={checkoutCallbackRequestLoading} onClick={handleCallbackRequest} success={!checkoutCallbackRequestExpired || checkoutCallbackSuccess} />
                    </div>
                </div>
            </Modal>

        );
    };
    const callBackModalClose = (val) => {
        setCallbackSupportModal(val);
        onModalClose();
    };
    const displayDefaultFlow = () => {
        return (
            <React.Fragment>
                {!callbackSupportModal && <Modal isOpen={open} close={onModalClose} >
                    <div styleName={"styles.loginModalNew"}>
                        <p styleName={"styles.text"}>Hey! If you have any <span>doubts</span> related to loan offers our team is here to help</p>
                        <CallBackRequest mobile={mobileNumber || phoneNumber || mobile} loading={checkoutCallbackRequestLoading} onClick={isCheckoutFLow ? handleCheckoutCallBackRequest :  handleCallbackRequest} success={!checkoutCallbackRequestExpired || checkoutCallbackSuccess} />
                        <p onClick={handleLeaveClick} styleName={"styles.leave"}>I’ll still leave</p>
                    </div>
                </Modal>}
                <Modal isOpen={callbackSupportModal} close={callBackModalClose}> <CallbackUsModal setMobileNumber={setMobileNumber} handleEmailUsModal={callBackModalClose} centralPosition={true} /></Modal>
            </React.Fragment>
        );
    };

    const displayCashModal = () => {
        return (
            <React.Fragment>
                {!callbackSupportModal && <Modal isOpen={open} close={onModalClose} >
                    <div styleName={"styles.loginModalNew"}>
                        <p styleName={"styles.text"}>Hey! If you have any <span>doubts</span> related to the car our team is here to help</p>
                        <CallBackRequest
                            mobile={mobileNumber || phoneNumber || mobile}
                            loading={checkoutCallbackRequestLoading}
                            onClick={handleCheckoutCallBackRequest}
                            success={!checkoutCallbackRequestExpired || checkoutCallbackSuccess}
                            subText="Our team will call you shortly"
                        />
                        <p onClick={handleLeaveClick} styleName={"styles.leave"}>I’ll still leave</p>
                    </div>
                </Modal>}
                <Modal isOpen={callbackSupportModal} close={callBackModalClose}> <CallbackUsModal setMobileNumber={setMobileNumber} handleEmailUsModal={callBackModalClose} centralPosition={true} /></Modal>
            </React.Fragment>
        );
    };

    if (isCheckoutFLow) {
        return (
            <React.Fragment>
                { displayCashModal()}
            </React.Fragment>
        );
    }

    if (isSummaryScreen) {
        return <React.Fragment> {financeOpted ? displayDefaultFlow() : displayCashModal() }</React.Fragment>;

    }

    return (
        <React.Fragment>
            {(isUserZeroDpVariant && deposit > 0) ? displayZeroDpFLow() : displayDefaultFlow()}
        </React.Fragment>
    );
};

export default HelpCenterModalDoubts;

HelpCenterModalDoubts.propTypes = {
    checkoutCallbackRequestConnect: PropTypes.func.isRequired,
    checkoutCallbackRequestExpired: PropTypes.bool.isRequired,
    checkoutCallbackRequestLoading: PropTypes.bool.isRequired,
    phoneNumber: PropTypes.string,
    onBackClick: PropTypes.func.isRequired,
    loan: PropTypes.object,
    isUserZeroDpVariant: PropTypes.bool,
    onModalClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    submitContactUsConnect: PropTypes.func,
    firstName: PropTypes.string,
    carLocation: PropTypes.string,
    lastName: PropTypes.string,
    mobile: PropTypes.string,
    financeOpted: PropTypes.bool
};
