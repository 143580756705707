/* eslint-disable max-params */

// eslint-disable-next-line max-statements
export default (api) => {
    const getVASBundles = (token, year, price) => {
        return api.get(`/api/v1/landing-page?carYear=${year}&carPrice=${price}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getVASItems = (token, key = "VAS_POPULAR_BUNDLES", year, price) => {
        return api.get(`/api/v1/vas-store?key=${key}&carYear=${year}&carPrice=${price}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const addVASItem = (token, orderId, type, key, year, price) => {
        let url = `/api/v1/vas/add-item/${orderId}/${type}/${key}`;
        if (year && price) {
            url = `/api/v1/vas/add-item/${orderId}/${type}/${key}?carYear=${year}&carPrice=${price}`;
        }
        return api.post(url, {}, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const removeVASItem = (token, orderId, type, key, year, price) => {
        let url = `/api/v1/vas/remove-item/${orderId}/${type}/${key}`;
        if (year && price) {
            url = `/api/v1/vas/remove-item/${orderId}/${type}/${key}?carYear=${year}&carPrice=${price}`;
        }
        return api.put(url, {}, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const getVASCart = (token, orderId, year, price) => {
        let url = `/api/v1/vas/${orderId}`;
        if (year && price) {
            url = `/api/v1/vas/${orderId}?carYear=${year}&carPrice=${price}`;
        }
        return api.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const clearVASCart = (token, orderId, year, price) => {
        let url = `/api/v1/vas/clear/${orderId}`;
        if (year && price) {
            url = `/api/v1/vas/clear/${orderId}?carYear=${year}&carPrice=${price}`;
        }
        return api.put(url, {}, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const vasTracker = (token, orderId, action) => {
        return api.put(`/api/v1/vas-tracker?orderId=${orderId}&action=${action}`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };

    const getVASProductDetail = (token, key, carYear, type, carPrice) => {
        return api.get(`/api/v1/detail-page?key=${key}&carYear=${carYear}&type=${type}&carPrice=${carPrice}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getWarrantyPlans = (token, params = "") => {
        return api.get(`/v1/extended-warranty/plans${params}`, {
            headers: {
                ...(token && {Authorization: `Bearer ${token}`}),
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    return {
        getVASBundles,
        getVASItems,
        getVASCart,
        addVASItem,
        removeVASItem,
        clearVASCart,
        vasTracker,
        getVASProductDetail,
        getWarrantyPlans
    };
};
