/* eslint-disable max-statements */
/* eslint-disable max-params */
import { X_VEHICLE_TYPE } from "../constants/api-constants";
import { getDeviceType } from "../utils/helpers/get-device-type";

export default (api) => {
    const deviceType = getDeviceType();

    const getInhousePreApprovalLoanDetails = (token, reset = false, fetchListingDetails = false) => {
        let params = "";
        if (reset && fetchListingDetails) {
            params = "?reset=true&fetchClpCalculatorDetails=true";
        } else if (reset) {
            params = "?reset=true";
        } else if (fetchListingDetails) {
            params = "?fetchClpCalculatorDetails=true";
        }

        return api.get(`/api/v1/preapproval/quote${params}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getInhouseEligibility = (token) => {
        return api.get(`/api/v1/preapproval/me`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const updateInhousePreApprovalLoan = (token, uuid, payload, showFinanceFirstCheckout) => {
        let url = `/api/v1/preapproval/quote/${uuid}`;
        if (showFinanceFirstCheckout) {
            url = `${url}?financeFirstPreapprovalVariant=${showFinanceFirstCheckout}`;
        }
        return api.post(url, payload, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const updateInhouseLoanQuote = (token, orderId) => {
        return api.put(`/api/v1/finance/${orderId}/update-quotes`, {}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const submitInhousePreApprovalLoan = (token, uuid, payload) => {
        return api.post(`/api/v1/preapproval/submit/${uuid}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const inHouseFinanceFirstFinancePollApi = (uuid, token) => {
        return api.get(`/api/v1/preapproval/poll/${uuid}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getDepositRangeValueInHouse = () => {
        return api.get(`/api/v1/deposit/range`, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const createOrUpdateInHouseLoanOffer = (params, orderId, token) => {
        return api.post(`/api/v1/finance/quote/${orderId}`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getInHouseLoanOfferDetails = (orderId, token) => {
        return api.get(`/api/v1/finance/quote/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const selectInHouseLoanOffer = (orderId, offerId, token) => {
        return api.post(`/api/v1/finance/selected/${orderId}/${offerId}`, { }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const submitInHouseLoanOffer = (params, orderId, token, urlParam, apiSource) => {
        return api.post(`/api/v1/finance/submit/${orderId}`, { ...params }, {
            params: urlParam,
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: apiSource || deviceType
            }
        });
    };

    const persistSelectedQuote = (params, orderId, token, apiSource) => {
        return api.post(`/api/v1/finance/submit/${orderId}?persistSelectedQuote=true`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: apiSource || deviceType
            }
        });
    };

    const checkEligibilityBFF = (token, params = {}) => {
        return api.post(`/api/v1/finance/eligibility`, params, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const inHouseFinancePollingApi = (orderId, token, params) => {
        return api.get(`/api/v1/finance/poll-offers/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            },
            params
        });
    };

    const selectInHouseLoanOfferV2 = (orderId, offerId, token, selectedCycle = "") => {
        return api.post(`/api/v1/finance/selected-offers/${orderId}/${offerId}?selectedCycle=${selectedCycle}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getFinancePostPaymentApplication = (token, orderId) => {
        return api.get(`/api/v1/finance/${orderId}/loan-application`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const patchFinancePostPaymentApplication = (params, payload) => {
        const {token, orderId, formId} = params;
        return api.patch(`/api/v1/finance/${orderId}/loan-application/${formId}`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const postFinancePostPaymentApplication = (params, payload) => {
        const {token, orderId, formId} = params;
        return api.post(`/api/v1/finance/${orderId}/loan-application/${formId}`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const patchFinanceUploadDocument = (params, payload) => {
        const {token, orderId, formId} = params;
        return api.patch(`/api/v1/finance/${orderId}/loan-document/${formId}`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const postFinanceUploadDocument = (params, payload) => {
        const {token, orderId, formId} = params;
        return api.post(`/api/v1/finance/${orderId}/loan-document/${formId}`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const financeUploadDocument = (params, payload) => {
        const {token, orderId} = params;
        return api.post(`/finance/api/v1/${orderId}/upload-document`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const getPreSignedFinanceUploadedImage = (token, params) => {
        return api.get(`finance/api/v1/loan-document/preSigned`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            },
            params
        });
    };

    const getZeroDpConfig = (token, params) => {
        return api.get(`/user-config`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const postPreApprovalRequestCallback = (token, params, urlParam, payload) => {
        const { uuid } = params || {};
        return api.put(`/api/v1/preapproval/callback/${uuid}`, payload, {
            params: urlParam,
            headers: {
                ...(token ? {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getCheckoutCallbackRequest = (token, orderId) => {
        return api.get(`/api/v1/finance/callback/${orderId}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const postCheckoutCallbackRequest = ({token, orderId, source}, params) => {
        return api.put(`/api/v1/finance/callback/${orderId}?requested=true&source=${source}`, {}, {
            params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const updateZeroDpExpiryTime = (token, orderId, payload) => {
        return api.put(`api/v1/finance/${orderId}/loan-application/extension`, payload, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const autoUpdateZeroDpExpiryTime = (token, orderId, payload) => {
        return api.put(`api/v1/finance/${orderId}/loan-application/auto-extension`, payload, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const getZeroDpTextDataConfig = (token) => {
        return api.get(`/config`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const getHemExpenses = ({token = "", annualIncome, relationshipStatus, dependants, pincode} = {}) => {
        let url = "/hem-expenses?";
        if (+annualIncome >= 0) url += `annualIncome=${annualIncome}`;
        if (token) {
            if (relationshipStatus) url += `&relationshipStatus=${relationshipStatus}`;
            if (+dependants >= 0) url += `&dependants=${dependants}`;
            if (+pincode) url += `&pincode=${pincode}`;
        }

        return api.get(url, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const getPreApprovalQuestions = ({token = "", scenario = "Ff_Form"} = {}) => {
        return api.get(`/config/metadata?scenario=${scenario}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const getInterestRateForPriceCalculator = ({ token = "", price, userId, appointmentId, year, creditScore }) => {
        const params = `appointmentId=${appointmentId}&userId=${userId}&year=${year}&price=${price}&creditType=${creditScore}`;

        return api.get(`/public/cdp/interest-rate?${params}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const getMinMaxInterestRates = () => {
        return api.get(`/clc/interest-rates`, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const getCheckoutSummaryFAQData = ({token = "", scenario = "Ff_Form"} = {}) => {
        return api.get(`/config/metadata?scenario=${scenario}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }});
    };

    const fetchStraightThroughEligble = ({token = ""} = {}) => {
        return api.get((`/st/eligibility`), {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const submitStraightThroughUser = ({token = "", orderId} = {}) => {
        return api.post(`st/submit/${orderId}`, {}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getStApplicationStatus = ({orderId, token} = {}) => {
        return api.get(`/st/status/${orderId}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    return {
        getInhousePreApprovalLoanDetails,
        getInhouseEligibility,
        updateInhousePreApprovalLoan,
        submitInhousePreApprovalLoan,
        inHouseFinanceFirstFinancePollApi,
        getDepositRangeValueInHouse,
        createOrUpdateInHouseLoanOffer,
        getInHouseLoanOfferDetails,
        selectInHouseLoanOffer,
        submitInHouseLoanOffer,
        checkEligibilityBFF,
        inHouseFinancePollingApi,
        selectInHouseLoanOfferV2,
        getFinancePostPaymentApplication,
        patchFinancePostPaymentApplication,
        postFinancePostPaymentApplication,
        patchFinanceUploadDocument,
        postFinanceUploadDocument,
        financeUploadDocument,
        getPreSignedFinanceUploadedImage,
        getZeroDpConfig,
        postPreApprovalRequestCallback,
        getCheckoutCallbackRequest,
        postCheckoutCallbackRequest,
        updateZeroDpExpiryTime,
        getZeroDpTextDataConfig,
        autoUpdateZeroDpExpiryTime,
        persistSelectedQuote,
        getHemExpenses,
        getPreApprovalQuestions,
        getInterestRateForPriceCalculator,
        getMinMaxInterestRates,
        getCheckoutSummaryFAQData,
        fetchStraightThroughEligble,
        submitStraightThroughUser,
        getStApplicationStatus,
        updateInhouseLoanQuote
    };
};
