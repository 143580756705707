import { createReducer } from "reduxsauce";
import { DEFAULT_FINANCE_TYPE } from "../../../config/finance-config";
import Types from "./types";

export const INITIAL_STATE = {
    showTestDrive: {
        data: null
    },
    showInHouseFinance: {
        data: DEFAULT_FINANCE_TYPE.showInHouseVariant
    },
    showWarrantyPageShift: {
        data: null
    },
    showNonPreApprovedPayOptions: {
        data: null
    },
    showPremiumFeatures: {
        data: null
    },
    showZeroDownpayment: {
        data: null
    },
    showFinanceFirstExperiment: {
        data: null
    },
    showUserDeliveryTimeline: {
        data: null
    },
    showLenderDisplayExperiment: {
        data: null
    },
    showSellCarBanner: {
        data: null
    },
    showCdpGalleryVariant: {
        data: null
    },
    showLoanOfferScreen: {
        data: null
    },
    showDesktopHomepageRevamp: {
        data: null
    },
    showOrderSummaryCash: {
        data: null
    },
    showOrderSummaryFinance: {
        data: null
    },
    showC2BLandingRevamp: {
        data: null
    },
    showOrderSummaryByof: {
        data: null
    },
    showWebTrustElements: {
        data: null
    },
    showWebToAppNudges: {
        data: null
    },
    showServiceHistoryText: {
        data: null
    },
    showIntermediateValuation: {
        data: null
    },
    cdpBiCta: {
        data: null
    },
    showTestDriveLocation: {
        data: null
    },
    showTestDriveV2: {
        data: null
    },
    benefitsIncluded: {
        data: null
    },
    showDesktopCdpRevamp: {
        data: null
    },
    showFinanceFirstCheckout: {
        data: null
    },
    guestLoginMobile: {
        data: null
    },
    webCustomStateExperiment: {
        data: null
    },
    showMobileSearchRevamp: {
        data: null
    },
    showSellCarAnimation: {
        data: null
    },
    showCdpAtfRevamp: {
        data: null
    },
    showInspectionSectionRevamp: {
        data: null
    },
    c2bSellCarSurvey: {
        data: null
    },
    showPriceConfidence: {
        data: null
    },
    showC2bMechanicalCondition: {
        data: null
    },
    c2bValuationTimer: {
        data: null
    },
    showTestDriveFinanceInfusion: {
        data: null
    },
    showWebToAppBanner: {
        data: null
    },
    showFinanceCentericDiscovery: {
        data: null
    },
    showClpRevamp: {
        data: null
    },
    showSimilarCarCLP: {
        data: null
    },
    showDesktopListingCardGallery: {
        data: null
    },
    statsigConfig: {
        initialized: false
    },
    c2bSellLandingMobileNumber: {
        data: null
    }
};

export const setExperiment = (state = INITIAL_STATE, {
    experimentType, experimentData, flattened = false
}) => {
    return {
        ...state,
        ...(flattened ? { [experimentType]: experimentData } :
            {
                [experimentType]: {
                    data: experimentData
                }
            })
    };
};

export const setAllABExperiment = (state = INITIAL_STATE, { variants }) => {
    return {
        ...state,
        ...variants
    };
};

export const setStatsigInitialization = (state = INITIAL_STATE, { statsigInitialized }) => {
    return {
        ...state,
        statsigConfig: {
            initialized: statsigInitialized
        }
    };
};

export const allClearExperiment = () => {
    return {};
};

export const clearExperiment = ({ updatedExperiments }) => {
    return {
        updatedExperiments
    };
};

export const HANDLERS = {
    [Types.SET_EXPERIMENT]: setExperiment,
    [Types.SET_ALL_EXPERIMENT]: setAllABExperiment,
    [Types.SET_STATSIG_INITIALIZATION]: setStatsigInitialization,
    [Types.ALL_CLEAR_EXPERIMENT]: allClearExperiment,
    [Types.CLEAR_EXPERIMENT]: clearExperiment
};

export default createReducer(INITIAL_STATE, HANDLERS);
