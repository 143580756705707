import React, {useEffect} from "react";
import { NUMBER } from "../constants/app-constants";
import { SHOW_QUICK_SELL_SURVEY } from "../au-c2b/constants/app-constants";
import { getItem, setItem } from "../utils/helpers/storage-crud";
import { EXPERIMENT_TYPE } from "../constants/optimize-constants";

const useSellSurvey = (getUpdatedValue, delay = NUMBER.THOUSAND, c2bSellCarSurvey) => {

    const handleCloseSurvey = ((clearTimeout = false) => {
        let timerId = null;
        window.clearTimeout(timerId);
        if (clearTimeout) {
            getUpdatedValue(false);
            return;
        }
        timerId = window.setTimeout(() => {
            getUpdatedValue(false);
        }, (NUMBER.THOUSAND * NUMBER.FIFTEEN));
    });

    useEffect(() => {
        const showQuickSell = getItem(SHOW_QUICK_SELL_SURVEY) ? JSON.parse(getItem(SHOW_QUICK_SELL_SURVEY)) : true;
        const isVariantB = c2bSellCarSurvey.data === EXPERIMENT_TYPE.VARIANT_B;
        let timerId = null;
        if (showQuickSell && isVariantB) {
            window.clearTimeout(timerId);
            timerId = window.setTimeout(() => {
                getUpdatedValue(true);
                handleCloseSurvey();
                setItem("false", SHOW_QUICK_SELL_SURVEY);
            }, delay);
        }
        return () => {
            window.clearTimeout(timerId);
            handleCloseSurvey(true);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return handleCloseSurvey;
};

export default useSellSurvey;
