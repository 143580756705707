import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_NUDGE_DETAILS
    HIDE_NUDGE_DETAILS
    SHOW_HELP_SUPPORT_MODAL
`,
    {
        prefix: "supportWidget/"
    }
);
