import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import styles from "./styles.css";

import { AUTH_ADDRESS } from "../../../../constants/api-constants";
import LoginStepTwoOTP from  "../../../../components/au.desktop/login-step-two-otp";
import Modal from "../../../../components/shared/modal";

import Email from "./images/email-notification.png";
import CustomerCare from "./images/customer-care.png";

// Tracking
import { trackC2bMobileCustomEvents } from "../../../tracking";
import { C2B_AU_MOBILE_EVENTS } from "../../../tracking/c2b-mobile-events";
import { desktopCleverTapEvent, trackAmplitude, trackDesktopCustomEventsAU } from "../../../../tracking";
import { AMPLITUDE_EVENTS } from "../../../tracking/amplitude-events";
import { AMPLITUDE_LOGIN_EVENTS } from "../../../../tracking/amplitude-events";
import { LOGIN_SOURCE, NUMBER } from "../../../constants/app-constants";
import { AU_DESKTOP_EVENTS } from "../../../../tracking/au.desktop-events";
import parseCookie from "../../../../utils/helpers/parse-cookie";
import jwtDecode from "jwt-decode";
import { getHashedDetails } from "../../../../utils/helpers/get-sha256-hash";

// eslint-disable-next-line max-statements
const GuestLoginEmailVerify = ({
    isVisible,
    verifySignInConnect,
    sendOTPConnect,
    email,
    onGuestEmailVerifyCallback,
    clearCallbacksAndClose
}) => {

    const [otp, setOtp] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const { orderId } = useParams();

    useEffect(() => {
        if (email) {
            sendOTPConnect(email, AUTH_ADDRESS.EMAIL);
            trackAmplitude(AMPLITUDE_LOGIN_EVENTS.LOGIN_INITIATED, {
                source: LOGIN_SOURCE.TRADE_IN
            });
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LOGIN_INITIATED, {eventLabel: LOGIN_SOURCE.TRADE_IN});
            trackAmplitude(AMPLITUDE_EVENTS.LOGIN_ATTEMPTED, {
                "order id": orderId
            });
        }
    }, [sendOTPConnect, email, orderId]);

    const onOtpChange = (e) => {
        const number = e.target.value;
        if (number && number.length === NUMBER.SIX) {
            trackAmplitude(AMPLITUDE_LOGIN_EVENTS.OTP_ENTERED, {
                source: LOGIN_SOURCE.TRADE_IN,
                "OTP entered": number
            });
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.OTP_ENTERED, {
                eventLabel: number
            });
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.OTP_ENTERED, {
                eventLabel: LOGIN_SOURCE.TRADE_IN
            });
        }
        setOtp(number);
    };

    const handleLoginSuccessEvents = async(mobilePhone = "") => {
        trackAmplitude(AMPLITUDE_LOGIN_EVENTS.OTP_VERIFICATION_SUCCESS, {
            source: LOGIN_SOURCE.TRADE_IN
        });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.OTP_VERIFICATION_SUCCESS, {
            eventLabel: LOGIN_SOURCE.TRADE_IN
        });
        trackAmplitude(AMPLITUDE_LOGIN_EVENTS.LOGIN_SUCCESS, {
            mode: AUTH_ADDRESS.EMAIL.toLowerCase()
        });
        const {email: hashedEmail = null, mobile: hashedMobile = null} = await getHashedDetails({email, mobile: mobilePhone || ""});
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LOGIN_SUCCESS, {
            eventLabel: AUTH_ADDRESS.EMAIL.toLowerCase(),
            au_hashed_email: hashedEmail || null,
            au_hashed_phone_number: hashedMobile || null
        });
        desktopCleverTapEvent("SIGNUP", {
            Mode_of_lead: "EMAIl",
            signup_amplitudeId: parseCookie("amp_amplit"),
            signup_gaid: parseCookie("_ga")}, {userAgent: true});
        trackAmplitude(AMPLITUDE_EVENTS.SUCCESSFUL_LOGIN, {
            "order id": orderId
        });
    };

    const handleLoginFailureEvents = () => {
        trackAmplitude(AMPLITUDE_LOGIN_EVENTS.OTP_VERIFICATION_FAILED, {
            source: LOGIN_SOURCE.TRADE_IN,
            "OTP entered": otp
        });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.OTP_VERIFICATION_FAILED, {
            eventLabel: otp
        });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.OTP_VERIFICATION_FAILED, {
            eventLabel: LOGIN_SOURCE.TRADE_IN
        });
    };
    // eslint-disable-next-line max-statements
    const handleUserLogin = async () => {
        try {
            trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.VERIFY_PHONE_NO_SCREEN, {
                eventLabel: "verify clicked"
            });
            setShowLoader(true);
            setErrorMessage("");
            const payload = { token: otp, username: email, addressType: AUTH_ADDRESS.EMAIL };
            const profileResponse = await verifySignInConnect(payload);
            // eslint-disable-next-line camelcase
            const {access_token = ""} = profileResponse || {};
            const {mobilePhone = ""} = jwtDecode(access_token) || {};
            onGuestEmailVerifyCallback();
            clearCallbacksAndClose();
            handleLoginSuccessEvents(mobilePhone || "");
        } catch (error) {
            // handleOnClose();
            setErrorMessage(error.message || "Invalid OTP. Please try again.");
            handleLoginFailureEvents();
        } finally {
            setShowLoader(false);
        }
    };

    const trackInputEvent = () => {
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.VERIFY_PHONE_NO_SCREEN, {
            eventLabel: "otp_entered"
        });
    };
    return (
        <Modal isOpen={isVisible}>
            <div styleName={"styles.loginModal"}>
                <div styleName={"styles.leftPanel"}>
                    <img src={Email} />
                    <p styleName={"styles.heading"}>Verify email address to proceed</p>
                    <p styleName={"styles.subHeading"}>Your order isn’t complete until your email is verified. Code sent to your email</p>
                    <LoginStepTwoOTP
                        userEmail={email}
                        otp={otp}
                        onOtpChange={onOtpChange}
                        handleUserLogin={handleUserLogin}
                        errorMessage={errorMessage}
                        showLoader={showLoader}
                        setErrorMessage={setErrorMessage}
                        showOtpSupportText={false}
                        onOtpFocusOut={trackInputEvent}
                    />
                </div>
                <div styleName={"styles.rightPanel"}>
                    <div styleName={"styles.customerCareWrap"}>
                        <img src={CustomerCare} />
                        <p styleName={"styles.reachOut"}>If you have any doubts reach out to us at +61 482 077 377 </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

GuestLoginEmailVerify.propTypes = {
    onGuestEmailVerifyCallback: PropTypes.func,
    verifySignInConnect: PropTypes.func,
    sendOTPConnect: PropTypes.func,
    clearCallbacksAndClose: PropTypes.func,
    email: PropTypes.string,
    isVisible: PropTypes.bool
};

export default GuestLoginEmailVerify;
