import Types from "./types";
import { showToast } from "../../shared/toast-message/actions";
import { VasService } from "../../../service";
import { updateChargeDetails } from "../checkout-info/actions";

const getVASBundlesRequest = () => ({
    type: Types.GET_VAS_BUNDLES_REQUEST
});

const getVASBundlesSuccess = (data) => ({
    type: Types.GET_VAS_BUNDLES_SUCCESS,
    data
});

const getVASBundlesFailure = (error) => ({
    type: Types.GET_VAS_BUNDLES_FAILURE,
    error
});

const getVASBundles = (year, price) =>
    async (dispatch, getState) => {
        dispatch(getVASBundlesRequest());
        const {
            user: { secureToken }
        }  = getState();
        try {
            const response = await VasService.getVASBundles(secureToken, year, price);
            dispatch(getVASBundlesSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getVASBundlesFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

const getVASItemsRequest = () => ({
    type: Types.GET_VAS_ITEMS_REQUEST
});

const getVASItemsSuccess = (data) => ({
    type: Types.GET_VAS_ITEMS_SUCCESS,
    data
});

const getVASItemsFailure = (error) => ({
    type: Types.GET_VAS_ITEMS_FAILURE,
    error
});

const getVASItems = (key, year, price) =>
    async (dispatch, getState) => {
        dispatch(getVASItemsRequest());
        const {
            user: { secureToken }
        }  = getState();
        try {
            const response = await VasService.getVASItems(secureToken, key, year, price);
            dispatch(getVASItemsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getVASItemsFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

const getVASCartRequest = () => ({
    type: Types.GET_VAS_CART_REQUEST
});

const getVASCartSuccess = (data) => ({
    type: Types.GET_VAS_CART_SUCCESS,
    data
});

const getVASCartFailure = (error) => ({
    type: Types.GET_VAS_CART_FAILURE,
    error
});

const getVASCart = (orderId) =>
    async (dispatch, getState) => {
        dispatch(getVASCartRequest());
        const {
            user: { secureToken }
        }  = getState();
        try {
            const response = await VasService.getVASCart(secureToken, orderId);
            dispatch(getVASCartSuccess(response.data));
            dispatch(updateChargeDetails(response.data.items));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getVASCartFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

const setCounterAnimation = (data) => ({
    type: Types.SET_COUNTER_ANIMATION,
    data
});

const addVASItemRequest = (data) => ({
    type: Types.ADD_VAS_ITEM_REQUEST,
    data
});

const addVASItemSuccess = (data) => ({
    type: Types.ADD_VAS_ITEM_SUCCESS,
    data
});

const addVASItemFailure = (error) => ({
    type: Types.ADD_VAS_ITEM_FAILURE,
    error
});

const addVASItem = ({orderId, type, key }) =>
    async (dispatch, getState) => {
        dispatch(addVASItemRequest(key));

        const {
            user: { secureToken }
        }  = getState();
        try {
            const response = await VasService.addVASItem(secureToken, orderId, type, key);
            dispatch(addVASItemSuccess(response.data));
            dispatch(getVASCartSuccess(response.data));
            dispatch(updateChargeDetails(response.data.items));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(addVASItemFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

const removeVASItemRequest = (data) => ({
    type: Types.REMOVE_VAS_ITEM_REQUEST,
    data
});

const removeVASItemSuccess = (data) => ({
    type: Types.REMOVE_VAS_ITEM_SUCCESS,
    data
});

const removeVASItemFailure = (error) => ({
    type: Types.REMOVE_VAS_ITEM_FAILURE,
    error
});

const removeVASItem = ({ orderId, type, key, skipLoad = false}) =>
    async (dispatch, getState) => {
        dispatch(removeVASItemRequest(skipLoad ? null : key));

        const {
            user: { secureToken }
        }  = getState();
        try {
            const response = await VasService.removeVASItem(secureToken, orderId, type, key);
            dispatch(removeVASItemSuccess(response.data));
            dispatch(getVASCartSuccess(response.data));
            dispatch(updateChargeDetails(response.data.items));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(removeVASItemFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

const clearVASCartRequest = () => ({
    type: Types.CLEAR_VAS_CART_REQUEST
});

const clearVASCartSuccess = (data) => ({
    type: Types.CLEAR_VAS_CART_SUCCESS,
    data
});

const clearVASCartFailure = (error) => ({
    type: Types.CLEAR_VAS_CART_FAILURE,
    error
});

const clearVASCart = (orderId) =>
    async (dispatch, getState) => {
        dispatch(clearVASCartRequest());

        const {
            user: { secureToken }
        }  = getState();
        try {
            const response = await VasService.clearVASCart(secureToken, orderId);
            dispatch(clearVASCartSuccess(response.data));
            dispatch(getVASCartSuccess(response.data));
            dispatch(updateChargeDetails(response.data.items));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(clearVASCartFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

const setAddonScreenType = (data) => ({
    type: Types.SET_ADDONS_SCREEN_TYPE,
    data
});

const vasTracker = (orderId, action) =>
    async (dispatch, getState) => {
        const {
            user: { secureToken }
        }  = getState();
        try {
            const response = await VasService.vasTracker(secureToken, orderId, action);
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

const getVASProductDetailRequest = () => ({
    type: Types.GET_VAS_PRODUCT_DETAIL_REQUEST
});

const getVASProductDetailSuccess = (data) => ({
    type: Types.GET_VAS_PRODUCT_DETAIL_SUCCESS,
    data
});

const getVASProductDetailFailure = (error) => ({
    type: Types.GET_VAS_PRODUCT_DETAIL_FAILURE,
    error
});

const getVASProductDetail = ({ key, carYear, type, carPrice }) =>
    async (dispatch, getState) => {
        dispatch(getVASProductDetailRequest());
        const {
            user: { secureToken }
        }  = getState();
        try {
            const response = await VasService.getVASProductDetail(secureToken, key, carYear, type, carPrice);
            dispatch(getVASProductDetailSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getVASProductDetailFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return Promise.reject(error);
        }
    };

export {
    getVASBundles,
    getVASItems,
    addVASItem,
    removeVASItem,
    getVASCart,
    clearVASCart,
    setAddonScreenType,
    setCounterAnimation,
    vasTracker,
    getVASProductDetail
};
