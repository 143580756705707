/* eslint-disable no-shadow */
/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React from "react";
import { BOOL_STATE, BOOL_OPTIONS, INCOME_LIST as INCOME_LIST_OPTIONS, LOAN_YEARS_OPTIONS, RELATIONSHIP_STATUS as RELATIONSHIP_STATUS_LABELS, RELATIONSHIP_STATUS_OPTIONS, BUSINESS_TYPE as BUSINESS_TYPE_OPTIONS, RESIDENCY_STATUS_INHOUSE as RESIDENCY_STATUS_INHOUSE_OPTIONS, TEMPORARY_VISA, LIVING_SITUATION as LIVING_SITUATION_OPTIONS, EMPLOYEMENT_TYPES as EMPLOYEMENT_TYPES_OPTIONS, PENSION, UNEMPLOYED, OWNER_WITHOUT_MORTGAGE, BUSINESS_TYPE_VALUE, EMPLOYEMENT_TYPES_VALUES } from "../../../constants/checkout-constants";
import * as Yup from "yup";
import { NUMBER } from "../../../constants/app-constants";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
//import { mobileNumberValidatorAU as mobileValidator, MOBILE_REGEX_AU_STARTING_ZERO_OR_NINE_DIGITS } from "../../../utils/validators/mobile-number-validator";
import { getMonthOptions, getOptions, getYearOptions } from "../../../utils/helpers/checkout-util";
import { getAgeFromDate } from "../../../utils/helpers/get-age";
import { trackAmplitude, trackDesktopCustomEventsAU, trackMobileCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { AU_MOBILE_EVENTS_IN_HOUSE_FINANCE } from "../../../tracking/au.mobile-events-in-house-finance";
import creditScoreImg from "../../../components/shared/images/finance/creditScore.svg";
import confidentialImg from "../../../components/shared/images/finance/confidentialImgWeb.svg";
//import infoImg from "../../../components/shared/images/finance/info.svg";
import { ZERO_DP_MINIMUM_SLIDER_STEP_COUNT } from "../../driva-config";
import { getFormCompletedStates } from "../../../utils/preapproval/get-form-completd-status";
import { monthNamesValues } from "../../../constants/finance-constants/month-names";
import  { findKey }  from "lodash";
import { numberRegex } from "../../../utils/helpers/regex";
import { differenceInMonths } from "date-fns";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
// import { getFinanceLendersPrivacyDiscliamers } from "../../utils/helpers/finance-loan-application-util";
// import { NUMBER } from "../../constants/app-constants";
import { getFinalIncome, getHemExpenses, setNudgeInfoBox, trackNudgeEvents } from "../../../utils/helpers/finance-util";

const gaEventFire = (eventObj, otherObj = {}) => {
    trackMobileCustomEventsAU(eventObj, otherObj);
};

//const CURRENT_YEAR = new Date().getFullYear();

const PRE_APPROVAL_FORM_DESKTOP_CONFIG = ({ components = {}, formData, depositRange = {}, isUserZeroDpVariant, questionsMetaData = {}, financeData, infoBox, setHemExpenses = () => {}, setInfoBox = () => {}, secureToken = "" }) => {
    const { loan, loanType, user } = formData || {};
    const { deposit, term } = loan || {};
    const { visaNumber, firstName, lastName, dateOfBirth, relationshipStatus = "", residency, currentAddressDurationBelow3Years, address, employment, previousAddress, userIncomeDetails, dependants } = user || {};
    const { type: employmentType, startMonth: employmentDurationMonths, startYear: employmentDurationYears } = (employment && employment[0]) || {};
    //const { durationMonths: previousEmploymentDurationMonths, durationYears: previousEmploymentDurationYears} = (employment && employment[1]) || {};
    const { isPurposeBusiness, businessStartMonth, businessStartYear, abnStartYear, abnStartMonth, isGstRegistered, gstStartYear, gstStartMonth } = loanType || {};
    const { postCode } = address || {};
    const { grossIncome, grossIncomeDuration, mortgageOrRentPayment, mortgageOrRentPaymentDuration, noMortage } = userIncomeDetails || {};

    const minDepositError = isUserZeroDpVariant ? "If you wish to put in a deposit, please put a minimum of $500. You could, however opt to proceed without a deposit (ZERO deposit), if you wish to" : `Deposit should be >= ${makePriceLabelRound(depositRange.min)}`;

    const {
        // stage 1

        // stage 1 -> form 1
        isStage1Form1Question1Completed,
        isStage1Form1Question2Completed,
        isStage1Form1Question3Completed,
        isStage1Form1Question4Completed,
        isStage1Form1Question5Completed,
        isStage1Form1Question6Completed,
        isStage1Form1Completed,

        // stage 1 -> form 2
        isStage1Form2Question1Completed,
        isStage1Form2Question2Completed,
        isStage1Form2Completed,

        isStage1Completed,

        // stage 2

        // stage 2 -> form 1
        isStage2Form1Question1Completed,
        isStage2Form1Question2Completed,
        isStage2Form1Question3Completed,
        isStage2Form1Completed,

        // stage 2 -> form 2
        isStage2Form2Question1Completed,
        isStage2Form2Question2Completed,
        isStage2Form2Completed,

        isStage2Completed,

        // stage 3 - form 1
        isStage3Form1Question1Completed,
        isStage3Form1Question2Completed,
        isStage3Form1Completed,

        // stage 3 - form 2
        isStage3Form2Question1Completed,
        isStage3Form2Question2Completed,
        isStage3Form2Completed,

        // stage 3 - form 3
        isStage3Form3Question1Completed,
        isStage3Form3Question2Completed,
        isStage3Form3Completed,

        // stage 3 - form 4
        isStage3Form4Question1Completed,
        isStage3Form4Question2Completed,
        isStage3Form4Question3Completed,
        isStage3Form4Question4Completed,
        isStage3Form4Completed,

        isStage3Completed
    } = getFormCompletedStates(formData);

    const {
        ABN,
        ADDRESS,
        BUSINESS_START_DATE,
        BUSINESS_TYPE,
        DEPENDENTS,
        DEPOSIT,
        DOB,
        EMPLOYMENT_DURATION,
        EMPLOYMENT_START_DATE,
        EMPLOYMENT_STATUS,
        FINANCE_FOR_BUSINESS,
        GROSS_INCOME,
        GST_REGISTRATION_DATE,
        GST,
        LIVING_SITUATION,
        LOAN_TERM,
        MORTGAGE_RENT_EXPENSES,
        NAME,
        OLD_ADDRESS,
        PARTNER_GROSS_INCOME,
        RELATIONSHIP_STATUS,
        RESIDENCY_STATUS,
        VISA_CLASS_NUMBER
    } = questionsMetaData || {};

    const grossIncomeIdentifier = ["GROSS_INCOME_LESS_THAN_EXPENSES", "GROSS_INCOME_GREATER_THAN_5000000", "GROSS_INCOME_LESS_THAN_20000", "RENT_GREATEN_THAN_GROSS_INCOME", "RENT_AND_HEM_GREATEN_THAN_GROSS_INCOME"].includes((infoBox || {}).identifier) && (infoBox || {}).identifier;

    return {
        formId: "v1-msite-pre-approval",
        totalStages: isPurposeBusiness === BOOL_STATE.YES ? NUMBER.TWO : NUMBER.THREE,
        formTitle: `Get Pre-approval`,
        disableResumeJourney: false,
        stages: [
            {
                name: "Loan details",
                route: "/financing/loan-details",
                isStageCompleted: isStage1Completed,
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        //bottomText: "No impact on credit score",
                        bottomTextImg: creditScoreImg,
                        infoText: "100% data confidentiality and no spams",
                        infoTextImg: confidentialImg,
                        onClickNext: () => {
                            const { additionalInfo = {}, identifier } = DEPOSIT || {};
                            const updatedAdditionalInfo = {
                                ...additionalInfo,
                                description: isUserZeroDpVariant ? (additionalInfo || {}).descriptionZeroDp : (additionalInfo || {}).description,
                                socialProof: (additionalInfo || {}).socialProof
                            };
                            setInfoBox({identifier, additionalInfo: updatedAdditionalInfo});
                            trackMobileCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT, {
                                eventLabel: "options Pre_approval_in_house_finance_business_purpose"
                            });
                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.BUSINESS_PURPOSE_QUES_ANSWERED, {
                                Yes: "Yes"
                            });
                        },
                        onClickBack: () => {
                            trackMobileCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK, {
                                eventLabel: "options Pre_approval_in_house_finance_business_purpose"
                            });
                        },
                        isCompleted: isStage1Form1Completed,
                        isVisible: true,
                        questions: [
                            {
                                ...FINANCE_FOR_BUSINESS,
                                helperText: null,
                                isRequired: false,
                                helperStyle: "styles.paragraphMargin",
                                isVisible: true,
                                fieldStyle: "removePadding",
                                isQuestionCompleted: isStage1Form1Question1Completed,
                                fields: [{
                                    apiKey: "loanType.isPurposeBusiness",
                                    hideError: [BOOL_STATE.YES, BOOL_STATE.NO].includes(isPurposeBusiness),
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: BOOL_OPTIONS,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        data: BOOL_OPTIONS,
                                        className: "col-6",
                                        styleName: "flexWrapper",
                                        onChange: (selectedValue) => {
                                            if (selectedValue === BOOL_STATE.YES) {
                                                const { additionalInfo = {}, identifier } = BUSINESS_TYPE || {};
                                                setInfoBox({additionalInfo, identifier});
                                            } else {
                                                const { additionalInfo = {}, identifier } = FINANCE_FOR_BUSINESS || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }

                                            //const { form } = props || {};
                                            //if (selectedValue === BOOL_STATE.NO) {
                                            //    form.setFieldValue("loanType.isAbnRegistered", BOOL_STATE.NA);
                                            //    form.setFieldValue("loanType.isGstRegistered", BOOL_STATE.NA);
                                            //}
                                            gaEventFire(AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_LOAN_TYPE, {eventLabel: `options ${selectedValue.toLowerCase()}`});
                                        }
                                    }
                                }]
                            },
                            {
                                ...BUSINESS_TYPE,
                                helperText: null,
                                isRequired: false,
                                helperStyle: "styles.paragraphMargin",
                                isVisible: isPurposeBusiness === BOOL_STATE.YES,
                                fieldStyle: "removePadding",
                                isQuestionCompleted: isStage1Form1Question2Completed,
                                fields: [{
                                    apiKey: "loanType.typeOfBusiness",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: BUSINESS_TYPE_OPTIONS,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        data: BUSINESS_TYPE_OPTIONS,
                                        className: "col-6",
                                        styleName: "flexWrapper",
                                        onChange: (selectedValue) => {
                                            const { showInfoForInput = false, additionalInfoForInputs = {} } = BUSINESS_TYPE || {};
                                            if (showInfoForInput) {
                                                const identifier = findKey(BUSINESS_TYPE_VALUE, v => v === selectedValue) || "";
                                                const {additionalInfo = {}} = additionalInfoForInputs[identifier] || {};
                                                setInfoBox({additionalInfo, identifier});
                                            } else {
                                                const { additionalInfo = {}, identifier } = BUSINESS_START_DATE || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }

                                            gaEventFire(AU_DESKTOP_EVENTS.IN_HOUSE_COMMERCIAL_LOAN, {
                                                eventAction: "Type of business",
                                                eventLabel: `${selectedValue}`
                                            });
                                        }
                                    }
                                }]
                            },
                            {
                                ...BUSINESS_START_DATE,
                                helperText: null,
                                isRequired: false,
                                isVisible: isPurposeBusiness === BOOL_STATE.YES,
                                fieldStyle: ["twoColumnDropDown", "employmnetStartDate"],
                                isQuestionCompleted: isStage1Form1Question3Completed,
                                fields: [
                                    {
                                        apiKey: "loanType.businessStartMonth",
                                        fieldType: components.select,
                                        title: null,
                                        isVisible: true,
                                        options: getMonthOptions(),
                                        placeholder: "Month",
                                        isRequired: false,
                                        priceType: false,
                                        props: {
                                            data: getMonthOptions(),
                                            isSearchable: false,
                                            onChange: (selectedValue) => {
                                                if (businessStartYear) {
                                                    const { additionalInfo = {}, identifier } = ABN || {};
                                                    setInfoBox({additionalInfo, identifier});
                                                }
                                                gaEventFire(AU_DESKTOP_EVENTS.IN_HOUSE_COMMERCIAL_LOAN, {
                                                    eventAction: "Business operational duration months",
                                                    eventLabel: `${selectedValue.label}`
                                                });
                                            }
                                        }
                                    },
                                    {
                                        apiKey: "loanType.businessStartYear",
                                        fieldType: components.select,
                                        title: null,
                                        isVisible: true,
                                        options: getYearOptions(),
                                        placeholder: "Year",
                                        isRequired: false,
                                        priceType: false,
                                        props: {
                                            data: getYearOptions(),
                                            parseStringToNum: true,
                                            isSearchable: false,
                                            onChange: (selectedValue) => {
                                                if (businessStartMonth) {
                                                    const { additionalInfo = {}, identifier } = ABN || {};
                                                    setInfoBox({additionalInfo, identifier});
                                                }
                                                gaEventFire(AU_DESKTOP_EVENTS.IN_HOUSE_COMMERCIAL_LOAN, {
                                                    eventAction: "Business operational duration years",
                                                    eventLabel: `${selectedValue.label}`
                                                });
                                            }
                                        }
                                    } ]
                            },
                            {
                                ...ABN,
                                helperText: null,
                                isRequired: false,
                                isVisible: isPurposeBusiness === BOOL_STATE.YES,
                                fieldStyle: ["twoColumnDropDown", "employmnetStartDate"],
                                isQuestionCompleted: isStage1Form1Question4Completed,
                                fields: [
                                    {
                                        apiKey: "loanType.abnStartMonth",
                                        fieldType: components.select,
                                        title: null,
                                        isVisible: true,
                                        options: getMonthOptions(),
                                        placeholder: "Month",
                                        isRequired: false,
                                        priceType: false,
                                        props: {
                                            data: getMonthOptions(),
                                            isSearchable: false,
                                            onChange: (selectedValue, props) => {
                                                if ([BOOL_STATE.YES, BOOL_STATE.NO].includes(isGstRegistered)) {
                                                    const { additionalInfo = {}, identifier } = GST || {};
                                                    setInfoBox({additionalInfo, identifier});
                                                }
                                                const { form } = props || {};
                                                form.setFieldValue("loanType.isAbnRegistered", BOOL_STATE.YES);
                                                gaEventFire(AU_DESKTOP_EVENTS.IN_HOUSE_COMMERCIAL_LOAN, {
                                                    eventAction: "ABN duration months",
                                                    eventLabel: `${selectedValue.label}`
                                                });
                                            }
                                        }
                                    }, {
                                        apiKey: "loanType.abnStartYear",
                                        fieldType: components.select,
                                        title: null,
                                        isVisible: true,
                                        options: getYearOptions(),
                                        placeholder: "Year",
                                        isRequired: false,
                                        priceType: false,
                                        props: {
                                            data: getYearOptions(),
                                            parseStringToNum: true,
                                            isSearchable: false,
                                            onChange: (selectedValue, props) => {
                                                if ([BOOL_STATE.YES, BOOL_STATE.NO].includes(isGstRegistered)) {
                                                    const { additionalInfo = {}, identifier } = GST || {};
                                                    setInfoBox({additionalInfo, identifier});
                                                }
                                                const { form } = props || {};
                                                form.setFieldValue("loanType.isAbnRegistered", BOOL_STATE.YES);
                                                gaEventFire(AU_DESKTOP_EVENTS.IN_HOUSE_COMMERCIAL_LOAN, {
                                                    eventAction: "ABN duration years",
                                                    eventLabel: `${selectedValue.label}`
                                                });
                                            }
                                        }
                                    }]
                            },
                            {
                                ...GST,
                                helperText: null,
                                isRequired: false,
                                isVisible: isPurposeBusiness === BOOL_STATE.YES,
                                fieldStyle: ["twoColumnDropDown", "businessSelectWrapGST", "removePadding"],
                                isQuestionCompleted: isStage1Form1Question5Completed,
                                fields: [{
                                    apiKey: "loanType.isGstRegistered",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: BOOL_OPTIONS,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        data: BOOL_OPTIONS,
                                        className: "col-6",
                                        styleName: "flexWrapper",
                                        onChange: (selectedValue) => {
                                            const { additionalInfo = {}, identifier } = GST_REGISTRATION_DATE || {};
                                            setInfoBox({additionalInfo, identifier});
                                            gaEventFire(AU_MOBILE_EVENTS_IN_HOUSE_FINANCE.IN_HOUSE_FINANCE_LOAN_TYPE_ADDITIONAL, {eventLabel: `option GST_${selectedValue.toLowerCase()}`});
                                        }
                                    }
                                }]
                            },
                            {
                                ...GST_REGISTRATION_DATE,
                                helperText: null,
                                isRequired: false,
                                isVisible: isPurposeBusiness === BOOL_STATE.YES && isGstRegistered === BOOL_STATE.YES,
                                fieldStyle: ["twoColumnDropDown", "employmnetStartDate"],
                                isQuestionCompleted: isStage1Form1Question6Completed,
                                fields: [
                                    {
                                        apiKey: "loanType.gstStartMonth",
                                        fieldType: components.select,
                                        title: null,
                                        isVisible: true,
                                        options: getMonthOptions(),
                                        placeholder: "Month",
                                        isRequired: false,
                                        priceType: false,
                                        props: {
                                            data: getMonthOptions(),
                                            isSearchable: false,
                                            onChange: (selectedValue) => {
                                                //setInfoBox({});
                                                gaEventFire(AU_DESKTOP_EVENTS.IN_HOUSE_COMMERCIAL_LOAN, {
                                                    eventAction: "GST duration years",
                                                    eventLabel: `${selectedValue.label}`
                                                });
                                            }
                                        }
                                    }, {
                                        apiKey: "loanType.gstStartYear",
                                        fieldType: components.select,
                                        title: null,
                                        isVisible: true,
                                        options: getYearOptions(),
                                        placeholder: "Year",
                                        isRequired: false,
                                        priceType: false,
                                        props: {
                                            data: getYearOptions(),
                                            parseStringToNum: true,
                                            isSearchable: false,
                                            onChange: (selectedValue) => {
                                                //setInfoBox({});
                                                gaEventFire(AU_DESKTOP_EVENTS.IN_HOUSE_COMMERCIAL_LOAN, {
                                                    eventAction: "GST duration months",
                                                    eventLabel: `${selectedValue.label}`
                                                });
                                            }
                                        }
                                    } ]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            loanType: Yup.object().transform((value, originalValue) => !originalValue ? {} : value)
                                .when(("isPurposeBusiness"), {
                                    is: () => isPurposeBusiness === BOOL_STATE.YES,
                                    then: Yup.object().shape({
                                        typeOfBusiness: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                        businessStartYear: Yup.number().required("Enter valid input").nullable("Enter valid input"),
                                        businessStartMonth: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                        abnStartYear: Yup.number().required("Enter valid input").nullable("Enter valid input"),
                                        abnStartMonth: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                        isGstRegistered: Yup.string().matches(/^(YES|NO)$/, "Enter valid input").required("Enter valid input").nullable("Enter valid input"),
                                        gstStartYear: Yup.mixed().when(("isGstRegistered"), {
                                            is: () => isGstRegistered === BOOL_STATE.YES,
                                            //then: Yup.number().min(abnStartYear, "Duration of GST registration cannot exceed that of ABN registration.").required("Enter valid input").nullable("Enter valid input")
                                            then: Yup.string()
                                                .test("gstStartYear", "Duration of GST registration cannot exceed that of ABN registration.", (value) => {
                                                    const gstAbnDiff = differenceInMonths(new Date(+abnStartYear, monthNamesValues.indexOf(abnStartMonth), NUMBER.ONE), new Date(+value, monthNamesValues.indexOf(gstStartMonth), NUMBER.ONE));

                                                    if (gstAbnDiff > 0) {
                                                        return false;
                                                    }
                                                    return true;
                                                })
                                                .required("Enter valid input").nullable("Enter valid input")
                                        }),
                                        gstStartMonth: Yup.mixed().when(("isGstRegistered"), {
                                            is: () => isGstRegistered === BOOL_STATE.YES,
                                            //then: Yup.number().max((abnYears === gstYears && gstMonths > abnMonths) ? abnMonths : NUMBER.ELEVEN, "Duration of GST registration cannot exceed that of ABN registration.").required("Enter valid input").nullable("Enter valid input")
                                            then: Yup.string()
                                                .test("gstStartMonth", "Duration of GST registration cannot exceed that of ABN registration.", (value) => {
                                                    const gstAbnDiff = differenceInMonths(new Date(+abnStartYear, monthNamesValues.indexOf(abnStartMonth), NUMBER.ONE), new Date(+gstStartYear, monthNamesValues.indexOf(value), NUMBER.ONE));

                                                    if (gstAbnDiff > 0) {
                                                        return false;
                                                    }

                                                    return true;
                                                })
                                                .required("Enter valid input").nullable("Enter valid input")
                                        })
                                    }),
                                    otherwise: Yup.object().shape({
                                        isPurposeBusiness: Yup.string().test("isPurposeBusiness", "Enter valid input", (value) => {
                                            return value === BOOL_STATE.NO;
                                        })
                                    })
                                }).nullable("Enter valid input")
                        }),
                        initialValues: formData
                    },
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        //bottomText: "No impact on credit score",
                        bottomTextImg: creditScoreImg,
                        onClickNext: () => {
                            const { additionalInfo = {}, identifier } = EMPLOYMENT_STATUS || {};
                            setInfoBox({additionalInfo, identifier});
                            trackMobileCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT, {
                                eventLabel: `options In_house_finance_deposit`
                            });
                            if (isUserZeroDpVariant) {
                                gaEventFire(AU_DESKTOP_EVENTS.ZERO_DP_PRE_APPROVAL, {
                                    eventAction: "Customer_finance first_zero_deposit_customer",
                                    eventLabel: deposit
                                });
                            }

                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.LOAN_DETAILS_COMPLETED, {
                                Deposit: deposit,
                                Tenure: term
                            });
                        },
                        onClickBack: () => {
                            //if (isPurposeBusiness === BOOL_STATE.NO) {
                            const { additionalInfo = {}, identifier } = FINANCE_FOR_BUSINESS || {};
                            setInfoBox({additionalInfo, identifier});
                            //} else if (isGstRegistered === BOOL_STATE.YES) {
                            //    const { additionalInfo = {}, identifier } = GST_REGISTRATION_DATE || {};
                            //    setInfoBox({additionalInfo, identifier});
                            //} else {
                            //    const { additionalInfo = {}, identifier } = GST || {};
                            //    setInfoBox({additionalInfo, identifier});
                            //}
                            trackMobileCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK, {
                                eventLabel: `options In_house_finance_deposit`
                            });
                        },
                        isCompleted: isStage1Form2Completed,
                        isVisible: true,
                        questions: [
                            {
                                ...DEPOSIT,
                                questionHelperText: isUserZeroDpVariant ? (DEPOSIT || {}).questionHelperTextZeroDp : (DEPOSIT || {}).questionHelperText,
                                helperText: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                isQuestionCompleted: isStage1Form2Question1Completed,
                                fields: [{
                                    apiKey: "loan.deposit",
                                    fieldType: components.inputSlider,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: "Enter amount",
                                    isRequired: false,
                                    errorKey: "deposit",
                                    props: {
                                        currentValue: deposit,
                                        showSlider: false,
                                        showSliderTop: isUserZeroDpVariant,
                                        maxValue: depositRange.max,
                                        minValue: depositRange.min,
                                        isUserZeroDpVariant,
                                        //zeroDepositToast: "$0 deposits will let you book the car at no cost",
                                        zeroDepositCustomToastDesktop: true,
                                        onChange: () => {
                                            const { additionalInfo = {}, identifier } = LOAN_TERM || {};
                                            setInfoBox({additionalInfo, identifier});
                                        },
                                        onSliderChange: () => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_DEPOSIT_SLIDER);
                                        },
                                        onBlurCallback: () => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_DEPOSIT_INPUT);
                                        }
                                    }
                                }]
                            },
                            {
                                ...LOAN_TERM,
                                helperText: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                isQuestionCompleted: isStage1Form2Question2Completed,
                                fields: [{
                                    apiKey: "loan.term",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: LOAN_YEARS_OPTIONS,
                                    placeholder: null,
                                    style: "flexWrapperLoan",
                                    isRequired: false,
                                    props: {
                                        data: LOAN_YEARS_OPTIONS,
                                        isSearchable: false,
                                        onChange: (selectedValue) => {
                                            //setInfoBox({});
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_LOAN_TENURE_OPTION, {
                                                eventLabel: `options ${selectedValue}`
                                            });
                                        }
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            loan: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                deposit: Yup.number().test({
                                    name: "min",
                                    message: minDepositError,
                                    exclusive: true,
                                    params: { ZERO_DP_MINIMUM_SLIDER_STEP_COUNT, isUserZeroDpVariant },
                                    test: (value) => {
                                        if (typeof value === "undefined") return false;
                                        if (isUserZeroDpVariant) {
                                            return (value >= ZERO_DP_MINIMUM_SLIDER_STEP_COUNT || value === 0);
                                        }
                                        return value >= depositRange.min;
                                    }
                                }).max(depositRange.max, "Minimum loan amount is $5000").required("Enter valid input").nullable("Enter valid input"),
                                term: Yup.number().min(NUMBER.THREE, "Enter valid input").required("Enter valid input").nullable("Enter valid input")
                            }).nullable("Enter valid input")
                        }),
                        initialValues: formData
                    }
                ],
                sidebarNavigation: {
                    key: "loanDetails",
                    title: "Loan details"
                    //description: "Add loan details"
                }
            },
            ...(isPurposeBusiness !== BOOL_STATE.YES && {
                name: "Employment details",
                route: "/financing/employment-details",
                isStageCompleted: isStage2Completed,
                forms: [
                    {
                        nextButtonCTA: "Next",
                        backButtonCTA: "BACK",
                        //bottomText: "Details needed to personalize your offers",
                        //bottomTextImg: infoImg,
                        onClickNext: () => {
                            if (isPurposeBusiness === BOOL_STATE.NO) {
                                //if (!isStage2Form2Question1Completed()) {
                                const { additionalInfo = {}, identifier } = GROSS_INCOME || {};
                                setInfoBox({additionalInfo, identifier});
                                //}
                            }

                            if (isPurposeBusiness === BOOL_STATE.YES) {
                                //if (!isStage3Form1Question1Completed()) {
                                const { additionalInfo = {}, identifier } = NAME || {};
                                setInfoBox({additionalInfo, identifier});
                                //}
                            }
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT, {
                                eventLabel: "options Pre_approval_in_house_finance_employment_status"
                            });
                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.CURRENT_EMPLOYMENT_STATUS_ANSWERED, {
                                "Current employment": employmentType
                            });
                        },
                        onClickBack: () => {
                            const { additionalInfo = {}, identifier } = DEPOSIT || {};
                            const updatedAdditionalInfo = {
                                ...additionalInfo,
                                description: isUserZeroDpVariant ? (additionalInfo || {}).descriptionZeroDp : (additionalInfo || {}).description,
                                socialProof: (additionalInfo || {}).socialProof
                            };
                            setInfoBox({identifier, additionalInfo: updatedAdditionalInfo});

                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK, {
                                eventLabel: "options Pre_approval_in_house_finance_employment_status"
                            });
                        },
                        isCompleted: isStage2Form1Completed,
                        isVisible: true,
                        questions: [
                            {
                                ...EMPLOYMENT_STATUS,
                                helperText: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: ["twoColumnDropDown", "businessSelectWrapGST", "removePadding"],
                                isQuestionCompleted: isStage2Form1Question1Completed,
                                fields: [{
                                    apiKey: "user.employment[0].type",
                                    errorKey: "type",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: EMPLOYEMENT_TYPES_OPTIONS,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        data: EMPLOYEMENT_TYPES_OPTIONS,
                                        className: "col-6",
                                        styleName: "flexWrapper",
                                        onChange: (selectedValue) => {
                                            const { showInfoForInput = false, additionalInfoForInputs = {} } = EMPLOYMENT_STATUS || {};
                                            if (showInfoForInput) {
                                                const identifier = findKey(EMPLOYEMENT_TYPES_VALUES, v => v === selectedValue) || "";
                                                const {additionalInfo = {}} = additionalInfoForInputs[identifier] || {};
                                                setInfoBox({additionalInfo, identifier});
                                            } else {
                                                const { additionalInfo = {}, identifier } = EMPLOYMENT_START_DATE || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS, {
                                                eventLabel: `options ${selectedValue}`
                                            });
                                        }
                                    }
                                }]
                            },
                            {
                                ...EMPLOYMENT_START_DATE,
                                helperText: null,
                                isRequired: false,
                                isVisible: employmentType && ![PENSION, UNEMPLOYED].includes(employmentType),
                                fieldStyle: ["twoColumnDropDown", "employmnetStartDate"],
                                isQuestionCompleted: isStage2Form1Question2Completed,
                                fields: [
                                    {
                                        apiKey: "user.employment[0].startMonth",
                                        errorKey: "startMonth",
                                        fieldType: components.select,
                                        title: null,
                                        isVisible: true,
                                        options: getMonthOptions(),
                                        placeholder: "Select month",
                                        isRequired: false,
                                        priceType: false,
                                        props: {
                                            data: getMonthOptions(),
                                            isSearchable: false,
                                            convertLabelAs: "month",
                                            onChange: (selectedValue) => {
                                                const lessThanSix = differenceInMonths(new Date(), new Date(employmentDurationYears, monthNamesValues.indexOf(selectedValue)), NUMBER.ONE) < NUMBER.SIX;

                                                if (![PENSION, UNEMPLOYED].includes(employmentType) && lessThanSix) {
                                                    const { additionalInfo = {}, identifier } = EMPLOYMENT_DURATION || {};
                                                    setInfoBox({additionalInfo, identifier});
                                                } else {
                                                    const { additionalInfo = {}, identifier } = EMPLOYMENT_START_DATE || {};
                                                    setInfoBox({additionalInfo, identifier});
                                                }
                                                gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS_TENURE, {
                                                    eventLabel: `durationMonths ${selectedValue.value}`
                                                });
                                                trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.CURRENT_EMPLOYMENT_DURATION, {
                                                    Month: selectedValue.value,
                                                    ...employmentDurationYears >= 0 &&  {Year: employmentDurationYears}
                                                });
                                            }
                                        }
                                    },
                                    {
                                        apiKey: "user.employment[0].startYear",
                                        errorKey: "startYear",
                                        fieldType: components.select,
                                        title: null,
                                        isVisible: true,
                                        options: getYearOptions(),
                                        placeholder: "Select year",
                                        isRequired: false,
                                        priceType: false,
                                        props: {
                                            data: getYearOptions(),
                                            isSearchable: false,
                                            convertLabelAs: "year",
                                            parseStringToNum: true,
                                            onChange: (selectedValue) => {
                                                const lessThanSix = differenceInMonths(new Date(), new Date(selectedValue, monthNamesValues.indexOf(employmentDurationMonths)), NUMBER.ONE) < NUMBER.SIX;

                                                if (![PENSION, UNEMPLOYED].includes(employmentType) && lessThanSix) {
                                                    const { additionalInfo = {}, identifier } = EMPLOYMENT_DURATION || {};
                                                    setInfoBox({additionalInfo, identifier});
                                                } else {
                                                    const { additionalInfo = {}, identifier } = EMPLOYMENT_START_DATE || {};
                                                    setInfoBox({additionalInfo, identifier});
                                                }
                                                gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS_TENURE, {
                                                    eventLabel: `durationYears ${selectedValue.value}`
                                                });
                                                trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.CURRENT_EMPLOYMENT_DURATION, {
                                                    ...employmentDurationMonths && { Month: employmentDurationMonths },
                                                    Year: selectedValue.value
                                                });
                                            }
                                        }
                                    }]
                            },
                            {
                                ...EMPLOYMENT_DURATION,
                                helperText: null,
                                isRequired: false,
                                isVisible: employmentType && employmentType !== PENSION && employmentType !== UNEMPLOYED && differenceInMonths(new Date(), new Date(employmentDurationYears, monthNamesValues.indexOf(employmentDurationMonths)), NUMBER.ONE) < NUMBER.SIX,
                                fieldStyle: ["twoColumnDropDown", "businessSelectWrap"],
                                isQuestionCompleted: isStage2Form1Question3Completed,
                                fields: [{
                                    apiKey: "user.employment[1].durationYears",
                                    errorKey: "durationYears",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("year", 0, NUMBER.TWENTY),
                                    placeholder: "Select years",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: getOptions("year", 0, NUMBER.TWENTY),
                                        isSearchable: false,
                                        convertLabelAs: "year",
                                        parseStringToNum: true,
                                        onChange: (selectedValue) => {
                                            //if (typeof previousEmploymentDurationMonths === "number" && typeof selectedValue === "number") {
                                            //    setInfoBox({});
                                            //}
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS_TENURE, {
                                                eventLabel: `durationYears ${selectedValue.value}`
                                            });
                                        }
                                    }
                                }, {
                                    apiKey: "user.employment[1].durationMonths",
                                    errorKey: "durationMonths",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: getOptions("month", 0, NUMBER.ELEVEN),
                                    placeholder: "Select months",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: getOptions("month", 0, NUMBER.ELEVEN),
                                        isSearchable: false,
                                        convertLabelAs: "month",
                                        parseStringToNum: true,
                                        onChange: (selectedValue) => {
                                            //if (typeof previousEmploymentDurationYears === "number" && typeof selectedValue === "number") {
                                            //    setInfoBox({});
                                            //}
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS_TENURE, {
                                                eventLabel: `durationMonths ${selectedValue.value}`
                                            });
                                        }
                                    }
                                }]
                            }
                        ],
                        validate: (values) => {
                            const errors = {};
                            if (!values.user || !values.user.employment || !values.user.employment[0]) {
                                errors.type = "Enter valid input";
                                return errors;
                            }

                            const employment1 = values.user.employment[0];

                            const validation1 = ![PENSION, UNEMPLOYED].includes(employment1.type);
                            const monthDiff = differenceInMonths(new Date(), new Date(employment1.startYear, monthNamesValues.indexOf(employment1.startMonth)), NUMBER.ONE);
                            const currentMonth = new Date().getMonth();

                            if (validation1) {
                                if (!employment1.startMonth || monthDiff < 0 || monthDiff === 0 && currentMonth !== monthNamesValues.indexOf(employment1.startMonth)) {
                                    errors.startMonth = "Enter valid input";
                                }

                                if (!employment1.startYear) {
                                    errors.startYear = "Enter valid input";
                                }
                            }

                            const employment2 = values.user.employment[1] || {};

                            const lessThanSix = differenceInMonths(new Date(), new Date(employment1.startYear, monthNamesValues.indexOf(employmentDurationMonths)), NUMBER.ONE) < NUMBER.SIX;

                            if (validation1 && lessThanSix) {
                                if (!employment2 || typeof employment2.durationYears !== "number") {
                                    errors.durationYears = "Enter valid input";
                                }

                                if (!employment2 || typeof employment2.durationMonths !== "number") {
                                    errors.durationMonths = "Enter valid input";
                                }
                            }

                            return errors;
                        },
                        initialValues: formData
                    },
                    ...(isPurposeBusiness === BOOL_STATE.NO && {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        //bottomText: "Details needed to personalize your offers",
                        //bottomTextImg: infoImg,
                        onClickNext: () => {
                            //if (!isStage3Form1Question1Completed()) {
                            const { additionalInfo = {}, identifier } = NAME || {};
                            setInfoBox({additionalInfo, identifier});
                            //}
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT, {
                                eventLabel: "options Pre_approval_in_house_finance_employment_status"
                            });
                        },
                        onClickBack: () => {
                            const { additionalInfo = {}, identifier } = EMPLOYMENT_STATUS || {};
                            setInfoBox({additionalInfo, identifier});
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK, {
                                eventLabel: "options Pre_approval_in_house_finance_employment_status"
                            });
                        },
                        isCompleted: isStage2Form2Completed,
                        isVisible: isPurposeBusiness === BOOL_STATE.NO,
                        questions: [
                            {
                                ...GROSS_INCOME,
                                helperText: null,
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: isPurposeBusiness === BOOL_STATE.NO,
                                fieldStyle: ["grossIncomeInput", "businessSelectWrap"],
                                isQuestionCompleted: isStage2Form2Question1Completed,
                                fields: [{
                                    apiKey: "user.userIncomeDetails.grossIncome",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: isPurposeBusiness === BOOL_STATE.NO,
                                    options: [],
                                    placeholder: "Enter income",
                                    isRequired: false,
                                    priceType: true,
                                    props: {
                                        identifier: grossIncomeIdentifier,
                                        onChange: () => {
                                            const { additionalInfo = {}, identifier } = GROSS_INCOME || {};
                                            setInfoBox({ additionalInfo, identifier });
                                        },
                                        onBlurCallback: async (e) => {
                                            const income = +e.target.value;

                                            // CONSIDERING YEAR AS FINAL BASE
                                            const finalGrossIncome = getFinalIncome(income, grossIncomeDuration);
                                            const finalMortgageAmount = getFinalIncome(mortgageOrRentPayment, mortgageOrRentPaymentDuration);
                                            const hemExpenses = await getHemExpenses({ secureToken, relationshipStatus, annualIncome: finalGrossIncome, dependants, postCode });

                                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.GROSS_INCOME, {
                                                "gross income": income,
                                                ...grossIncomeDuration && {"gross income frequency": grossIncomeDuration}
                                            });

                                            setHemExpenses(hemExpenses);
                                            setNudgeInfoBox({
                                                defaultIdentifier: "GROSS_INCOME",
                                                grossIncome: income,
                                                grossIncomeDuration,
                                                mortgageOrRentPayment,
                                                mortgageOrRentPaymentDuration,
                                                hemExpenses,
                                                metaData: questionsMetaData,
                                                setInfoBox,
                                                id: "grossIncome"
                                            });
                                            trackNudgeEvents({finalGrossIncome, finalMortgageAmount, hemExpenses });
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_INCOME_DETAILS, {
                                                eventLabel: `grossIncome ${income}`
                                            });
                                        }
                                    }
                                }, {
                                    apiKey: "user.userIncomeDetails.grossIncomeDuration",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: isPurposeBusiness === BOOL_STATE.NO,
                                    options: INCOME_LIST_OPTIONS,
                                    placeholder: "Frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_OPTIONS,
                                        isSearchable: false,
                                        onChange: (selectedValue) => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_INCOME_DETAILS, {
                                                eventLabel: `grossIncomeDuration ${selectedValue}`
                                            });
                                            const { additionalInfo = {}, identifier } = GROSS_INCOME || {};
                                            setInfoBox({ additionalInfo, identifier });
                                        },
                                        onBlurChange: async (selectedValue) => {
                                            // CONSIDERING YEAR AS FINAL BASE
                                            const finalGrossIncome = getFinalIncome(grossIncome, selectedValue);
                                            const finalMortgageAmount = getFinalIncome(mortgageOrRentPayment, mortgageOrRentPaymentDuration);
                                            const hemExpenses = await getHemExpenses({ secureToken, relationshipStatus, annualIncome: finalGrossIncome, dependants, postCode });
                                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.GROSS_INCOME, {
                                                ...grossIncome && { "gross income": grossIncome },
                                                "gross income frequency": selectedValue
                                            });
                                            setHemExpenses(hemExpenses);
                                            setNudgeInfoBox({
                                                defaultIdentifier: "GROSS_INCOME",
                                                grossIncome,
                                                grossIncomeDuration: selectedValue,
                                                mortgageOrRentPayment,
                                                mortgageOrRentPaymentDuration,
                                                hemExpenses,
                                                metaData: questionsMetaData,
                                                setInfoBox,
                                                id: "grossIncomeDuration"
                                            });
                                            trackNudgeEvents({finalGrossIncome, finalMortgageAmount, hemExpenses });
                                        }
                                    }
                                }]
                            },
                            {
                                ...MORTGAGE_RENT_EXPENSES,
                                //helperText: "The more accurate you are, the more accurate the finance quotes will be",
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: isPurposeBusiness === BOOL_STATE.NO,
                                fieldStyle: ["grossIncomeInput", "flexWrap", "businessSelectWrap"],
                                isQuestionCompleted: isStage2Form2Question2Completed,
                                fields: [{
                                    apiKey: "user.userIncomeDetails.mortgageOrRentPayment",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: isPurposeBusiness === BOOL_STATE.NO,
                                    options: [],
                                    placeholder: "Enter expense",
                                    isRequired: false,
                                    priceType: true,
                                    hideError: noMortage,
                                    props: {
                                        onChange: () => {
                                            const { additionalInfo = {}, identifier } = MORTGAGE_RENT_EXPENSES || {};
                                            setInfoBox({additionalInfo, identifier});
                                        },
                                        onBlurCallback: async (e) => {
                                            const income = +e.target.value;
                                            // CONSIDERING YEAR AS FINAL BASE
                                            const finalGrossIncome = getFinalIncome(grossIncome, grossIncomeDuration);
                                            const finalMortgageAmount = getFinalIncome(income, mortgageOrRentPaymentDuration);
                                            const hemExpenses = await getHemExpenses({ secureToken, relationshipStatus, annualIncome: finalGrossIncome, dependants, postCode });
                                            setHemExpenses(hemExpenses);
                                            setNudgeInfoBox({
                                                defaultIdentifier: "MORTGAGE_RENT_EXPENSES",
                                                grossIncome,
                                                grossIncomeDuration,
                                                mortgageOrRentPayment: income,
                                                mortgageOrRentPaymentDuration,
                                                hemExpenses,
                                                metaData: questionsMetaData,
                                                setInfoBox,
                                                id: "mortgageOrRentPayment"
                                            });

                                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.HOUSE_MORTGAGE, {
                                                "house rent ": income,
                                                "frequency": mortgageOrRentPaymentDuration
                                            });

                                            if (+finalMortgageAmount > +finalGrossIncome) {
                                                trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_FLOW, {
                                                    eventAction: "Nudge",
                                                    eventLabel: "Income_lesser_rent"
                                                });
                                            }
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_INCOME_DETAILS, {
                                                eventLabel: `mortgageOrRentPayment ${income}`
                                            });
                                        }
                                    }
                                }, {
                                    apiKey: "user.userIncomeDetails.mortgageOrRentPaymentDuration",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: isPurposeBusiness === BOOL_STATE.NO,
                                    options: INCOME_LIST_OPTIONS,
                                    placeholder: "Frequency",
                                    isRequired: false,
                                    priceType: false,
                                    hideError: noMortage,
                                    props: {
                                        data: INCOME_LIST_OPTIONS,
                                        isSearchable: false,
                                        onChange: (selectedValue) => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_INCOME_DETAILS, {
                                                eventLabel: `mortgageOrRentPaymentDuration ${selectedValue}`
                                            });
                                            const { additionalInfo = {}, identifier } = MORTGAGE_RENT_EXPENSES || {};
                                            setInfoBox({additionalInfo, identifier});
                                        },
                                        onBlurChange: async (selectedValue) => {
                                            // CONSIDERING YEAR AS FINAL BASE
                                            const finalGrossIncome = getFinalIncome(grossIncome, grossIncomeDuration);
                                            const finalMortgageAmount = getFinalIncome(mortgageOrRentPayment, selectedValue);
                                            const hemExpenses = await getHemExpenses({ secureToken, relationshipStatus, annualIncome: finalGrossIncome, dependants, postCode });
                                            setHemExpenses(hemExpenses);
                                            setNudgeInfoBox({
                                                defaultIdentifier: "MORTGAGE_RENT_EXPENSES",
                                                grossIncome,
                                                grossIncomeDuration,
                                                mortgageOrRentPayment,
                                                mortgageOrRentPaymentDuration: selectedValue,
                                                hemExpenses,
                                                metaData: questionsMetaData,
                                                setInfoBox,
                                                id: "mortgageOrRentPaymentDuration"
                                            });

                                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.HOUSE_MORTGAGE, {
                                                "house rent ": mortgageOrRentPayment,
                                                "frequency": selectedValue
                                            });

                                            if (+finalMortgageAmount > +finalGrossIncome) {
                                                trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_FLOW, {
                                                    eventAction: "Nudge",
                                                    eventLabel: "Income_lesser_rent"
                                                });
                                            }
                                        }
                                    }
                                },
                                {
                                    apiKey: "user.userIncomeDetails.noMortage",
                                    fieldType: components.checkbox,
                                    isVisible: true,
                                    label: "I have my own house with no mortgage",
                                    isRequired: false,
                                    style: "m-left",
                                    props: {
                                        currentValue: noMortage,
                                        onChange: ({ value, form = {} }) => {
                                            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_FLOW, {
                                                eventAction: "Form_details",
                                                eventLabel: "My_own_house_checkbox"
                                            });
                                            form.setFieldValue("user.livingSituation", value ? OWNER_WITHOUT_MORTGAGE : financeData.user.livingSituation);
                                        }
                                    }
                                }
                                ]
                            }
                        ],
                        formValidator: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                            user: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                userIncomeDetails: Yup.mixed().when(("isPurposeBusiness"), {
                                    is: () => isPurposeBusiness === BOOL_STATE.NO,
                                    then: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                        grossIncome: Yup.number().min(1, "Your income should be > 0").required("Enter valid input").nullable("Enter valid input"),
                                        grossIncomeDuration: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                        mortgageOrRentPayment: Yup.number().test("mortgageOrRentPayment", "Enter valid input", (value) => {
                                            return noMortage ? true : typeof value === "number";
                                        }).nullable("Enter valid input"),
                                        mortgageOrRentPaymentDuration: Yup.string("Enter valid input").test("mortgageOrRentPaymentDuration", "Enter valid input", () => {
                                            return noMortage ? true : mortgageOrRentPaymentDuration;
                                        }).nullable("Enter valid input")
                                    })
                                })
                            }).nullable("Enter valid input")
                        }),
                        initialValues: formData
                    })
                ],
                sidebarNavigation: {
                    key: "employmentDetails",
                    title: "Employment details"
                    //description: "Add employment details"
                }
            }),
            {
                name: "Your details",
                route: "/financing/personal-details",
                isStageCompleted: isStage3Completed,
                forms: [
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        //bottomText: "No impact on credit score",
                        bottomTextImg: creditScoreImg,
                        //jumpToNextPageNo: () => {
                        //    if (isPurposeBusiness === BOOL_STATE.YES) {
                        //        return NUMBER.THREE;
                        //    }
                        //    return NUMBER.TWO;
                        //},
                        onClickNext: () => {
                            //if (!isStage3Form2Question1Completed()) {
                            const { additionalInfo = {}, identifier } = LIVING_SITUATION || {};
                            setInfoBox({additionalInfo, identifier});
                            //}
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT, {
                                eventLabel: "options Pre_approval_in_house_personal_detail"
                            });
                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.NAME_AND_DOB_ANSWERED, {
                                yes: "yes"
                            });
                        },
                        onClickBack: () => {
                            if (isPurposeBusiness === BOOL_STATE.NO) {
                                const { additionalInfo = {}, identifier } = GROSS_INCOME || {};
                                setInfoBox({additionalInfo, identifier});
                            } else {
                                const { additionalInfo = {}, identifier } = EMPLOYMENT_STATUS || {};
                                setInfoBox({additionalInfo, identifier});
                            }
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK, {
                                eventLabel: "options Pre_approval_in_house_personal_detail"
                            });
                        },
                        isCompleted: isStage3Form1Completed,
                        isVisible: true,
                        questions: [
                            {
                                ...NAME,
                                helperText: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: "nameFieldWrap",
                                isQuestionCompleted: isStage3Form1Question1Completed,
                                fields: [{
                                    apiKey: "user.firstName",
                                    fieldType: components.inputText,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: "First",
                                    isRequired: false,
                                    props: {
                                        onChange: (e) => {
                                            if (e.target.value && lastName) {
                                                const { additionalInfo = {}, identifier } = NAME || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }
                                        },
                                        onBlurCallback: () => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_NAME_DETAILS, {
                                                eventLabel: `options first_name`
                                            });
                                        }
                                    }
                                }, {
                                    apiKey: "user.middleName",
                                    fieldType: components.inputText,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: "Middle (optional)",
                                    isRequired: false,
                                    props: {
                                        onBlurCallback: () => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_NAME_DETAILS, {
                                                eventLabel: `options middle_name`
                                            });
                                        }
                                    }
                                }, {
                                    apiKey: "user.lastName",
                                    fieldType: components.inputText,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: "Last",
                                    isRequired: false,
                                    props: {
                                        onChange: (e) => {
                                            if (firstName && e.target.value) {
                                                const { additionalInfo = {}, identifier } = NAME || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }
                                        },
                                        onBlurCallback: () => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_NAME_DETAILS, {
                                                eventLabel: `options last_name`
                                            });
                                        }
                                    }
                                }]
                            },
                            {
                                ...DOB,
                                //helperText: "Your name and D.O.B helps personalise your finance quotes, and will be kept confidential.",
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: null,
                                isQuestionCompleted: isStage3Form1Question2Completed,
                                fields: [{
                                    apiKey: "user.dateOfBirth",
                                    fieldType: components.dateOfBirth,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: "MM-DD-YYYY",
                                    isRequired: false,
                                    props: {
                                        value: dateOfBirth,
                                        handleChange: () => {
                                            //setInfoBox({});
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_DOB);
                                        }
                                    }
                                }]
                            }
                            //{
                            //    questionLabel: "Mobile number",
                            //    questionHelperLabel: null,
                            //    helperText: "We only contact you if there is an issue with your pre-approval. We won’t spam you with calls.",
                            //    isRequired: false,
                            //    isVisible: true,
                            //    fieldStyle: null,
                            //    fields: [{
                            //        apiKey: "user.mobile",
                            //        fieldType: components.inputMobile,
                            //        title: null,
                            //        isVisible: true,
                            //        options: null,
                            //        placeholder: null,
                            //        isRequired: false,
                            //        props: {
                            //            placeholder: `+${PHONE_COUNTRY_CODE} - Your mobile number`,
                            //            value: mobile,
                            //            validator: mobileValidator,
                            //            onBlurCallback: () => {
                            //                gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_MOBILE_NUMBER_ENTERED, {
                            //                    eventLabel: "mobile"
                            //                });
                            //            }
                            //        }
                            //    }]
                            //}
                        ],
                        formValidator: Yup.object().shape({
                            user: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                firstName: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                middleName: Yup.string("Enter valid input"),
                                lastName: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                dateOfBirth: Yup.string().required("Enter valid input").test("dateOfBirth", "You must be at least 18 years old", (value) => {
                                    const date = (value || "").split("-");
                                    const userAge = getAgeFromDate(`${date[NUMBER.TWO]}-${date[1]}-${date[0]}`, "-");
                                    return userAge >= NUMBER.EIGHTEEN;
                                })
                                //mobile: Yup.string().matches(MOBILE_REGEX_AU_STARTING_ZERO_OR_NINE_DIGITS, "Enter valid input").required("Enter valid input").nullable("Enter valid input")
                            }).nullable("Enter valid input")
                        }),
                        initialValues: formData
                    },
                    {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        //bottomText: "We won’t spam you",
                        //bottomTextImg: infoImg,
                        onClickNext: () => {
                            if (isPurposeBusiness === BOOL_STATE.YES) {
                                //if (!isStage3Form4Question1Completed()) {
                                const { additionalInfo = {}, identifier } = RESIDENCY_STATUS || {};
                                setInfoBox({additionalInfo, identifier});
                                //}
                            }

                            if (isPurposeBusiness === BOOL_STATE.NO) {
                                //if (!isStage3Form3Question1Completed()) {
                                const { additionalInfo = {}, identifier } = RELATIONSHIP_STATUS || {};
                                setInfoBox({additionalInfo, identifier});
                                //}
                            }
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT, {
                                eventLabel: "options Pre_approval_in_house_finance_living_situation"
                            });
                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.CURRENT_LIVING_QUESTION_AND_DEPENDENTS_ANSWERED, {
                                yes: "yes"
                            });
                        },
                        onClickBack: () => {
                            const { additionalInfo = {}, identifier } = NAME || {};
                            setInfoBox({additionalInfo, identifier});
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK, {
                                eventLabel: "options Pre_approval_in_house_finance_living_situation"
                            });
                        },
                        isCompleted: isStage3Form2Completed,
                        isVisible: true,
                        questions: [
                            {
                                ...LIVING_SITUATION,
                                helperText: null,
                                isRequired: false,
                                isVisible: true,
                                helperStyle: "styles.paragraphMargin",
                                fieldStyle: ["livingSituation"],
                                isQuestionCompleted: isStage3Form2Question1Completed,
                                fields: [{
                                    apiKey: "user.livingSituation",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: LIVING_SITUATION_OPTIONS,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        data: LIVING_SITUATION_OPTIONS,
                                        onChange: (selectedValue, options) => {
                                            const { showInfoForInput = false, additionalInfoForInputs = {} } = LIVING_SITUATION || {};
                                            if (showInfoForInput) {
                                                const { identifier } = LIVING_SITUATION_OPTIONS.find(v => v.value === selectedValue) || "";
                                                const { additionalInfo = {} } = additionalInfoForInputs[identifier] || {};
                                                setInfoBox({additionalInfo, identifier});
                                            } else if (isPurposeBusiness === BOOL_STATE.NO) {
                                                const { additionalInfo = {}, identifier } = DEPENDENTS || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_LIVING_SITUATION, {
                                                eventLabel: `options ${options.gtm}`
                                            });
                                        }
                                    }
                                }]
                            },
                            {
                                ...DEPENDENTS,
                                helperText: null,
                                isRequired: false,
                                isVisible: isPurposeBusiness === BOOL_STATE.NO,
                                fieldStyle: null,
                                isQuestionCompleted: isStage3Form2Question2Completed,
                                fields: [{
                                    apiKey: "user.dependants",
                                    fieldType: components.increementDecreeement,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: null,
                                    isRequired: false,
                                    defaultValue: 0,
                                    props: {
                                        min: 0,
                                        minError: "# dependents should be >=0",
                                        onChange: (selectedValue) => {
                                            //setInfoBox({});
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_NO_OF_DEPENDANTS, {
                                                eventLabel: selectedValue
                                            });
                                        }
                                    }
                                }]
                            }
                        ],
                        formValidator: Yup.object().shape({
                            user: Yup.object().transform((value, originalValue) => !originalValue ? {} : value).shape({
                                livingSituation: Yup.string().required("Enter valid input").nullable("Enter valid input"),
                                //dependants: Yup.number().required("Enter valid input").nullable("Enter valid input")
                                dependants: Yup.string().test("isPurposeBusiness", "Enter valid input", (value) => {
                                    if (isPurposeBusiness === BOOL_STATE.YES) {
                                        return true;
                                    }
                                    return typeof value !== "undefined";
                                })
                            }).nullable("Enter valid input")
                        }),
                        initialValues: formData
                    },
                    ...(isPurposeBusiness === BOOL_STATE.NO && {
                        nextButtonCTA: "NEXT",
                        backButtonCTA: "BACK",
                        //bottomText: "We won’t spam you",
                        //bottomTextImg: infoImg,
                        isVisible: isPurposeBusiness === BOOL_STATE.NO,
                        onClickNext: () => {
                            //if (!isStage3Form4Question1Completed()) {
                            const { additionalInfo = {}, identifier } = RESIDENCY_STATUS || {};
                            setInfoBox({additionalInfo, identifier});
                            //}
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT, {
                                eventLabel: "options Pre_approval_in_house_finance_relationship_status"
                            });
                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.RELATIONSHIP_STATUS_AND_PARTNERS_GROSS_INCOME_ANSWERED, {
                                yes: "yes"
                            });
                        },
                        onClickBack: () => {
                            const { additionalInfo = {}, identifier } = LIVING_SITUATION || {};
                            setInfoBox({additionalInfo, identifier});
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK, {
                                eventLabel: "options Pre_approval_in_house_finance_relationship_status"
                            });
                        },
                        isCompleted: isStage3Form3Completed,
                        questions: [
                            {
                                ...RELATIONSHIP_STATUS,
                                helperText: null,
                                isRequired: false,
                                isVisible: isPurposeBusiness === BOOL_STATE.NO,
                                fieldStyle: ["livingSituation"],
                                isQuestionCompleted: isStage3Form3Question1Completed,
                                fields: [{
                                    apiKey: "user.relationshipStatus",
                                    errorKey: "relationshipStatus",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: RELATIONSHIP_STATUS_OPTIONS,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        data: RELATIONSHIP_STATUS_OPTIONS,
                                        onChange: (selectedValue) => {
                                            const { showInfoForInput = false, additionalInfoForInputs = {} } = RELATIONSHIP_STATUS || {};
                                            if (showInfoForInput) {
                                                const { identifier } = RELATIONSHIP_STATUS_OPTIONS.find(v => v.value === selectedValue) || "";
                                                const {additionalInfo = {}} = additionalInfoForInputs[identifier] || {};
                                                setInfoBox({additionalInfo, identifier});
                                            } else if (([RELATIONSHIP_STATUS_LABELS.MARRIED, RELATIONSHIP_STATUS_LABELS.DEFACTO]).includes(selectedValue)) {
                                                const { additionalInfo = {}, identifier } = PARTNER_GROSS_INCOME || {};
                                                setInfoBox({additionalInfo, identifier});
                                            } else {
                                                const { additionalInfo = {}, identifier } = RELATIONSHIP_STATUS || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_RELATIONSHIP_STATUS, {
                                                eventLabel: selectedValue
                                            });
                                        }
                                    }
                                }]
                            },
                            {
                                ...PARTNER_GROSS_INCOME,
                                //helperText: "Your partner won’t become a co-applicant. Lenders require this to provide accurate quotes.",
                                inFormNudge: null,
                                isRequired: false,
                                isVisible: relationshipStatus === RELATIONSHIP_STATUS_LABELS.MARRIED || relationshipStatus === RELATIONSHIP_STATUS_LABELS.DEFACTO,
                                fieldStyle: ["dollorplusdropdownInputPartner", "flexWrap", "businessSelectWrap"],
                                isQuestionCompleted: isStage3Form3Question2Completed,
                                fields: [{
                                    apiKey: "user.userIncomeDetails.partnerGrossIncome",
                                    errorKey: "partnerGrossIncome",
                                    fieldType: components.amountInput,
                                    title: null,
                                    isVisible: true,
                                    options: [],
                                    placeholder: "Enter income",
                                    isRequired: false,
                                    priceType: true,
                                    props: {
                                        onBlurCallback: (e) => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_INCOME_DETAILS, {
                                                eventLabel: `partnerGrossIncome ${e.target.value}`
                                            });
                                        }
                                    }
                                }, {
                                    apiKey: "user.userIncomeDetails.partnerGrossIncomeDuration",
                                    errorKey: "partnerGrossIncomeDuration",
                                    fieldType: components.select,
                                    title: null,
                                    isVisible: true,
                                    options: INCOME_LIST_OPTIONS,
                                    placeholder: "Frequency",
                                    isRequired: false,
                                    priceType: false,
                                    props: {
                                        data: INCOME_LIST_OPTIONS,
                                        isSearchable: false,
                                        onChange: (selectedValue) => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_INCOME_DETAILS, {
                                                eventLabel: `partnerGrossIncomeDuration ${selectedValue}`
                                            });
                                        }
                                    }
                                }
                                //{
                                //    apiKey: "user.userIncomeDetails.skipPartnerGrossIncome",
                                //    fieldType: components.checkbox,
                                //    isVisible: true,
                                //    label: "I’d rather not mention",
                                //    isRequired: false,
                                //    style: "w-100",
                                //    props: {
                                //        currentValue: skipPartnerGrossIncome,
                                //        onChange: ({ value, form }) => {
                                //            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_APPROVAL_FLOW, {
                                //                eventAction: "Form_details",
                                //                eventLabel: "Not_mention_checkbox"
                                //            });
                                //            form.setFieldValue("user.userIncomeDetails.partnerGrossIncome", value ? "0" : financeData.user.userIncomeDetails.partnerGrossIncome || partnerGrossIncome);
                                //            form.setFieldValue("user.userIncomeDetails.partnerGrossIncomeDuration", value ? "weekly" : financeData.user.userIncomeDetails.partnerGrossIncomeDuration || partnerGrossIncomeDuration);
                                //        }
                                //    }
                                //}
                                ]
                            }
                        ],
                        validate: (values) => {
                            const errors = {};
                            if (!values.user || !values.user.relationshipStatus) {
                                errors.relationshipStatus = "Enter valid input";
                                return errors;
                            }

                            const validation1 = [RELATIONSHIP_STATUS_LABELS.MARRIED, RELATIONSHIP_STATUS_LABELS.DEFACTO].includes(relationshipStatus);

                            if (validation1) {
                                if (!values.user.userIncomeDetails.partnerGrossIncome) {
                                    errors.partnerGrossIncome = "Enter valid input";
                                }

                                if (!values.user.userIncomeDetails.partnerGrossIncomeDuration) {
                                    errors.partnerGrossIncomeDuration = "Enter valid input";
                                }
                            }

                            return errors;
                        },
                        initialValues: formData
                    }),
                    {
                        nextButtonCTA: "SUBMIT",
                        backButtonCTA: "BACK",
                        isLastPage: true,
                        //bottomText: "We won’t spam you",
                        //bottomTextImg: infoImg,
                        //jumpToPrevPageNo: () => {
                        //    if (isPurposeBusiness === BOOL_STATE.YES) {
                        //        return NUMBER.ONE;
                        //    }
                        //    return NUMBER.TWO;
                        //},
                        onClickNext: () => {
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT, {
                                eventLabel: "options Pre_approval_in_house_finance_residency_status"
                            });
                            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.FORM_SUBMISSION, {
                                yes: "yes"
                            });
                        },
                        onClickBack: () => {
                            if (isPurposeBusiness === BOOL_STATE.NO) {
                                const { additionalInfo = {}, identifier } = RELATIONSHIP_STATUS || {};
                                setInfoBox({additionalInfo, identifier});
                            } else {
                                const { additionalInfo = {}, identifier } = LIVING_SITUATION || {};
                                setInfoBox({additionalInfo, identifier});
                            }
                            gaEventFire(AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK, {
                                eventLabel: "options Pre_approval_in_house_finance_residency_status"
                            });
                        },
                        isCompleted: isStage3Form4Completed,
                        isVisible: true,
                        questions: [
                            {
                                ...RESIDENCY_STATUS,
                                helperText: null,
                                isRequired: false,
                                isVisible: true,
                                fieldStyle: ["livingSituation"],
                                isQuestionCompleted: isStage3Form4Question1Completed,
                                fields: [{
                                    apiKey: "user.residency",
                                    errorKey: "residency",
                                    fieldType: components.listTabGroup,
                                    title: null,
                                    isVisible: true,
                                    options: RESIDENCY_STATUS_INHOUSE_OPTIONS,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        data: RESIDENCY_STATUS_INHOUSE_OPTIONS,
                                        onChange: (selectedValue, option) => {
                                            if (selectedValue === TEMPORARY_VISA) {
                                                const { additionalInfo = {}, identifier } = VISA_CLASS_NUMBER || {};
                                                setInfoBox({additionalInfo, identifier});
                                            } else {
                                                const { additionalInfo = {}, identifier } = ADDRESS || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }

                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_RESIDENCY, {
                                                eventLabel: `options ${option.gtm}`
                                            });
                                        }
                                    }
                                }]
                            },
                            {
                                ...VISA_CLASS_NUMBER,
                                //questionLabel: "What is your visa class number?",
                                //questionHelperLabel: null,
                                helperText: null,
                                isRequired: false,
                                isVisible: residency === TEMPORARY_VISA,
                                fieldStyle: ["visaWrapper"],
                                isQuestionCompleted: isStage3Form4Question2Completed,
                                fields: [{
                                    apiKey: "user.visaNumber",
                                    errorKey: "visaNumber",
                                    fieldType: components.inputText,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: "Enter last 3 digits",
                                    isRequired: false,
                                    maxLength: NUMBER.THREE,
                                    onlyNumber: true,
                                    props: {
                                        onChange: () => {
                                            //if (!isStage3Form4Question3Completed()) {
                                            const { additionalInfo = {}, identifier } = ADDRESS || {};
                                            setInfoBox({additionalInfo, identifier});
                                            //}
                                        },
                                        onBlurCallback: () => {
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_RESIDENCY_VISA);
                                        }
                                    }
                                }]
                            },
                            {
                                ...ADDRESS,
                                helperText: null,
                                isRequired: false,
                                //isVisible: [AUSTRALIAN_CITIZEN, PERMANENT_RESIDENT, NEW_ZEALAND_CITIZEN].includes(residency),
                                isVisible: ((residency && residency !== TEMPORARY_VISA) || (residency === TEMPORARY_VISA && visaNumber)),
                                fieldStyle: ["addressInput", "flexWrap", "businessSelectWrap"],
                                isQuestionCompleted: isStage3Form4Question3Completed,
                                fields: [{
                                    apiKey: "user.address",
                                    errorKey: "address",
                                    fieldType: components.locationPicker,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        value: address,
                                        onChange: () => {
                                            //setInfoBox({});
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_ADDRESS, {
                                                eventLabel: "options current_address"
                                            });
                                        }
                                    }
                                },
                                {
                                    apiKey: "user.currentAddressDurationBelow3Years",
                                    excludeKeyFromAPI: true,
                                    fieldType: components.checkbox,
                                    isVisible: true,
                                    label: "I have stayed here for less than 2 years",
                                    isRequired: false,
                                    style: "w-100",
                                    props: {
                                        onChange: ({ value }) => {
                                            if (value && residency) {
                                                const { additionalInfo = {}, identifier } = OLD_ADDRESS || {};
                                                setInfoBox({additionalInfo, identifier});
                                            } else {
                                                const { additionalInfo = {}, identifier } = ADDRESS || {};
                                                setInfoBox({additionalInfo, identifier});
                                            }

                                            //if (!isStage3Form4Question3Completed()) {
                                            //    const { additionalInfo = {}, identifier } = ADDRESS || {};
                                            //    setInfoBox({additionalInfo, identifier});
                                            //} else if (value && residency && !isStage3Form4Question4Completed()) {
                                            //    const { additionalInfo = {}, identifier } = OLD_ADDRESS || {};
                                            //    setInfoBox({additionalInfo, identifier});
                                            //} else {
                                            //    setInfoBox({});
                                            //}
                                        },
                                        currentValue: currentAddressDurationBelow3Years || !!(previousAddress || {}).full
                                    }
                                }]
                            },
                            {
                                ...OLD_ADDRESS,
                                //helperText: "Your previous address will help us personalise your rate. Don’t worry, this won’t impact your credit score",
                                isRequired: false,
                                //isVisible: typeof currentAddressDurationYears === "number" && currentAddressDurationYears < NUMBER.THREE,
                                isVisible: residency && currentAddressDurationBelow3Years,
                                fieldStyle: null,
                                isQuestionCompleted: isStage3Form4Question4Completed,
                                fields: [{
                                    apiKey: "user.previousAddress",
                                    errorKey: "previousAddress",
                                    fieldType: components.locationPicker,
                                    title: null,
                                    isVisible: true,
                                    options: null,
                                    placeholder: null,
                                    isRequired: false,
                                    props: {
                                        regions: ["au", "nz"],
                                        value: previousAddress,
                                        onChange: () => {
                                            //setInfoBox({});
                                            gaEventFire(AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_ADDRESS, {
                                                eventLabel: "options previous_address"
                                            });
                                        }
                                    }
                                }]
                            }
                        ],
                        validate: (values) => {
                            const errors = {};
                            if (!values.user || !values.user.residency) {
                                errors.residency = "Enter valid input";
                                return errors;
                            }

                            const validation1 = values.user.residency === TEMPORARY_VISA;

                            if (validation1) {
                                if (!values.user.visaNumber || values.user.visaNumber.length !== NUMBER.THREE || !numberRegex.test(values.user.visaNumber)) {
                                    errors.visaNumber = "Enter valid input";
                                }
                            }

                            if (!values.user.address || !values.user.address.full) {
                                errors.address = "Enter valid input";
                            }

                            const validation2 = values.user.currentAddressDurationBelow3Years;

                            if (validation2) {
                                if (!values.user.previousAddress || !values.user.previousAddress.full) {
                                    errors.previousAddress = "Enter valid input";
                                }
                            }

                            return errors;
                        },
                        initialValues: formData
                    }
                ],
                sidebarNavigation: {
                    key: "personalDetails",
                    title: "Your details"
                    //description: "Add personal details"
                }
            }
        ]
    };
};

export {
    PRE_APPROVAL_FORM_DESKTOP_CONFIG
};
