import React from "react";
import styles from "./styles.css";
import Impact from "./images/impact.png";
import Refund from "./images/terms.png";
import Button from "../../shared/button";
import CarImage from "./images/car-img.png";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
// import { trackDesktopCustomEventsAU } from "../../../tracking";
import { trackAmplitude, trackDesktopCustomEventsAU } from "../../../tracking";
import { AMPLITUDE_EVENTS_CATEGORY} from "../../../tracking/amplitude-events";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const GetPreApprovedCardRevamp = ({preApproved, setPreApprovedOriginConnect, clearAllFiltersConnect}) => {

    const history = useHistory();

    const onApplyAllClickHandler = () => {
        setPreApprovedOriginConnect(CAR_LIST_EVENT_NAMES.HOME_PAGE);
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.PREAPPROVAL_CLICK_DONE);
        // trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PREAPPROVAL_APPLY_NOW, {
        //     eventLabel: "NA"
        // });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.FF_VOLUME_EXPERIMENT, {
            eventAction: "Finance_card_clicked",
            eventLabel: "Get_pre_approval_Home_page"
        });
        history.push("/financing/get-pre-approval");
    };

    const onViewCarsClickHandler = () => {
        clearAllFiltersConnect();
        history.push("/buy-used-cars-australia/");
    };

    const handleFinanceKnowMore = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.NUDGES_FINANCE_KNOW_MORE_FINANCE, {
            eventLabel: "Home_page"
        });
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.KNOW_MORE_FINANCE, {
            "Know_more": "Home_page"
        });
        history.push("/cars24-finance");
    };

    return (
        <div styleName={"styles.outer"}>
            <img src={CarImage} />
            {
                preApproved ?
                    <p styleName={"styles.heading"}>Congratulations! You are pre-approved</p>
                    :
                    <p styleName={"styles.heading"}>Get pre-approved<br /><span styleName={"styles.subHeading"}>for car loan in <span>2 mins</span></span></p>
            }
            <div styleName={"styles.stepsWrapper"}>
                <p styleName={"styles.steps"}><img src={Impact} />{preApproved ? "100% online finance process" : "No impact on credit score"}</p>
                <p styleName={"styles.steps"}><img src={Refund} />User privacy & data confidentiality</p>
            </div>
            {preApproved ?
                <Button text="VIEW CARS" onClick={onViewCarsClickHandler}/>
                :
                <Button text="get pre-approval" onClick={onApplyAllClickHandler}/>
            }
            <p styleName={"styles.knowMore"} onClick={handleFinanceKnowMore}>Know more about finance</p>
        </div>
    );
};

export default GetPreApprovedCardRevamp;

GetPreApprovedCardRevamp.propTypes = {
    preApproved: PropTypes.bool,
    setPreApprovedOriginConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func
};
