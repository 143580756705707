/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import closeImage from "./images/close.svg";
import { surveyQuestion } from "../../../constants/app-constants";
import { trackAmplitude, trackDesktopCustomEventsAU } from "../../../../tracking";
import {AMPLITUDE_EVENTS, GA_EVENTS} from "./tracking";

const QuickSellIntentSurveyCard = ({handleCloseSurvey, route, gaId}) => {

    const handleAnswerClick = (value) => {
        handleCloseSurvey(true);
        trackAmplitude(AMPLITUDE_EVENTS.SELL_SURVEY_CLICKED, {
            "visitor ID": gaId,
            "page": route,
            "answer": value
        });
        const category = route === "homepage" ? "Cars24_home_page" : route === "clp" ? "Cars24_listing_page" : "Cars24_detail_page";
        trackDesktopCustomEventsAU(GA_EVENTS.SELL_SURVEY_CLICKED, {
            event: "custom_event",
            eventAction: category,
            eventLabel: "sell survey",
            eventCategory: `${gaId}_${value}`
        });
    };

    const closeModal = () => {
        handleCloseSurvey(true);
        trackAmplitude(AMPLITUDE_EVENTS.SELL_SURVEY_CLICKED, {
            "visitor ID": gaId,
            "page": route,
            "answer": "close"
        });
        const category = route === "homepage" ? "Cars24_home_page" : route === "clp" ? "Cars24_listing_page" : "Cars24_detail_page";
        trackDesktopCustomEventsAU(GA_EVENTS.SELL_SURVEY_CLICKED, {
            event: "custom_event",
            eventAction: category,
            eventLabel: "sell survey",
            eventCategory: `${gaId}_close`
        });
    };

    return (
        <div styleName={"styles.outer"}>
            <div>
                <p styleName={"styles.heading"}>Do you own a car that you’re planning to sell?</p>
                <div styleName={"styles.tabWrapper"}>
                    {surveyQuestion.map((item) => {
                        return (
                            <div styleName={"styles.tab"} onClick={() => handleAnswerClick(item)}>
                                <p styleName={"styles.tabName"}>{item}</p>
                            </div>
                        );
                    })}
                </div>
                <p styleName={"styles.subHeading"}>Providing this information will help us serve you better. </p>
            </div>
            <img src={closeImage} alt="Quick Sell Survey modal close" onClick={closeModal} />
        </div>
    );
};

QuickSellIntentSurveyCard.propTypes = {
    handleCloseSurvey: PropTypes.func,
    route: PropTypes.string,
    gaId: PropTypes.string
};

export default QuickSellIntentSurveyCard;
