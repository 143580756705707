import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setNudgeDetails, showHelpSupportModal } from "./actions";
import SupportWidget from "./component";

const mapStateToProps = ({
    supportWidget,
    carDetails: {content: {
        stateCode
    }, ipDetectedState}
}) => ({
    supportWidget,
    stateCode,
    ipDetectedState
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setNudgeDetailsConnect: setNudgeDetails,
    showHelpSupportModalConnect: showHelpSupportModal
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportWidget));
