import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAllAbExperiment, setExperiment, setStatsigInitialization } from "../shared/ab-experiment/actions";
import { getRefreshTokenInfo, getSeoList, logoutSuccess, setCurrentScreen, setGAId, setSecureToken, setInitialZeroDpOptimizeVariant, getUserGeoLocation } from "./actions";
import AUConfiguration from "./component";

const mapStateToProps = ({
    user: {seoMenu, firstName, middleName, lastName, email, mobile, isLoggedIn, loginType, userId, amplitudeDeviceId, initialSSRVariantConfig, gaId},
    abExperiment
}) => ({
    seoMenu,
    firstName,
    middleName,
    lastName,
    email,
    mobile,
    isLoggedIn,
    loginType,
    userId,
    amplitudeDeviceId,
    abExperiment,
    initialSSRVariantConfig,
    gaId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getRefreshTokenInfoConnect: getRefreshTokenInfo,
    logoutSuccessConnect: logoutSuccess,
    setSecureTokenConnect: setSecureToken,
    setGAIdConnect: setGAId,
    setExperimentConnect: setExperiment,
    getSeoListConnect: getSeoList,
    setCurrentScreenConnect: setCurrentScreen,
    setInitialZeroDpOptimizeVariantConnect: setInitialZeroDpOptimizeVariant,
    setAllAbExperimentConnect: setAllAbExperiment,
    getUserGeoLocationConnect: getUserGeoLocation,
    setStatsigInitializationConnect: setStatsigInitialization
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AUConfiguration);
