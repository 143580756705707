import * as amplitude from "@amplitude/analytics-browser";
import { API_SOURCE } from "../../constants/app-constants";
import { getDeviceType } from "../../utils/helpers/get-device-type";

export default {
    initialize: (params, payload) => {
        const deviceType = getDeviceType();
        amplitude.init(process.env.AMPLITUDE_AU, params, payload);
        amplitude.identify(new amplitude.Identify().set("platform type", deviceType === API_SOURCE.WEB_APP ? API_SOURCE.DESKTOP : deviceType));
    },
    setUserId: (isLoggedIn, email) => {
        if (isLoggedIn) {
            amplitude.setUserId(email);
        }
    },
    resetUser: () => {
        amplitude.reset();
    }
};
