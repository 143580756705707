import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import ChatWidgetRevamp from "../chat-widget-revamp";
import { NUMBER } from "../../../constants/app-constants";
import { trackAmplitude, trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const HelpSupportModalMobile = loadable(() => import("../help-support-modal/component"));
const EmailUsModalMobile = loadable(() => import("../email-us-modal"));

const SupportWidget = ({
    showWidget = true,
    supportWidget = {},
    setNudgeDetailsConnect = () => { },
    showHelpSupportModalConnect,
    isSellCarPage = false, ipDetectedState
}) => {
    // const [helpSupportModal, setHelpSupportModal] = useState(false);
    const [emailSupportModal, setEmailSupportModal] = useState(false);

    const { transition, showHelpSupportModal, hideWidgetIcon } = supportWidget;

    const handleHelpModal = (val) => {
        showHelpSupportModalConnect(val);
    };

    const handleWidgetClick = async (val) => {
        if (emailSupportModal) return;
        await yieldToMain();
        handleHelpModal(val);
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.HS_S_ICON_CLICKED, {
            "H&S clickedSupport method": val,
            "Page name": window.location.href,
            "User IP state": ipDetectedState
        });
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.SUPPORT_WIDGET_HELP_ICON_CLICKED, {
            eventLabel: window.location.href
        });
    };

    const handleEmailUsModal = (val) => {
        setEmailSupportModal(val);
    };

    useEffect(() => {
        const onTouchMove = () => {
            if (transition) {
                setNudgeDetailsConnect({
                    transition: false
                });
                window.setTimeout(() => {
                    setNudgeDetailsConnect({
                        nudgeText: false
                    });
                }, NUMBER.THOUSAND);
            }
        };

        window.addEventListener("touchmove", onTouchMove);

        return () => {
            window.removeEventListener("touchmove", onTouchMove);
        };
    }, [setNudgeDetailsConnect, transition]);

    if (!showWidget) return null;

    return (
        <React.Fragment>
            {hideWidgetIcon ? null : <ChatWidgetRevamp onClick={() => handleWidgetClick(!showHelpSupportModal)} />}
            {showHelpSupportModal && <HelpSupportModalMobile isSellCarPage={isSellCarPage} handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal} ipDetectedState={ipDetectedState} />}
            {emailSupportModal && isSellCarPage && <EmailUsModalMobile handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal} ipDetectedState={ipDetectedState} />}
        </React.Fragment>
    );
};
export default SupportWidget;

SupportWidget.propTypes = {
    showWidget: PropTypes.bool,
    isSellCarPage: PropTypes.bool,
    supportWidget: PropTypes.object,
    setNudgeDetailsConnect: PropTypes.func,
    showHelpSupportModalConnect: PropTypes.func,
    ipDetectedState: PropTypes.string,
    stateCode: PropTypes.string
};
